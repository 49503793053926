export default function Copyright() {
    return (
        <footer className="bg-[#fff] pb-[30px] z-40">
            <div className="w-full h-[54px] flex-col justify-center text-center items-center gap-[35px] inline-flex">
                <div className="w-full h-px opacity-20 bg-slate-900 border border-neutral-400" />
                <div className="opacity-60 text-center text-slate-900 text-sm font-normal font-['Circular Std']">
                    @ 2024 Makan Bergizi Gratis
                </div>
            </div>
        </footer>
    );
}
