import React, { useState } from 'react'
import Footer from '../../Components/footer'
import { ArrowLeftIcon } from '../../assets/icons'
import { useNavigate } from 'react-router-dom'
import Head from './section/head'
import RequireList from './section/requireList'
import axios from 'axios'
import { useTypedSelector } from '../../../redux/store'
import { Data } from './interface'
import NotFulfiledToDellAccount from './modals'
import Cookies from 'universal-cookie'

export default function DeletedAccountRequire() {
    const navigate = useNavigate()
    const cookies = new Cookies();
    const token = useTypedSelector((state)=>state.UserInfo.token)
    const idDevices = useTypedSelector((state)=>state.UserInfo.deviceId)
    const deviceIdKey = 'device_id';
    let deviceId = cookies.get(deviceIdKey);
    console.log(idDevices);
    console.log(deviceId);
    
    const [loading, setloading] = useState(false)
    const [isNotFulfilledModals, setIsNotFulfilledModals] = useState(false)
    const [validationData, setValidationData] = useState<Data>()
    const handleCheckValidate = () => {
        setloading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/user/delete-account-validation`, {
            headers: {
                Authorization: token,
                'X-Header-DeviceID': deviceId
            }
        })
        .then((res)=>{
            if(res.data.data.dataOrder.passed &&
                res.data.data.saldo.passed &&
                res.data.data.user.passed
            ){
                navigate('/delete-account/reason', { state : res.data})
            }else {
                setValidationData(res.data.data)
                setIsNotFulfilledModals(true)
            }
        }).catch((err)=>{
            console.log(err);
            console.log(token);
            console.log(deviceId);
        }).finally(()=>{
            setloading(false)
        })
    }
    return (
        <div className='pt-[203px]'>
            {isNotFulfilledModals && <NotFulfiledToDellAccount showModal={isNotFulfilledModals} CloseModals={setIsNotFulfilledModals} data={validationData}/>}
            <section className='w-[976px] mx-auto py-[24px] px-[16px] shadow-custom-shadow'>
                <div
                    onClick={() => navigate(-1)}
                    className='flex gap-[8px] items-center cursor-pointer'>
                    <ArrowLeftIcon />
                    <p className='text-[24px] text-[#25282B] font-bold'>Kembali</p>
                </div>
                <Head />
                <RequireList />
                <div className='w-full flex justify-center mb-[40px]'>
                    <button
                    onClick={()=>handleCheckValidate()} 
                    className='text-[#fff] w-[320px] py-[8px] bg-[#1454B8] cursor-pointer rounded-full'>{loading ? "Loading..." : 'Lanjut'}</button>
                </div>
            </section>
            <Footer />
        </div>
    )
}
