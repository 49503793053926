import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FeeApps {
    asuransi: number;
    proteksi: number;
    layanan: number;
    jasaAplikasi: number
    constanta_volume: number
}

const initialState: FeeApps = {
    asuransi:0,
    proteksi:0,
    layanan:0,
    jasaAplikasi:0,
    constanta_volume:0
};

const FeeAps = createSlice({
    name: 'Fee for Aplication',
    initialState,
    reducers: {
        setAsuransi(state, action: PayloadAction<number>) {
            state.asuransi = action.payload;
        },
        setProteksi(state, action: PayloadAction<number>) {
            state.proteksi = action.payload;
        },
        setLayanan(state, action: PayloadAction<number>) {
            state.layanan = action.payload;
        },
        setJasaAplikasi(state, action: PayloadAction<number>) {
            state.jasaAplikasi = action.payload;
        },
        setConstantaVolume(state, action: PayloadAction<number>) {
            state.constanta_volume = action.payload;
        },
    },
});

export const {  setAsuransi, setProteksi, setLayanan, setJasaAplikasi, setConstantaVolume } = FeeAps.actions;
export default FeeAps.reducer;
