import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { DetailToko } from '../../../../../konsumnen/pages/DetailToko/interface';

export const GetDetailToko = createAsyncThunk(
  'detail/toko',
  async ({ id }: { id: string }) => { 
    const response = await axiosInstance.get(`/vendor/detail/toko/${id}`);     
    return response.data;
  }
);

// Redux slice
const VendorDetail = createSlice({
    name: 'VendorDetail',
    initialState: {
      data: null as DetailToko | null,
      loading: false,
      error: null as string | null | undefined, 
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(GetDetailToko.pending, (state) => {
          state.loading = true;
          state.error = null;  
        })
        .addCase(GetDetailToko.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(GetDetailToko.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;  
        });
    },
  });
  

export default VendorDetail.reducer;
