import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../../api/axiosInstance';
import { ChatViewDetail } from '../../../../../../konsumnen/pages/profile/menu/Chat/section/chatView/interface';

export const ChatDetail = createAsyncThunk(
  'chat/detail',
  async ({
    chatId
  }: {
    chatId: string

  }) => {
    const response = await axiosInstance.get(`/chat/detail/${chatId}`);
    console.log(response);
    
    return response.data;

  }
);

// Redux slice
const DetailChat = createSlice({
  name: 'DetailChat',
  initialState: {
    data: null as ChatViewDetail | null,
    loading: false,
    error: null as string | null | undefined,
  },
  reducers: {
    addMessage: (state, action) => {      
      const { newMessage } = action.payload;
      if (state.data) {
        state.data.data.messages.push(newMessage);
      } else {
        console.log('gagal menambahkan data chat');
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(ChatDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ChatDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(ChatDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { addMessage } = DetailChat.actions;


export default DetailChat.reducer;
