import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { CartListDetail } from '../../../../../konsumnen/pages/cartUpdate/interface';

export const GetCartList = createAsyncThunk(
  'cart/getCartList',
  async () => {
    const response = await axiosInstance.get(`/cart/list`);
    return response.data;
  }
);
const HitungTotalProduk = (cartList: CartListDetail) => {
  return cartList.datas.reduce((totalJumlahProduk, toko) => {
    const jumlahProdukToko = toko.products.length;
    return totalJumlahProduk + jumlahProdukToko;
  }, 0);
};

// Redux slice
const cartList = createSlice({
  name: 'cartList',
  initialState: {
    data: null as CartListDetail | null,
    totalProduct: 0,
    loading: false,
    error: null as string | null | undefined,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(GetCartList.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    
    .addCase(GetCartList.fulfilled, (state, action) => {
      state.loading = false; 
      state.data = action.payload; 
      state.totalProduct = HitungTotalProduk(action.payload); 
    })

    .addCase(GetCartList.rejected, (state, action) => {
      state.loading = false; 
      state.error = action.error.message; 
    });
},
});


export default cartList.reducer;
