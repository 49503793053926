import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { ReviewDetail } from '../../../../../konsumnen/Components/product/detailProduct/reviewProduct/component/interface';

export const GetReviewProductList = createAsyncThunk(
  'review/getReviewList',
  async ({
    prodId
  }:{
    prodId: string
  }) => { 
    const response = await axiosInstance.get(`/review-produk/reviews/${prodId}`); 
    console.log('review data here');
    console.log(response);
    
    return response.data;
  }
);

// Redux slice
const reviewProductList = createSlice({
    name: 'reviewProductList',
    initialState: {
      data: null as ReviewDetail | null,
      loading: false,
      error: null as string | null | undefined, 
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(GetReviewProductList.pending, (state) => {
          state.loading = true;
          state.error = null;  
        })
        .addCase(GetReviewProductList.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(GetReviewProductList.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;  
        });
    },
  });
  

export default reviewProductList.reducer;
