export const RequireListDeleteAccount = [
    {
    title: 'Tambahkan Email',
    desk: 'Jika kamu belom menambahkan email harap untuk memambahkan Email untuk menerima laporan penghapusan akun'
},
    {
    title: 'Kosongkan Saldo',
    desk: 'Pastikan saldo di akun MBG sudah Rp 0, jika saldo kamu dibawah Rp 10.000 harap isi saldo terlebih dahulu untuk dipindahkan ke rekening lain.'
},
    {
    title: 'Selesaikan Transaksi',
    desk: 'Semua transaksi harus diselesaikan. Tidak boleh ada transaksi yang sedang berlangsung, harap selesaikan semua proses transaksi termasuk pengiriman terlebih dahulu'
}
]