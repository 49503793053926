import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Content {
    nama: any;
    nama_ewallet: any;
    nama_gerai: string;
    nama_bank: any;
    _id: string;
    nama_fintech: string;
    icon: string;
}

interface Method {
    metode: string;
    contents: Content[];
}

interface ResponseData {
    data: Method[];
}
const initialState: ResponseData = {
    data: [],
};

const methodsSlice = createSlice({
    name: 'methods',
    initialState,
    reducers: {
        setMethods(state, action: PayloadAction<Method[]>) {
            state.data = action.payload;
        },
    },
});

export const { setMethods } = methodsSlice.actions;
export default methodsSlice.reducer;