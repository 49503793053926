import React from 'react'
import { CantDellAccount, GreenCheck, RedAlert } from './icon';
import { Data } from '../interface';
interface dellAccountModal {
  showModal: boolean;
  CloseModals: (value: boolean) => void;
  data?: Data 
}
export default function NotFulfiledToDellAccount({
  showModal,
  CloseModals,
  data
}: dellAccountModal) {
  const keysArray = data ? Object.keys(data) : [];
  console.log(keysArray);
 
  return (
    <div className={
      showModal ? 'fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50' : 'hidden'
    }>
      <div className='flex flex-col justify-center items-center bg-white  w-[520px] p-[50px] rounded-[12px] shadow-lg'>
        <CantDellAccount />
        <div className='max-w-[320px] text-center mt-[32px]'>
          <p className='text-[#25282B] text-[18px] font-bold'>Akun Anda belum bisa dihapus</p>
          <p className='text-[14px] text-[#818284] font-normal mt-[24px]'>salah satu syarat belum terpenuhi. Harap untuk menuntaskannya terlebih dahulu</p>
        </div>
        {data && (
        <div className='mt-[32px] [w-320px] flex flex-col items-start gap-[18px]'>
          <section className='flex gap-[8px] items-start'>
            <div className={`${data.user.passed ? 'bg-[#A6A7A8]': 'bg-[#FF0000]' } w-[22px] h-[22px] rounded-full text-[#fff] text-[14px] font-normal flex justify-center items-center`}>
              <p>1</p>
            </div>
            <div className='flex flex-col'>
              <p className={data.user.passed ? 'text-[16px] text-[#818284] font-medium' : 'text-[16px] text-[#FF0000] font-medium'}>Tambahkan Email</p>
              {data.user.passed ? (
                <div className='flex gap-[5px]'>
                  <GreenCheck />
                  <p className='text-[#46BB2F] text-[14px] font-normal'>Terpenuhi</p>
                </div>
              ) : (
                <div className='flex gap-[5px] items-center'>
                  <RedAlert />
                  <p className='text-[14px] text-[#FF0000] font-normal'>harap tambahkan Email</p>
                </div>
              )}
            </div>
          </section>
          <section className='flex gap-[8px] items-start'>
            <div className={`${data.saldo.passed ? 'bg-[#A6A7A8]': 'bg-[#FF0000]' } w-[22px] h-[22px] rounded-full text-[#fff] text-[14px] font-normal flex justify-center items-center`}>
              <p>2</p>
            </div>
            <div className='flex flex-col'>
              <p className={data.saldo.passed ? 'text-[16px] text-[#818284] font-medium' : 'text-[16px] text-[#FF0000] font-medium'}>Kosongkan saldo</p>
              {data.saldo.passed ? (
                <div className='flex gap-[5px]'>
                  <GreenCheck />
                  <p className='text-[#46BB2F] text-[14px] font-normal'>Terpenuhi</p>
                </div>

              ) : (
                <div className='flex gap-[5px] items-center'>
                  <RedAlert />
                  <p className='text-[14px] text-[#FF0000] font-normal'>harap kosongkan saldo</p>
                </div>
              )}
            </div>
          </section>
          <section className='flex gap-[8px] items-start'>
            <div className={`${data.dataOrder.passed ? 'bg-[#A6A7A8]': 'bg-[#FF0000]' } w-[22px] h-[22px] rounded-full text-[#fff] text-[14px] font-normal flex justify-center items-center`}>
              <p>3</p>
            </div>
            <div className='flex flex-col'>
              <p className={data.dataOrder.passed ? 'text-[16px] text-[#818284] font-medium' : 'text-[16px] text-[#FF0000] font-medium'}>Selesaikan Transaksi</p>
              {data.dataOrder.passed ? (
                <div className='flex gap-[5px]'>
                  <GreenCheck />
                  <p className='text-[#46BB2F] text-[14px] font-normal'>Terpenuhi</p>
                </div>

              ) : (
                <div className='flex gap-[5px] items-center'>
                  <RedAlert />
                  <p className='text-[14px] text-[#FF0000] font-normal'>harap selesaikan transaksi</p>
                </div>
              )}
            </div>
          </section>
        </div>
        )}
        <button
          onClick={() => CloseModals(false)}
          className='mt-[32px] bg-[#1454B8] text-[#fff] w-[320px] rounded-full py-[8px]'>Kembali</button>
      </div>
    </div>
  )
}
