export const CantDellAccount = () => (
    <svg width="240" height="238" viewBox="0 0 240 238" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M124.515 238.001C188.295 238.001 240 234.632 240 230.477C240 226.322 188.295 222.953 124.515 222.953C60.7338 222.953 9.0293 226.322 9.0293 230.477C9.0293 234.632 60.7338 238.001 124.515 238.001Z" fill="#C9B5A9" />
        <path d="M71.8317 4.64039L74.3268 5.44989L71.8317 6.25882C70.8144 6.58859 70.0172 7.38602 69.6875 8.4035L68.8782 10.8992L68.0689 8.4035C67.7392 7.38602 66.942 6.58859 65.9248 6.25882L63.4297 5.44989L65.9248 4.64039C66.942 4.31062 67.7392 3.51319 68.0689 2.49571L68.8782 0L69.6875 2.49571C70.0172 3.51319 70.8144 4.31062 71.8317 4.64039Z" fill="white" />
        <path d="M224.835 166.422L227.33 167.231L224.835 168.041C223.818 168.37 223.021 169.168 222.691 170.185L221.882 172.681L221.073 170.185C220.743 169.168 219.946 168.37 218.929 168.041L216.434 167.231L218.929 166.422C219.946 166.092 220.743 165.294 221.073 164.277L221.882 161.781L222.691 164.277C223.021 165.294 223.818 166.091 224.835 166.422Z" fill="white" />
        <path d="M8.4014 144.996L10.8965 145.805L8.4014 146.615C7.38418 146.945 6.58695 147.742 6.25725 148.76L5.44796 151.255L4.63924 148.76C4.30954 147.742 3.51231 146.945 2.49509 146.615L0 145.805L2.49509 144.996C3.51231 144.666 4.30954 143.869 4.63924 142.851L5.44796 140.355L6.25725 142.851C6.58695 143.869 7.38418 144.666 8.4014 144.996Z" fill="white" />
        <path d="M120.472 69.0851L121.921 69.5551L120.472 70.025C119.882 70.2164 119.419 70.6794 119.227 71.27L118.757 72.719L118.288 71.27C118.096 70.6794 117.633 70.2164 117.042 70.025L115.594 69.5551L117.042 69.0851C117.633 68.8938 118.096 68.4307 118.288 67.8396L118.757 66.3906L119.227 67.8396C119.419 68.4307 119.882 68.8938 120.472 69.0851Z" fill="white" />
        <path d="M32.0135 37.9054L33.4621 38.3754L32.0135 38.8453C31.4231 39.0367 30.9601 39.4997 30.7683 40.0903L30.2984 41.5393L29.8286 40.0903C29.6373 39.4997 29.1744 39.0367 28.5833 38.8453L27.1348 38.3754L28.5833 37.9054C29.1738 37.7141 29.6367 37.2511 29.8286 36.6599L30.2984 35.2109L30.7683 36.6599C30.9601 37.2505 31.4231 37.7135 32.0135 37.9054Z" fill="white" />
        <path d="M13.7967 132.374L15.2453 132.844L13.7967 133.314C13.2063 133.505 12.7433 133.968 12.5515 134.56L12.0816 136.009L11.6118 134.56C11.4205 133.969 10.9576 133.506 10.3665 133.314L8.91797 132.844L10.3665 132.374C10.957 132.183 11.42 131.72 11.6118 131.129L12.0816 129.68L12.5515 131.129C12.7433 131.719 13.2063 132.182 13.7967 132.374Z" fill="white" />
        <path d="M189.783 230.48H183.598L170.816 178.379H177.001L189.783 230.48Z" fill="#8EADD5" />
        <path d="M227.577 230.48H221.393L208.611 178.379H214.796L227.577 230.48Z" fill="#8EADD5" />
        <path d="M188.062 230.48H181.877L169.096 178.379H175.281L188.062 230.48Z" fill="#B9CFE6" />
        <path d="M155.078 230.48H161.262L174.044 178.379H167.86L155.078 230.48Z" fill="#8EADD5" />
        <path d="M225.996 230.48H219.811L207.029 178.379H213.214L225.996 230.48Z" fill="#B9CFE6" />
        <path d="M177.344 197.066H213.902V191.705H177.344V197.066Z" fill="#B9CFE6" />
        <path d="M183.113 216.184H219.672V210.822H183.113V216.184Z" fill="#B9CFE6" />
        <path d="M152.695 230.48H158.88L171.662 178.379H165.477L152.695 230.48Z" fill="white" />
        <path d="M192.918 230.48H199.102L211.884 178.379H205.7L192.918 230.48Z" fill="#8EADD5" />
        <path d="M190.629 230.48H196.813L209.595 178.379H203.41L190.629 230.48Z" fill="white" />
        <path d="M201.348 191.707H164.789V197.068H201.348V191.707Z" fill="white" />
        <path d="M195.576 210.816H159.018V216.178H195.576V210.816Z" fill="white" />
        <path d="M214.223 179.753H164.011C162.797 179.753 161.812 178.769 161.812 177.554V177.46C161.812 176.246 162.796 175.262 164.011 175.262H214.223C215.437 175.262 216.421 176.246 216.421 177.46V177.554C216.421 178.769 215.437 179.753 214.223 179.753Z" fill="white" />
        <path d="M27.4168 132.938H68.3721C69.7362 132.938 71.0268 133.474 71.8826 134.396L77.6643 140.626C78.2347 141.24 79.0957 141.598 80.0049 141.598H143.938C146.203 141.598 148.039 143.191 148.039 145.157V222.787C148.039 227.027 144.078 230.465 139.193 230.465H29.4368C26.0159 230.465 23.2422 228.058 23.2422 225.089V136.561C23.2428 134.56 25.1112 132.938 27.4168 132.938Z" fill="#E9EAEA" />
        <path d="M57.4436 86.5742L40.2129 103.809V169.854H124.678V86.5742H57.4436Z" fill="white" />
        <path d="M57.4436 103.809V86.5742L40.2129 103.809H57.4436Z" fill="#B9CFE6" />
        <path d="M13.049 152.398H126.532C129.207 152.398 131.503 154.051 131.997 156.333L146.802 224.752C147.445 227.721 144.82 230.468 141.338 230.468H27.8553C25.1804 230.468 22.8852 228.815 22.3912 226.533L7.58553 158.114C6.9428 155.144 9.5677 152.398 13.049 152.398Z" fill="#CDCDE0" />
        <path d="M85.8934 121.947C88.0524 119.758 90.2075 117.564 92.3637 115.371C93.6337 114.079 93.748 111.75 92.3637 110.479C90.9932 109.221 88.8284 109.101 87.4735 110.479C85.2863 112.63 83.0996 114.781 80.9164 116.935C78.7556 114.773 76.5867 112.619 74.3921 110.491C73.1164 109.175 70.7103 109.113 69.4461 110.491C68.148 111.905 68.0848 114.034 69.4461 115.438C71.5684 117.628 73.716 119.791 75.8717 121.947C73.7172 124.101 71.5713 126.264 69.4496 128.453C68.1337 129.729 68.0722 132.135 69.4496 133.4C70.8637 134.698 72.9917 134.761 74.3955 133.4C76.5891 131.272 78.7573 129.12 80.9164 126.959C83.0985 129.112 85.284 131.261 87.47 133.411C88.7612 134.682 91.0897 134.796 92.3603 133.411C93.6187 132.04 93.7382 129.875 92.3603 128.52C90.2058 126.327 88.0519 124.135 85.8934 121.947Z" fill="url(#paint0_linear_2286_189406)" />
        <path d="M16.209 92.1866H15.4692C15.3544 91.8321 15.2119 91.4903 15.0442 91.1628L15.568 90.6388C16.0637 90.143 16.0637 89.3398 15.568 88.844L14.3188 87.5939C13.8231 87.0986 13.0201 87.0986 12.525 87.5939L12.0011 88.1178C11.6738 87.9506 11.332 87.8076 10.9776 87.6927V86.9527C10.9776 86.2518 10.4096 85.6836 9.70882 85.6836H7.94146C7.24072 85.6836 6.67266 86.2518 6.67266 86.9527V87.6927C6.31827 87.8076 5.97652 87.9501 5.64913 88.1178L5.12529 87.5939C4.63018 87.0986 3.82663 87.0986 3.33152 87.5939L2.08168 88.844C1.58656 89.3398 1.58656 90.143 2.08168 90.6388L2.60551 91.1628C2.43779 91.4903 2.29534 91.8315 2.18047 92.1866H1.44067C0.739933 92.1866 0.171875 92.7548 0.171875 93.4557V95.2235C0.171875 95.9244 0.739933 96.4926 1.44067 96.4926H2.18047C2.29534 96.8471 2.43779 97.1889 2.60551 97.5164L2.08168 98.0404C1.58656 98.5362 1.58656 99.3394 2.08168 99.8346L3.33152 101.085C3.82663 101.58 4.63018 101.58 5.12529 101.085L5.64913 100.561C5.97652 100.728 6.31827 100.871 6.67266 100.986V101.726C6.67266 102.427 7.24072 102.995 7.94146 102.995H9.70882C10.4096 102.995 10.9776 102.427 10.9776 101.726V100.986C11.332 100.871 11.6738 100.729 12.0011 100.561L12.525 101.085C13.0207 101.58 13.8236 101.58 14.3188 101.085L15.568 99.8346C16.0637 99.3388 16.0637 98.5356 15.568 98.0404L15.0442 97.5164C15.2113 97.1889 15.3544 96.8477 15.4692 96.4926H16.209C16.9098 96.4926 17.4778 95.9244 17.4778 95.2235V93.4557C17.4773 92.7548 16.9092 92.1866 16.209 92.1866ZM8.82485 98.7798C6.37341 98.7798 4.3855 96.7919 4.3855 94.3393C4.3855 91.8873 6.37284 89.8988 8.82485 89.8988C11.2769 89.8988 13.2642 91.8867 13.2642 94.3393C13.2642 96.7919 11.2763 98.7798 8.82485 98.7798Z" fill="#C9B5A9" />
        <path d="M160.034 122.178H159.295C159.18 121.824 159.037 121.482 158.87 121.154L159.393 120.63C159.889 120.135 159.889 119.331 159.393 118.836L158.144 117.586C157.648 117.09 156.845 117.09 156.349 117.586L155.825 118.11C155.498 117.942 155.157 117.8 154.802 117.685V116.945C154.802 116.244 154.234 115.676 153.533 115.676H151.766C151.065 115.676 150.497 116.244 150.497 116.945V117.685C150.142 117.8 149.801 117.942 149.473 118.11L148.95 117.586C148.454 117.09 147.651 117.09 147.156 117.586L145.906 118.836C145.41 119.331 145.41 120.135 145.906 120.63L146.43 121.154C146.262 121.482 146.12 121.824 146.005 122.178H145.265C144.564 122.178 143.996 122.746 143.996 123.447V125.215C143.996 125.916 144.564 126.484 145.265 126.484H146.005C146.12 126.839 146.262 127.181 146.43 127.508L145.906 128.032C145.41 128.528 145.41 129.331 145.906 129.827L147.156 131.077C147.651 131.572 148.454 131.572 148.95 131.077L149.473 130.553C149.801 130.721 150.142 130.863 150.497 130.978V131.718C150.497 132.419 151.065 132.987 151.766 132.987H153.533C154.234 132.987 154.802 132.419 154.802 131.718V130.978C155.156 130.863 155.498 130.721 155.825 130.553L156.349 131.077C156.845 131.572 157.648 131.572 158.144 131.077L159.393 129.827C159.889 129.331 159.889 128.528 159.393 128.032L158.87 127.508C159.037 127.181 159.18 126.839 159.295 126.484H160.034C160.735 126.484 161.303 125.916 161.303 125.215V123.447C161.303 122.746 160.735 122.178 160.034 122.178ZM152.65 128.771C150.199 128.771 148.211 126.783 148.211 124.33C148.211 121.878 150.198 119.89 152.65 119.89C155.102 119.89 157.09 121.878 157.09 124.33C157.09 126.783 155.102 128.771 152.65 128.771Z" fill="#C9B5A9" />
        <path d="M36.1501 79.0599L35.1754 78.6904C35.2012 78.1659 35.1834 77.6442 35.1265 77.1295L36.0783 76.7003C36.9783 76.2947 37.3792 75.2364 36.9737 74.3362L35.9508 72.0657C35.5453 71.1654 34.4873 70.7644 33.5872 71.17L32.6355 71.5992C32.288 71.2154 31.9089 70.8569 31.4994 70.5282L31.8687 69.5533C32.2185 68.63 31.7532 67.5976 30.8302 67.2483L28.5017 66.3664C27.5787 66.0165 26.5471 66.4819 26.1973 67.4052L25.828 68.3801C25.3036 68.3543 24.782 68.3721 24.2674 68.429L23.8383 67.477C23.4328 66.5767 22.3742 66.1757 21.4748 66.5813L19.2048 67.6045C18.3048 68.0101 17.9039 69.0684 18.3094 69.9687L18.7384 70.9206C18.3548 71.2682 17.9958 71.6474 17.6678 72.057L16.6931 71.6876C15.7701 71.3377 14.7385 71.8031 14.3887 72.7264L13.507 75.0555C13.1572 75.9787 13.6225 77.0111 14.5455 77.3604L15.5202 77.7298C15.4944 78.2544 15.5122 78.7761 15.569 79.2908L14.6173 79.72C13.7172 80.1256 13.3163 81.1839 13.7218 82.0841L14.7448 84.3546C15.1503 85.2549 16.2083 85.6559 17.1084 85.2503L18.0601 84.8211C18.4076 85.2049 18.7867 85.564 19.1962 85.892L18.8269 86.867C18.4771 87.7903 18.9423 88.8221 19.8654 89.172L22.1939 90.0539C23.1169 90.4037 24.1485 89.9384 24.4983 89.0151L24.8676 88.0402C25.392 88.066 25.9135 88.0482 26.4282 87.9913L26.8572 88.9433C27.2628 89.8436 28.3208 90.2446 29.2208 89.839L31.4907 88.8158C32.3908 88.4102 32.7917 87.3519 32.3862 86.4516L31.9571 85.4996C32.3408 85.1521 32.6998 84.7729 33.0278 84.3633L34.0025 84.7327C34.9255 85.0826 35.9571 84.6172 36.3069 83.6939L37.1886 81.3648C37.5378 80.4416 37.0731 79.4092 36.1501 79.0599ZM23.1318 84.0605C19.9016 82.8367 18.2749 79.2253 19.4983 75.9942C20.7218 72.7631 24.3323 71.1361 27.5626 72.3598C30.7929 73.5835 32.4195 77.195 31.1961 80.4261C29.9727 83.6566 26.3621 85.2842 23.1318 84.0605Z" fill="#C9B5A9" />
        <path d="M46.9166 35.9326L42.2578 45.8149L52.2606 73.678L78.4406 64.2748L65.8279 29.1406L46.9166 35.9326Z" fill="white" />
        <path d="M49.5271 43.1969L46.9166 35.9258L42.2578 45.8081L49.5271 43.1969Z" fill="#F4E8D8" />
        <path d="M61.6968 50.4602C62.3435 49.0635 62.988 47.6657 63.6324 46.2679C64.0121 45.4446 63.6721 44.3283 62.8053 43.9641C61.9472 43.6033 60.907 43.9129 60.5021 44.7914C59.8358 46.1748 59.1689 47.5577 58.505 48.9417C57.1219 48.289 55.7359 47.6416 54.3419 47.0102C53.5182 46.6063 52.3747 46.9843 52.0122 47.8472C51.64 48.7332 51.9703 49.7466 52.8491 50.1775C54.219 50.8497 55.5963 51.5058 56.9766 52.1561C56.3258 53.5356 55.6808 54.9173 55.0518 56.307C54.6475 57.1309 55.026 58.2748 55.8881 58.6373C56.7738 59.0096 57.787 58.6792 58.2178 57.8008C58.8915 56.4271 59.5486 55.0466 60.2005 53.6637C61.5922 54.3089 62.9857 54.9512 64.3791 55.5941C65.2022 55.9738 66.3182 55.6337 66.6824 54.7668C67.0431 53.9079 66.7335 52.868 65.8553 52.4629C64.4687 51.7942 63.0833 51.126 61.6968 50.4602Z" fill="url(#paint1_linear_2286_189406)" />
        <path d="M101.659 17.3281L92.9387 20.8896L83.0508 44.4265L105.167 53.7216L117.635 24.0425L101.659 17.3281Z" fill="white" />
        <path d="M99.0782 23.4703L101.658 17.3281L92.9375 20.8896L99.0782 23.4703Z" fill="#F4E8D8" />
        <path d="M102.583 35.1764C103.808 34.6662 105.032 34.1543 106.256 33.6418C106.977 33.3402 107.413 32.4325 107.074 31.6953C106.74 30.9651 105.898 30.5555 105.128 30.8772C103.899 31.3673 102.669 31.8574 101.44 32.3491C100.942 31.1277 100.439 29.908 99.9217 28.6941C99.6339 27.9569 98.6868 27.5301 97.9533 27.8667C97.2003 28.2126 96.8195 29.0491 97.1262 29.8356C97.6047 31.0616 98.0975 32.2819 98.5949 33.4999C97.3772 33.9969 96.1618 34.4984 94.9516 35.0143C94.2147 35.3022 93.7879 36.2496 94.1245 36.9826C94.4703 37.7358 95.3066 38.1167 96.0923 37.81C97.3209 37.3302 98.5432 36.8361 99.7638 36.3375C100.272 37.5589 100.783 38.7797 101.293 40.0012C101.594 40.7222 102.502 41.1577 103.239 40.8193C103.969 40.4843 104.378 39.6427 104.057 38.8728C103.566 37.6405 103.076 36.4076 102.583 35.1764Z" fill="url(#paint2_linear_2286_189406)" />
        <path d="M202.963 171.72C202.963 171.72 198.097 172.253 196.196 173.317C194.295 174.382 193.383 177.957 196.728 177.957C200.074 177.957 205.397 177.957 207.678 177.957C209.96 177.957 209.047 172.861 207.83 171.492L202.963 171.72Z" fill="#2E344B" />
        <path d="M200.73 164.018C200.73 164.018 201.969 170.665 202.313 171.756C202.84 173.427 207.522 173.34 207.939 171.492C208.554 168.766 208.554 163.227 208.554 163.227L200.73 164.018Z" fill="#D8877B" />
        <path d="M199.875 173.452C199.769 173.259 199.631 173.078 199.47 172.928C199.301 172.772 199.114 172.634 198.921 172.51C198.718 172.379 198.483 172.219 198.232 172.236C198.068 172.247 197.896 172.406 197.965 172.587C197.993 172.663 198.022 172.733 198.076 172.795C198.108 172.833 198.145 172.868 198.18 172.903C198.213 172.935 198.251 172.962 198.288 172.991C198.294 172.996 198.307 173.005 198.317 173.011C198.312 173.008 198.308 173.005 198.297 172.998C198.338 173.025 198.376 173.061 198.415 173.092C198.566 173.217 198.712 173.35 198.841 173.499C198.868 173.53 198.893 173.564 198.921 173.595C198.825 173.489 198.944 173.627 198.962 173.649C199.008 173.723 199.063 173.792 199.106 173.87C199.129 173.91 199.149 173.951 199.169 173.992C199.174 174.003 199.176 174.007 199.178 174.01C199.192 174.046 199.206 174.082 199.225 174.117C199.244 174.152 199.262 174.188 199.282 174.223C199.329 174.309 199.402 174.362 199.482 174.412C199.65 174.518 199.861 174.44 199.965 174.286C200.137 174.026 200.012 173.699 199.875 173.452Z" fill="white" />
        <path d="M201.8 173.268C201.763 173.146 201.717 173.025 201.653 172.914C201.541 172.721 201.402 172.539 201.248 172.377C200.939 172.053 200.522 171.763 200.067 171.72C199.949 171.708 199.832 171.805 199.789 171.906C199.746 172.007 199.759 172.163 199.852 172.237C199.938 172.305 200.024 172.367 200.114 172.428C200.134 172.442 200.155 172.456 200.175 172.47C200.178 172.472 200.182 172.474 200.185 172.476C200.216 172.492 200.247 172.528 200.274 172.55C200.414 172.669 200.542 172.793 200.658 172.935C200.671 172.953 200.683 172.97 200.696 172.988C200.721 173.025 200.746 173.063 200.769 173.102C200.816 173.179 200.859 173.259 200.897 173.342C200.935 173.424 200.974 173.505 201.013 173.586C201.018 173.598 201.018 173.598 201.017 173.595C201.024 173.611 201.03 173.626 201.037 173.642C201.061 173.685 201.097 173.728 201.132 173.762C201.164 173.791 201.198 173.818 201.229 173.847C201.413 174.02 201.699 173.94 201.776 173.703C201.824 173.558 201.848 173.421 201.8 173.268Z" fill="white" />
        <path d="M207.107 174.575C207.056 174.395 206.965 174.238 206.833 174.106C206.761 174.05 206.689 173.995 206.617 173.939C206.451 173.843 206.272 173.794 206.08 173.794C205.933 173.788 205.797 173.818 205.67 173.885C205.538 173.928 205.424 174.001 205.328 174.105C205.224 174.201 205.151 174.315 205.108 174.447C205.041 174.574 205.011 174.711 205.016 174.857C205.029 174.951 205.042 175.046 205.054 175.14C205.105 175.32 205.196 175.477 205.328 175.609C205.399 175.665 205.471 175.72 205.543 175.776C205.709 175.872 205.888 175.92 206.08 175.921C206.226 175.926 206.363 175.896 206.49 175.829C206.622 175.786 206.736 175.713 206.832 175.609C206.936 175.513 207.009 175.399 207.052 175.267C207.119 175.14 207.149 175.004 207.144 174.857C207.131 174.764 207.119 174.669 207.107 174.575Z" fill="white" />
        <path d="M200.965 165.261C202.051 165.745 204.85 166.661 208.471 166.474C208.554 164.685 208.554 163.227 208.554 163.227L200.73 164.018C200.73 164.018 200.824 164.516 200.965 165.261Z" fill="#B44E50" />
        <path d="M183.796 171.72C183.796 171.72 178.929 172.253 177.028 173.317C175.127 174.382 174.215 177.957 177.56 177.957C180.906 177.957 186.229 177.957 188.51 177.957C190.791 177.957 189.879 172.861 188.662 171.492L183.796 171.72Z" fill="#2E344B" />
        <path d="M181.566 164.018C181.566 164.018 182.805 170.665 183.149 171.756C183.676 173.427 188.358 173.34 188.775 171.492C189.39 168.766 189.39 163.227 189.39 163.227L181.566 164.018Z" fill="#D8877B" />
        <path d="M180.708 173.452C180.601 173.259 180.464 173.078 180.302 172.928C180.133 172.772 179.946 172.634 179.753 172.51C179.55 172.379 179.315 172.219 179.064 172.236C178.9 172.247 178.729 172.406 178.796 172.587C178.825 172.663 178.854 172.733 178.908 172.795C178.94 172.833 178.977 172.868 179.012 172.903C179.045 172.935 179.083 172.962 179.12 172.991C179.126 172.996 179.139 173.005 179.149 173.011C179.144 173.008 179.14 173.005 179.129 172.998C179.17 173.025 179.208 173.061 179.247 173.092C179.399 173.217 179.544 173.35 179.673 173.499C179.7 173.53 179.724 173.564 179.753 173.595C179.657 173.489 179.776 173.627 179.793 173.649C179.84 173.723 179.895 173.792 179.938 173.87C179.961 173.91 179.981 173.951 180.001 173.992C180.006 174.003 180.008 174.007 180.009 174.01C180.024 174.046 180.038 174.082 180.056 174.117C180.076 174.152 180.094 174.188 180.113 174.223C180.16 174.309 180.234 174.362 180.314 174.412C180.482 174.518 180.693 174.44 180.796 174.286C180.969 174.026 180.845 173.699 180.708 173.452Z" fill="white" />
        <path d="M182.636 173.268C182.598 173.146 182.553 173.025 182.489 172.914C182.377 172.721 182.238 172.539 182.084 172.377C181.775 172.053 181.358 171.763 180.903 171.72C180.785 171.708 180.668 171.805 180.625 171.906C180.582 172.007 180.594 172.163 180.688 172.237C180.774 172.305 180.86 172.367 180.95 172.428C180.97 172.442 180.991 172.456 181.011 172.47C181.014 172.472 181.018 172.474 181.021 172.476C181.052 172.492 181.083 172.528 181.11 172.55C181.25 172.669 181.378 172.793 181.494 172.935C181.507 172.953 181.519 172.97 181.532 172.988C181.558 173.025 181.582 173.063 181.605 173.102C181.652 173.179 181.695 173.259 181.733 173.342C181.771 173.424 181.81 173.505 181.849 173.586C181.854 173.598 181.854 173.598 181.853 173.595C181.86 173.611 181.866 173.626 181.873 173.642C181.897 173.685 181.933 173.728 181.968 173.762C182 173.791 182.034 173.818 182.065 173.847C182.249 174.02 182.535 173.94 182.612 173.703C182.659 173.558 182.683 173.421 182.636 173.268Z" fill="white" />
        <path d="M187.94 174.575C187.889 174.395 187.798 174.238 187.666 174.106C187.594 174.05 187.523 173.995 187.451 173.939C187.285 173.843 187.106 173.794 186.914 173.794C186.767 173.788 186.631 173.818 186.504 173.885C186.372 173.928 186.258 174.001 186.162 174.105C186.058 174.201 185.985 174.315 185.942 174.447C185.875 174.574 185.845 174.711 185.85 174.857C185.863 174.951 185.876 175.046 185.888 175.14C185.939 175.32 186.03 175.477 186.162 175.609C186.233 175.665 186.305 175.72 186.377 175.776C186.543 175.872 186.722 175.92 186.914 175.921C187.06 175.926 187.197 175.896 187.324 175.829C187.456 175.786 187.57 175.713 187.666 175.609C187.77 175.513 187.843 175.399 187.886 175.267C187.953 175.14 187.983 175.004 187.977 174.857C187.965 174.764 187.953 174.669 187.94 174.575Z" fill="white" />
        <path d="M181.801 165.261C182.887 165.745 185.686 166.661 189.307 166.474C189.39 164.685 189.39 163.227 189.39 163.227L181.566 164.018C181.566 164.018 181.66 164.516 181.801 165.261Z" fill="#B44E50" />
        <path d="M205.391 111.084C206.006 113.106 207.94 118.382 208.116 124.538C208.292 130.694 208.995 160.327 209.259 164.899H199.852C199.852 164.899 193.611 142.213 193.259 125.066C193.259 125.066 190.995 138.774 190.182 144.763C189.215 151.885 190.534 164.988 190.534 164.988H180.512C180.512 164.988 177.26 143.18 178.754 131.749C180.249 120.317 182.798 110.293 182.798 110.293L205.391 111.084Z" fill="#4F6E59" />
        <path d="M206.091 113.312C205.804 112.377 205.551 111.618 205.39 111.088L194.065 110.691L191.852 111.352L191.896 113.593C191.896 113.593 198.576 115.501 206.091 113.312Z" fill="#2E344B" />
        <path d="M177.039 90.7784C177.039 90.7784 179.874 92.1628 182.874 91.9324C185.874 91.7015 182.676 89.625 182.676 89.625L177.039 90.7784Z" fill="#2E344B" />
        <path d="M183.806 91.0351C184.038 90.9712 182.971 95.036 182.048 97.0144C181.474 98.2464 177.037 104.093 177.037 104.093L173.125 101.719C173.125 101.719 177.037 95.1016 177.367 94.31C177.696 93.5184 178.488 87.7812 178.488 87.7812L182.576 87.1875L183.806 91.0351Z" fill="#D8877B" />
        <path d="M178.49 87.7783C178.49 87.7783 178.271 89.3637 178.016 90.9827C178.778 91.1447 181.76 91.82 183.49 92.7891C183.763 91.7621 183.909 91.0042 183.808 91.0323L182.746 87.7105L181.675 87.3164L178.49 87.7783Z" fill="#B44E50" />
        <path d="M199.094 76.2031C202.225 76.6358 204.731 77.6248 206.182 79.1418C207.632 80.6588 208.028 94.772 207.369 96.6843L206.621 96.2664L204.929 97.8711C204.929 97.8711 204.863 106.444 206.248 111.259C206.248 111.259 195.303 116.271 182.248 110.336C182.248 110.336 184.819 105.917 184.094 98.4648C183.979 97.2813 182.71 94.1778 182.775 91.2105C182.775 91.2105 178.951 90.4851 177.039 90.7816C177.039 90.7816 178.754 81.9112 180.336 79.8672C181.918 77.8226 185.412 76.7676 186.731 76.4379C188.05 76.1083 190.489 76.8993 190.489 76.8993L195.83 76.6353L199.094 76.2031Z" fill="#FBCCBB" />
        <path d="M205.976 110.183C205.941 110.026 205.907 109.868 205.874 109.707C204.801 110.061 203.721 110.387 202.617 110.65C200.982 111.039 199.327 111.312 197.655 111.473C194.306 111.794 190.915 111.682 187.577 111.29C185.952 111.099 184.34 110.831 182.73 110.547C183.159 110.734 183.585 110.909 184.009 111.073C186.609 111.637 189.283 111.925 191.926 112.03C195.34 112.167 198.785 111.908 202.135 111.226C203.428 110.962 204.721 110.621 205.976 110.183Z" fill="#2E344B" />
        <path d="M167.59 102.023L191.173 121.52C192.441 122.569 194.308 122.44 195.42 121.227L195.82 120.791C196.943 119.567 196.898 117.673 195.718 116.504L174.007 94.9883L167.59 102.023Z" fill="url(#paint3_linear_2286_189406)" />
        <path d="M196.082 57.7054C196.082 57.7054 196.695 53.5938 201.556 53.3584C206.416 53.123 208.353 56.7942 208.753 60.4352C209.154 64.0761 209.644 65.9861 210.813 66.7286C210.813 66.7286 206.1 70.7389 202.283 66.0208C198.466 61.3028 196.082 57.7054 196.082 57.7054Z" fill="#5F2A3D" />
        <path d="M199.503 53.7461C196.514 54.8232 196.082 57.7023 196.082 57.7023C196.082 57.7023 198.371 61.1542 202.05 65.7267C202.715 65.6186 203.231 65.0194 203.493 63.7075C204.612 58.0997 200.979 54.8216 199.503 53.7461Z" fill="#2E344B" />
        <path d="M188.294 73.6315C185.244 71.5208 183.805 71.9072 183.194 63.9513C182.582 55.9953 195.047 49.3027 201.07 56.9362C204.858 61.7364 205.19 70.3472 199.093 72.4486C192.266 74.8018 188.294 73.6315 188.294 73.6315Z" fill="#5F2A3D" />
        <path d="M157.04 105.945C168.583 105.945 177.941 96.5851 177.941 85.039C177.941 73.4928 168.583 64.1328 157.04 64.1328C145.496 64.1328 136.139 73.4928 136.139 85.039C136.139 96.5851 145.496 105.945 157.04 105.945Z" fill="url(#paint4_linear_2286_189406)" />
        <path d="M157.037 100.788C165.736 100.788 172.788 93.7342 172.788 85.0328C172.788 76.3313 165.736 69.2773 157.037 69.2773C148.337 69.2773 141.285 76.3313 141.285 85.0328C141.285 93.7342 148.337 100.788 157.037 100.788Z" fill="#B9CFE6" />
        <path d="M189.17 73.0554C189.17 73.0554 189.126 74.1987 188.906 75.122C188.686 76.0453 186.73 76.4411 186.73 76.4411C186.73 76.4411 188.071 79.7386 191.148 80.3543L192.203 84.7951L193.697 80.5302C193.697 80.5302 197.291 79.9877 199.093 76.2063C199.093 76.2063 198.401 76.1335 197.477 75.7377C196.554 75.3419 196.598 72.6602 196.598 72.6602L189.17 73.0554Z" fill="#D8877B" />
        <path d="M170.315 107.211C169.739 105.146 170.315 103.782 170.799 103.122C171.282 102.463 171.678 103.298 171.722 103.562C171.766 103.826 171.634 105.584 171.634 105.584C171.634 105.584 171.985 104.485 172.688 103.254C173.392 102.023 174.183 101.627 174.666 102.551C174.666 102.551 175.37 102.155 175.809 103.21C175.809 103.21 176.732 103.386 176.557 104.617C176.557 104.617 177.172 105.144 176.952 106.024C176.732 106.903 175.765 107.914 175.37 109.277C174.974 110.641 171.098 110.013 170.315 107.211Z" fill="#D8877B" />
        <path d="M207.369 96.6886C206.017 97.0512 200.149 95.6007 199.391 95.1719C199.391 95.1719 199.803 94.7597 200.38 94.6445L203.94 95.5511L205.391 95.8972L206.622 96.2708L207.369 96.6886Z" fill="#2E344B" />
        <path d="M200.38 94.6383C200.38 94.6383 199.764 96.9027 198.797 98.2654C197.83 99.6286 188.819 108.839 188.336 109.235C187.853 109.631 184.688 109.499 183.765 110.071C182.841 110.642 183.589 111.258 184.292 111.214C184.995 111.17 186.182 111.346 186.182 111.346C186.182 111.346 185.435 112.665 184.995 113.104C184.555 113.544 183.237 114.203 182.753 114.335C182.27 114.467 182.358 115.566 182.929 115.522C182.929 115.522 182.358 116.534 183.545 116.929C183.545 116.929 183.281 117.588 184.424 117.677C184.424 117.677 184.468 117.984 185.698 117.897C186.929 117.808 188.468 116.226 189.127 115.566C189.786 114.907 190.929 112.841 191.236 112.621C191.544 112.401 201.654 104.091 203.192 102.332C204.731 100.573 206.621 96.265 206.621 96.265C206.621 96.265 203.412 94.0225 200.38 94.6383Z" fill="#D8877B" />
        <path d="M198.001 75.9359C197.837 75.8802 197.664 75.8168 197.478 75.7369C196.555 75.3412 196.599 72.6602 196.599 72.6602L192.608 72.8729L190.169 73.262L189.085 74.0732C189.069 74.2104 189.049 74.3548 189.025 74.5008C189.848 75.2476 192.955 77.5941 198.001 75.9359Z" fill="#B44E50" />
        <path d="M199.674 64.7871C199.674 64.7871 201.539 63.6819 202.712 65.5445C203.885 67.4071 201.972 68.9925 200.402 68.9919C200.402 68.9919 198.919 75.5649 193.24 75.8096C187.589 76.0533 185.157 70.2642 184.699 65.98C184.699 65.98 187.243 64.7772 187.525 63.3985C187.807 62.0199 191.284 61.87 193.019 59.3359C193.019 59.3359 194.358 61.8744 196.003 62.0083C197.647 62.1417 199.292 63.8473 199.674 64.7871Z" fill="#D8877B" />
        <path d="M196.002 62.0083C194.357 61.8744 193.019 59.3359 193.019 59.3359C191.284 61.87 187.807 62.0199 187.525 63.3985C187.243 64.7772 184.699 65.98 184.699 65.98C184.715 66.1238 184.732 66.2694 184.752 66.416C185.74 66.1878 187.523 64.9326 187.935 63.99C188.397 62.935 191.215 62.4565 192.864 60.5442C192.864 60.5442 194.083 62.424 195.864 62.6219C197.252 62.7762 198.687 63.9084 199.443 64.3577C198.833 63.4035 197.419 62.1241 196.002 62.0083Z" fill="#B44E50" />
        <path d="M201.967 65.8542C202.027 65.7065 201.888 65.55 201.786 65.4629C201.663 65.3599 201.533 65.2772 201.379 65.2298C201.099 65.1439 200.763 65.199 200.564 65.4277C200.381 65.6382 200.345 65.9297 200.358 66.1992C200.374 66.5392 200.417 66.8809 200.471 67.2165C200.501 67.4028 200.784 67.4711 200.902 67.3301C201.016 67.1945 201.282 67.0154 201.436 67.2088C201.501 67.2909 201.461 67.4171 201.418 67.4987C201.382 67.5687 201.329 67.6282 201.291 67.6976C201.249 67.7742 201.223 67.8564 201.204 67.9407C201.178 68.0547 201.28 68.1754 201.401 68.1374C201.666 68.0548 201.829 67.8597 201.918 67.5995C202.004 67.3499 201.962 67.0771 201.79 66.8749C201.565 66.6087 201.224 66.5773 200.929 66.6908C200.915 66.5602 200.894 66.4301 200.889 66.2978C200.882 66.1342 200.861 65.9126 200.972 65.7831C201.23 65.4822 201.789 66.2896 201.967 65.8542Z" fill="#B44E50" />
        <path d="M200.379 94.6382C200.379 94.6382 200.343 94.7683 200.277 94.9805C200.778 95.1397 203.832 95.6484 206.142 97.3028C206.438 96.6795 206.62 96.2645 206.62 96.2645C206.62 96.2645 203.411 94.0227 200.379 94.6382Z" fill="#B44E50" />
        <path d="M186.998 113.138C186.824 113.218 186.702 113.413 186.58 113.555C186.441 113.716 186.304 113.879 186.154 114.03C185.877 114.31 185.565 114.557 185.227 114.76C184.559 115.161 183.825 115.448 183.053 115.561C182.971 115.573 182.989 115.713 183.073 115.708C183.881 115.653 184.675 115.424 185.359 114.985C185.69 114.772 186.002 114.527 186.281 114.248C186.447 114.083 186.602 113.908 186.757 113.733C186.89 113.582 187.064 113.436 187.139 113.247C187.172 113.16 187.065 113.107 186.998 113.138Z" fill="#B44E50" />
        <path d="M187.889 114.588C187.578 114.852 187.294 115.139 186.967 115.384C186.643 115.628 186.296 115.841 185.937 116.03C185.218 116.41 184.436 116.668 183.642 116.839C183.536 116.863 183.583 117.016 183.686 117C184.513 116.869 185.299 116.593 186.045 116.217C186.418 116.029 186.776 115.812 187.119 115.575C187.455 115.343 187.811 115.09 188.087 114.787C188.211 114.652 188.026 114.472 187.889 114.588Z" fill="#B44E50" />
        <path d="M187.898 115.854C187.63 115.941 187.415 116.172 187.186 116.331C186.938 116.503 186.681 116.663 186.415 116.804C186.149 116.946 185.876 117.073 185.595 117.182C185.306 117.295 185 117.373 184.715 117.492C184.632 117.526 184.628 117.682 184.739 117.668C185.04 117.629 185.346 117.515 185.628 117.404C185.917 117.29 186.2 117.161 186.475 117.015C186.749 116.87 187.016 116.71 187.273 116.535C187.51 116.374 187.826 116.195 187.958 115.932C187.978 115.894 187.945 115.839 187.898 115.854Z" fill="#B44E50" />
        <path d="M174.513 102.629C174.376 102.625 174.282 102.763 174.201 102.86C174.097 102.983 174.004 103.118 173.912 103.25C173.716 103.534 173.542 103.834 173.388 104.145C173.242 104.44 173.108 104.744 172.995 105.053C172.937 105.211 172.884 105.371 172.836 105.533C172.795 105.673 172.708 105.868 172.765 106.011C172.798 106.092 172.888 106.123 172.957 106.061C173.062 105.968 173.075 105.798 173.111 105.667C173.155 105.504 173.204 105.343 173.258 105.184C173.366 104.868 173.494 104.56 173.647 104.264C173.792 103.981 173.957 103.708 174.132 103.444C174.225 103.303 174.328 103.17 174.425 103.033C174.499 102.928 174.608 102.816 174.581 102.681C174.575 102.65 174.542 102.63 174.513 102.629Z" fill="#B44E50" />
        <path d="M175.711 103.375C175.573 103.374 175.481 103.485 175.389 103.576C175.28 103.684 175.18 103.8 175.086 103.922C174.9 104.162 174.744 104.422 174.613 104.695C174.473 104.986 174.354 105.29 174.266 105.6C174.219 105.767 174.177 105.936 174.138 106.105C174.1 106.269 174.043 106.444 174.043 106.613C174.043 106.69 174.156 106.726 174.194 106.653C174.264 106.519 174.293 106.365 174.336 106.22C174.38 106.072 174.426 105.924 174.476 105.778C174.581 105.465 174.69 105.152 174.832 104.853C174.954 104.598 175.097 104.352 175.265 104.125C175.35 104.011 175.442 103.902 175.541 103.8C175.631 103.707 175.772 103.622 175.798 103.488C175.808 103.432 175.773 103.376 175.711 103.375Z" fill="#B44E50" />
        <path d="M176.447 104.704C176.319 104.692 176.234 104.795 176.153 104.879C176.061 104.976 175.98 105.084 175.905 105.194C175.741 105.432 175.608 105.691 175.495 105.956C175.385 106.214 175.29 106.477 175.205 106.744C175.126 106.99 174.96 107.327 175.074 107.581C175.111 107.663 175.231 107.616 175.233 107.538C175.238 107.388 175.248 107.263 175.295 107.119C175.337 106.99 175.38 106.862 175.424 106.734C175.511 106.48 175.603 106.226 175.712 105.98C175.816 105.75 175.934 105.527 176.076 105.319C176.153 105.206 176.239 105.098 176.329 104.993C176.398 104.913 176.476 104.847 176.476 104.734C176.476 104.717 176.463 104.706 176.447 104.704Z" fill="#B44E50" />
        <path d="M184.326 83.6957C183.934 84.1747 183.763 84.8202 183.57 85.3984C183.352 86.0555 183.181 86.7297 183.036 87.4071C182.901 88.041 182.794 88.6799 182.721 89.3243C182.656 89.907 182.47 90.687 182.713 91.2443C182.734 91.2928 182.812 91.2873 182.835 91.2443C182.964 91.0006 182.979 90.7245 183.002 90.4538C183.03 90.1187 183.061 89.784 183.101 89.45C183.184 88.7802 183.29 88.1116 183.427 87.4507C183.558 86.8123 183.724 86.1834 183.9 85.5561C184.069 84.9558 184.349 84.3511 184.394 83.7254C184.398 83.6907 184.348 83.6686 184.326 83.6957Z" fill="#2E344B" />
        <path d="M198.607 79.9142C199.186 79.3283 199.641 78.6376 199.959 77.8785C200.116 77.5026 200.255 77.1096 200.311 76.7044C200.324 76.6101 200.341 76.5115 200.357 76.4111C200.26 76.3918 200.161 76.3737 200.061 76.3555C200.042 76.4597 200.018 76.5633 199.984 76.6653C199.857 77.0379 199.745 77.4111 199.579 77.7694C199.255 78.4634 198.815 79.1078 198.27 79.6474C197.727 80.186 197.081 80.6275 196.385 80.9439C196.042 81.0999 195.686 81.2152 195.323 81.3144C195.134 81.3662 194.944 81.4136 194.752 81.4505C194.666 81.4671 194.587 81.4759 194.515 81.499C194.439 81.4935 194.358 81.5283 194.326 81.6209C193.719 83.3578 192.999 85.0347 192.11 86.6437C191.783 85.8202 191.546 84.9707 191.316 84.1136C191.186 83.6312 191.069 83.1461 190.957 82.6594C190.855 82.2129 190.797 81.6843 190.593 81.273C190.589 81.2648 190.581 81.2642 190.576 81.2576C190.583 81.2289 190.583 81.1975 190.57 81.1611C190.501 80.9528 190.111 80.912 189.93 80.8508C188.935 80.514 188.015 79.9594 187.259 79.2302C186.543 78.54 185.953 77.6872 185.516 76.7821C185.459 76.8003 185.403 76.8185 185.344 76.8383C185.866 78.6288 187.242 80.0984 188.912 80.9236C189.134 81.0332 189.361 81.1292 189.594 81.2124C189.8 81.2857 190.045 81.386 190.272 81.4009C190.27 81.6341 190.336 81.8706 190.379 82.0982C190.429 82.3617 190.483 82.6236 190.541 82.8854C190.648 83.3727 190.759 83.8605 190.888 84.3429C191.151 85.3197 191.509 86.2766 191.917 87.2016C191.977 87.3388 192.187 87.3184 192.254 87.2016C193.227 85.49 194.06 83.6731 194.65 81.7918C194.907 81.8039 195.228 81.7135 195.422 81.6732C195.822 81.5905 196.213 81.4511 196.587 81.289C197.341 80.9616 198.029 80.4997 198.607 79.9142Z" fill="#2E344B" />
        <path d="M200.926 86.5438C200.919 86.5245 200.886 86.5129 200.875 86.5371C200.623 87.111 200.668 87.8392 200.612 88.4565C200.545 89.2046 200.448 89.9482 200.339 90.6913C200.125 92.1283 199.759 93.5268 199.48 94.9501C199.445 95.1265 199.685 95.191 199.747 95.024C200.256 93.6541 200.517 92.1735 200.734 90.7326C200.837 90.043 200.911 89.3484 200.966 88.6533C200.994 88.3033 201.015 87.9544 201.022 87.6032C201.028 87.2367 201.057 86.8916 200.926 86.5438Z" fill="#2E344B" />
        <path d="M196.656 99.1369C196.237 99.2802 195.869 99.521 195.48 99.7304C195.044 99.9647 194.594 100.175 194.131 100.353C193.233 100.698 192.295 100.941 191.335 101.013C189.539 101.146 187.628 100.68 186.173 99.5998C186.156 99.5871 186.134 99.6153 186.15 99.6301C187.556 100.902 189.404 101.436 191.28 101.333C192.266 101.28 193.247 101.062 194.169 100.71C194.623 100.537 195.067 100.336 195.497 100.11C195.94 99.877 196.393 99.6378 196.73 99.262C196.775 99.2102 196.731 99.1116 196.656 99.1369Z" fill="#2E344B" />
        <path d="M197.242 97.8799C196.903 98.1124 196.57 98.35 196.209 98.5495C196.008 98.6608 195.798 98.7534 195.594 98.8592C195.403 98.9584 195.173 99.0284 195.012 99.1739C195.003 99.1821 195.011 99.1942 195.021 99.1959C195.214 99.2218 195.416 99.1474 195.6 99.0939C195.79 99.0383 195.972 98.9611 196.153 98.8801C196.526 98.7126 196.896 98.5203 197.228 98.2816C197.347 98.1962 197.63 98.0419 197.614 97.8683C197.594 97.6611 197.327 97.822 197.242 97.8799Z" fill="#2E344B" />
        <path d="M193.476 124.683C193.471 124.376 193.471 124.069 193.471 123.763C193.471 123.487 193.544 123.118 193.409 122.868C193.348 122.755 193.193 122.77 193.129 122.868C193.045 122.996 193.043 123.154 193.036 123.303C193.028 123.468 193.021 123.633 193.016 123.798C193.006 124.105 193.006 124.411 193.011 124.718C193.019 125.346 193.02 125.985 193.179 126.597C193.226 126.777 193.467 126.722 193.485 126.555C193.547 125.933 193.486 125.307 193.476 124.683Z" fill="#2E344B" />
        <path d="M190.298 134.516C190.557 133.247 190.836 131.983 191.142 130.724C191.457 129.429 191.854 128.133 192.075 126.82C192.083 126.775 192.015 126.735 191.993 126.785C191.545 127.838 191.272 128.97 190.972 130.073C190.662 131.218 190.392 132.376 190.142 133.534C189.642 135.854 189.23 138.195 188.913 140.546C188.283 145.209 187.973 149.953 188.084 154.659C188.144 157.209 188.289 159.775 188.649 162.303C186.171 162.296 183.694 162.288 181.216 162.293C180.858 162.294 180.499 162.292 180.141 162.291C180.162 162.451 180.182 162.604 180.201 162.752C183.037 162.757 185.873 162.749 188.709 162.74C189.251 162.738 189.793 162.737 190.335 162.736C190.324 162.597 190.312 162.456 190.301 162.306C189.879 162.304 189.458 162.305 189.036 162.304C188.835 160.059 188.628 157.818 188.545 155.564C188.458 153.219 188.444 150.862 188.537 148.518C188.724 143.82 189.358 139.12 190.298 134.516Z" fill="#2E344B" />
        <path d="M209.146 162.28C208.559 162.282 207.972 162.284 207.386 162.285C207.409 162.257 207.426 162.22 207.423 162.171C207.297 159.059 207.138 155.951 207.115 152.835C207.104 151.302 207.115 149.768 207.17 148.236C207.226 146.69 207.376 145.148 207.418 143.602C207.501 140.559 206.931 137.56 206.796 134.528C206.728 133.014 206.798 131.499 206.807 129.984C206.815 128.475 206.763 126.964 206.645 125.458C206.382 122.128 205.871 118.744 204.841 115.559C204.806 115.449 204.643 115.494 204.669 115.607C205.336 118.566 205.952 121.518 206.235 124.543C206.374 126.023 206.454 127.511 206.463 128.997C206.473 130.508 206.394 132.017 206.412 133.528C206.449 136.563 207.049 139.561 207.071 142.597C207.082 144.118 206.945 145.637 206.859 147.154C206.773 148.685 206.716 150.219 206.713 151.752C206.705 155.226 206.85 158.703 207.054 162.17C207.056 162.219 207.077 162.256 207.104 162.285C205.198 162.291 203.291 162.297 201.386 162.301C200.649 162.302 199.913 162.304 199.176 162.306C199.214 162.457 199.251 162.602 199.287 162.742C202.029 162.747 204.771 162.752 207.513 162.757C208.063 162.758 208.614 162.76 209.165 162.761C209.158 162.607 209.152 162.447 209.146 162.28Z" fill="#2E344B" />
        <path d="M189.633 67.7569C189.602 67.365 189.44 66.9859 189.14 66.8316C189.052 66.7864 188.96 66.7654 188.862 66.7682C188.796 66.7561 188.732 66.761 188.668 66.7825C188.571 66.7941 188.483 66.8283 188.403 66.8856C188.356 66.9258 188.31 66.966 188.264 67.0063C188.18 67.1005 188.125 67.208 188.099 67.3292C187.979 67.8908 188.059 69.0321 188.895 68.9346C189.085 68.912 189.279 68.837 189.403 68.6877C189.583 68.4689 189.661 68.1079 189.633 67.7569Z" fill="#2E344B" />
        <path d="M195.012 67.8936C194.981 67.5018 194.819 67.1226 194.519 66.9683C194.431 66.9231 194.339 66.9022 194.241 66.9049C194.175 66.8928 194.111 66.8978 194.047 66.9192C193.95 66.9308 193.862 66.965 193.782 67.0223C193.735 67.0625 193.689 67.1028 193.643 67.143C193.559 67.2372 193.504 67.3447 193.478 67.4659C193.358 68.0275 193.438 69.1688 194.274 69.0713C194.464 69.0487 194.658 68.9743 194.782 68.8244C194.961 68.6056 195.04 68.2446 195.012 67.8936Z" fill="#2E344B" />
        <path d="M191.567 70.7465C191.49 70.6727 191.415 70.6054 191.327 70.5437C191.246 70.4881 191.16 70.4423 191.088 70.3751C190.944 70.2417 190.857 70.061 191.001 69.8956C191.17 69.7027 191.3 69.4977 191.386 69.2553C191.461 69.0464 191.549 68.729 191.439 68.5217C191.392 68.4319 191.259 68.4391 191.21 68.5217C191.141 68.6375 191.137 68.7901 191.1 68.9191C191.063 69.0447 191.011 69.1632 190.945 69.2756C190.879 69.3875 190.803 69.4878 190.714 69.5826C190.605 69.6994 190.512 69.8185 190.496 69.9827C190.469 70.2428 190.606 70.4974 190.8 70.6622C190.896 70.7432 191.002 70.8176 191.117 70.8689C191.237 70.9229 191.353 70.9383 191.484 70.9455C191.586 70.9526 191.638 70.8149 191.567 70.7465Z" fill="#B44E50" />
        <path d="M194.126 67.124C194.108 67.0607 194.076 67.005 194.029 66.9582L193.953 66.8992C193.894 66.865 193.831 66.8479 193.763 66.8479C193.711 66.8457 193.663 66.8567 193.618 66.8804C193.572 66.8953 193.531 66.9218 193.497 66.9582C193.461 66.9923 193.435 67.0325 193.419 67.0794C193.396 67.124 193.385 67.1725 193.387 67.2243C193.391 67.2579 193.396 67.291 193.4 67.3241C193.418 67.388 193.45 67.4431 193.497 67.49L193.573 67.5489C193.631 67.5831 193.694 67.6002 193.762 67.6002C193.814 67.6024 193.862 67.5914 193.907 67.5677C193.954 67.5522 193.994 67.5263 194.028 67.49C194.065 67.4558 194.091 67.4156 194.106 67.3693C194.129 67.3246 194.14 67.2761 194.138 67.2243C194.135 67.1902 194.13 67.1571 194.126 67.124Z" fill="white" />
        <path d="M188.383 69.4133C188.383 69.8161 187.882 70.1429 187.266 70.1429C186.649 70.1429 186.148 69.8161 186.148 69.4133C186.148 69.0104 186.649 68.6836 187.266 68.6836C187.883 68.6836 188.383 69.0104 188.383 69.4133Z" fill="#C85F64" />
        <path d="M197.136 69.5734C197.136 69.9763 196.637 70.3031 196.019 70.3031C195.403 70.3031 194.902 69.9763 194.902 69.5734C194.902 69.1706 195.402 68.8438 196.019 68.8438C196.637 68.8438 197.136 69.1706 197.136 69.5734Z" fill="#C85F64" />
        <path d="M195.242 66.4598C195.203 66.4207 195.135 66.3976 195.081 66.4174C195.069 66.4218 195.057 66.4268 195.044 66.4323C195.029 66.4367 195.016 66.4438 195.005 66.4549C194.998 66.4604 194.991 66.4659 194.984 66.472C194.982 66.4742 194.979 66.4769 194.977 66.4797C194.966 66.4907 194.955 66.5017 194.945 66.5133L194.881 66.5805C194.863 66.6004 194.845 66.6219 194.834 66.6467C194.827 66.6604 194.821 66.6737 194.815 66.6874C194.811 66.694 194.808 66.7012 194.805 66.7078C194.804 66.7117 194.801 66.7155 194.8 66.7194C194.797 66.7255 194.794 66.7315 194.792 66.7381C194.786 66.7486 194.783 66.7602 194.783 66.7729C194.778 66.8043 194.784 66.8351 194.8 66.8632C194.809 66.8803 194.825 66.8991 194.842 66.9073C194.849 66.9106 194.855 66.9134 194.863 66.9161C194.897 66.931 194.935 66.931 194.97 66.9161C194.989 66.9084 195.006 66.8996 195.024 66.8908C195.037 66.8847 195.049 66.8781 195.062 66.8721C195.087 66.8594 195.108 66.8412 195.129 66.8214C195.149 66.801 195.17 66.7806 195.19 66.7602C195.201 66.7492 195.213 66.7387 195.224 66.7277C195.226 66.7255 195.228 66.7227 195.231 66.7205C195.24 66.7089 195.249 66.6968 195.258 66.6852L195.262 66.6814C195.272 66.6632 195.278 66.6422 195.285 66.6224C195.303 66.5656 195.282 66.4995 195.242 66.4598Z" fill="#C85F64" />
        <path d="M195.519 67.1135C195.515 67.1052 195.511 67.0969 195.508 67.0887C195.501 67.0766 195.493 67.0666 195.481 67.0595C195.474 67.0529 195.467 67.0484 195.458 67.0462C195.446 67.0396 195.433 67.0369 195.418 67.0374L195.368 67.0314C195.36 67.0303 195.352 67.0292 195.343 67.0281C195.325 67.0259 195.307 67.0292 195.29 67.0325C195.274 67.0352 195.258 67.0374 195.242 67.0418C195.221 67.0479 195.201 67.0611 195.182 67.0721C195.129 67.1014 195.129 67.1824 195.182 67.2116C195.201 67.222 195.22 67.2336 195.24 67.2408C195.256 67.2463 195.274 67.2479 195.29 67.2507C195.307 67.254 195.323 67.2573 195.341 67.2551C195.349 67.254 195.357 67.2529 195.366 67.2518L195.418 67.2452C195.428 67.2441 195.436 67.2424 195.446 67.2413C195.46 67.238 195.471 67.232 195.481 67.2226C195.498 67.2094 195.514 67.1901 195.519 67.1686C195.521 67.1598 195.523 67.1493 195.523 67.141C195.522 67.1317 195.521 67.1223 195.519 67.1135Z" fill="#C85F64" />
        <path d="M188.563 66.9912C188.545 66.9273 188.513 66.8722 188.466 66.8253L188.39 66.7664C188.332 66.7322 188.269 66.7151 188.201 66.7151C188.149 66.7129 188.1 66.7239 188.056 66.7476C188.009 66.7625 187.969 66.7884 187.935 66.8253C187.898 66.8595 187.872 66.8997 187.857 66.9466C187.833 66.9912 187.823 67.0397 187.824 67.0915C187.829 67.1251 187.833 67.1582 187.838 67.1913C187.855 67.2552 187.888 67.3103 187.934 67.3571L188.01 67.4161C188.069 67.4503 188.132 67.4674 188.2 67.4674C188.251 67.4696 188.3 67.4586 188.345 67.4349C188.391 67.42 188.432 67.3941 188.465 67.3571C188.502 67.323 188.528 67.2828 188.543 67.2365C188.567 67.1918 188.577 67.1433 188.576 67.0915C188.572 67.0579 188.568 67.0248 188.563 66.9912Z" fill="white" />
        <path d="M187.727 66.4909C187.722 66.481 187.716 66.4716 187.707 66.4645C187.692 66.4502 187.675 66.4402 187.655 66.4342L187.672 66.4413C187.657 66.4347 187.643 66.4254 187.629 66.416C187.625 66.4127 187.622 66.4099 187.618 66.4061C187.608 66.3956 187.597 66.3846 187.583 66.3774C187.578 66.3752 187.574 66.3724 187.57 66.3702C187.554 66.3609 187.541 66.3488 187.523 66.3443C187.509 66.3416 187.496 66.3416 187.483 66.3399C187.485 66.3405 187.487 66.3405 187.489 66.3405C187.468 66.3377 187.449 66.3438 187.433 66.3587C187.432 66.3603 187.43 66.362 187.428 66.3636C187.422 66.3713 187.416 66.3791 187.41 66.3873C187.404 66.3956 187.401 66.4022 187.398 66.4143C187.396 66.4242 187.394 66.4364 187.395 66.4463C187.396 66.4661 187.399 66.486 187.405 66.5053C187.405 66.508 187.406 66.5102 187.407 66.513C187.414 66.5345 187.423 66.5516 187.439 66.5692C187.443 66.5736 187.447 66.578 187.452 66.5824C187.475 66.6056 187.502 66.6265 187.531 66.6419C187.536 66.6447 187.541 66.6469 187.547 66.6491C187.57 66.6596 187.595 66.6722 187.621 66.6711C187.654 66.6695 187.687 66.6579 187.71 66.6331C187.729 66.6122 187.746 66.5852 187.744 66.5554C187.741 66.5328 187.739 66.5124 187.727 66.4909Z" fill="#C85F64" />
        <path d="M187.481 67.0749C187.464 67.0638 187.446 67.0523 187.425 67.0479C187.404 67.0429 187.382 67.0418 187.36 67.0396C187.334 67.0368 187.309 67.0357 187.284 67.0352C187.214 67.0346 187.14 67.0401 187.078 67.0749C187.032 67.1013 187.004 67.1641 187.021 67.2159C187.038 67.2705 187.084 67.3047 187.141 67.308C187.152 67.3085 187.163 67.3063 187.174 67.3058C187.196 67.3041 187.219 67.3025 187.24 67.2981C187.257 67.2942 187.274 67.292 187.291 67.2892C187.312 67.287 187.333 67.2848 187.354 67.2837C187.367 67.2832 187.38 67.2837 187.393 67.2832C187.416 67.2826 187.435 67.2766 187.455 67.2694C187.496 67.2551 187.525 67.2253 187.531 67.1812C187.537 67.1377 187.517 67.0986 187.481 67.0749Z" fill="#C85F64" />
        <path d="M188.856 64.6899C188.903 64.632 188.936 64.5785 188.946 64.5025C188.955 64.4342 188.948 64.3532 188.907 64.2942C188.881 64.2556 188.852 64.2126 188.821 64.1807C188.755 64.114 188.678 64.0837 188.592 64.0517C188.475 64.0087 188.333 64.0313 188.215 64.0534C188.054 64.0837 187.897 64.1454 187.754 64.2242C187.618 64.2997 187.475 64.3972 187.372 64.5146C187.24 64.6662 187.095 64.8833 187.098 65.09C187.101 65.381 187.412 65.5474 187.661 65.4135C187.774 65.3534 187.854 65.2399 187.955 65.1616C187.925 65.1842 187.896 65.2068 187.868 65.2288C187.913 65.1941 187.957 65.1583 188.002 65.123C188.08 65.0646 188.166 65.0183 188.254 64.977C188.328 64.9494 188.401 64.9224 188.479 64.9059C188.557 64.8894 188.63 64.8635 188.701 64.8249C188.758 64.7935 188.809 64.7527 188.849 64.7009C188.849 64.7009 188.852 64.6965 188.852 64.6954C188.847 64.7009 188.846 64.702 188.856 64.6899Z" fill="#5F2A3D" />
        <path d="M188.852 64.6989C188.863 64.6854 188.903 64.6369 188.888 64.651C188.877 64.6606 188.857 64.691 188.852 64.6989Z" fill="#5F2A3D" />
        <path d="M195.217 65.2305C195.185 65.2439 195.191 65.2415 195.217 65.2305V65.2305Z" fill="#5F2A3D" />
        <path d="M193.647 64.6061C193.645 64.605 193.644 64.6038 193.643 64.6026C193.637 64.598 193.631 64.5951 193.625 64.5898C193.627 64.591 193.637 64.5986 193.647 64.6061Z" fill="#5F2A3D" />
        <path d="M195.32 64.6141C195.157 64.3457 194.865 64.1737 194.572 64.0828C194.428 64.0382 194.274 64.0112 194.122 64.0117C193.931 64.0128 193.734 64.0332 193.592 64.176C193.457 64.3126 193.504 64.5033 193.642 64.6102C193.683 64.6411 193.717 64.6626 193.768 64.6808C193.793 64.6896 193.818 64.6967 193.845 64.7023C193.901 64.7149 193.958 64.7265 194.013 64.7436C194.062 64.759 194.111 64.7778 194.16 64.7954C194.186 64.8048 194.212 64.8163 194.238 64.8251C194.116 64.7838 194.212 64.8147 194.245 64.8318C194.346 64.8825 194.445 64.9447 194.537 65.0092C194.541 65.012 194.545 65.0153 194.548 65.0186C194.543 65.0131 194.541 65.012 194.534 65.0048C194.574 65.0439 194.623 65.0787 194.669 65.1101C194.714 65.1398 194.757 65.1729 194.806 65.1949C194.852 65.2164 194.898 65.2412 194.946 65.2567C194.979 65.2671 195.022 65.2743 195.057 65.2743C195.117 65.2743 195.162 65.2528 195.216 65.233C195.224 65.2297 195.227 65.228 195.24 65.223L195.218 65.2324C195.217 65.2324 195.217 65.2324 195.216 65.233C195.223 65.2308 195.229 65.2286 195.235 65.2264C195.238 65.2253 195.241 65.223 195.243 65.2219C195.245 65.2214 195.245 65.2214 195.246 65.2208C195.263 65.2098 195.283 65.1944 195.297 65.1806C195.456 65.0324 195.424 64.7844 195.32 64.6141Z" fill="#5F2A3D" />
        <path d="M194.549 65.0195C194.579 65.0436 194.606 65.0739 194.549 65.0195V65.0195Z" fill="#5F2A3D" />
        <path d="M193.646 64.6133C193.648 64.6145 193.649 64.6164 193.65 64.6171C193.679 64.6385 193.663 64.6265 193.646 64.6133Z" fill="#5F2A3D" />
        <path d="M192.081 71.7168C191.867 71.5895 191.58 71.5432 191.336 71.563C191.04 71.5878 190.762 71.6842 190.539 71.8848C190.388 72.021 190.581 72.2579 190.752 72.161C190.938 72.0557 191.146 71.9901 191.359 71.989C191.416 71.9885 191.472 71.9929 191.529 71.9989C191.534 71.9995 191.58 72.0039 191.556 72.0011C191.534 71.9984 191.572 72.0039 191.572 72.0039C191.605 72.01 191.638 72.016 191.671 72.0243C191.804 72.0568 191.892 72.1047 191.998 72.1422C192.064 72.1659 192.145 72.194 192.213 72.1643C192.223 72.1598 192.25 72.1384 192.26 72.1312C192.279 72.1141 192.291 72.1025 192.302 72.0777C192.367 71.9251 192.196 71.7862 192.081 71.7168Z" fill="#B44E50" />
        <path d="M192.262 72.1278C192.258 72.131 192.258 72.1341 192.254 72.1373C192.27 72.1227 192.269 72.1233 192.262 72.1278Z" fill="#B44E50" />
        <path d="M154.102 73.9999C153.991 73.1703 153.198 72.2894 152.28 72.3908C151.555 72.4707 150.956 72.6653 150.312 73.0159C149.997 73.1874 149.69 73.3742 149.392 73.5721C148.651 74.0638 147.936 74.62 147.304 75.2462C146.69 75.8537 146.114 76.5378 145.731 77.3145C145.515 77.7543 145.334 78.2113 145.206 78.6848C145.098 79.085 145.069 79.4769 145.179 79.8832C145.348 80.5138 145.93 81.1373 146.644 81.0827C147.131 81.0452 147.538 80.8716 147.891 80.5342C148.091 80.3429 148.224 80.1076 148.374 79.8799C148.543 79.6236 148.729 79.3761 148.914 79.1313C149.1 78.8976 149.285 78.665 149.467 78.4279C149.369 78.5553 149.27 78.6826 149.172 78.81C149.663 78.1821 150.232 77.6265 150.852 77.1276C151.353 76.7561 151.887 76.4077 152.445 76.1282C152.553 76.0929 152.659 76.0538 152.773 76.0361C153.184 75.9733 153.694 75.54 153.882 75.1834C154.079 74.808 154.158 74.4221 154.102 73.9999Z" fill="white" />
        <path d="M160.196 73.0546C160.195 72.9097 160.159 72.7746 160.086 72.6495C160.048 72.5134 159.979 72.3955 159.879 72.2952C159.841 72.2213 159.789 72.1591 159.725 72.1095C159.616 72.0251 159.507 71.9408 159.398 71.8565C159.377 71.8416 159.355 71.8267 159.333 71.8124C159.175 71.7099 158.996 71.6388 158.814 71.5898C158.698 71.5584 158.581 71.5325 158.464 71.5121C158.224 71.4696 157.981 71.4377 157.738 71.4134C157.619 71.4018 157.5 71.3914 157.381 71.4046C157.146 71.4311 156.908 71.4614 156.677 71.5115C156.62 71.5242 156.563 71.538 156.507 71.5528C156.417 71.5771 156.347 71.6096 156.256 71.6482C156.078 71.7231 155.89 71.8278 155.761 71.9761C155.517 72.2544 155.319 72.6143 155.337 72.9995C155.353 73.3577 155.467 73.7898 155.761 74.0229C155.971 74.1898 156.157 74.3557 156.432 74.418C156.477 74.4279 156.524 74.4329 156.569 74.4395C156.688 74.4582 156.806 74.4786 156.922 74.5084C157.056 74.5426 157.193 74.5591 157.328 74.5877C157.464 74.6164 157.598 74.6522 157.731 74.6952C157.794 74.7156 157.857 74.7376 157.919 74.7613C157.987 74.7878 158.061 74.7977 158.133 74.8104C158.126 74.8093 158.119 74.8087 158.112 74.8076C158.122 74.8093 158.132 74.8109 158.142 74.8126C158.204 74.8231 158.264 74.8363 158.326 74.8423C158.412 74.8567 158.499 74.8688 158.581 74.871C158.666 74.8782 158.749 74.8694 158.832 74.8446C158.976 74.844 159.111 74.8076 159.237 74.7349C159.373 74.6969 159.491 74.628 159.591 74.5288C159.653 74.4918 159.709 74.4483 159.759 74.3976C159.768 74.3888 159.777 74.3805 159.785 74.3717C160.06 74.0846 160.219 73.6961 160.224 73.2987C160.225 73.2144 160.209 73.1565 160.196 73.0546Z" fill="white" />
        <path d="M164.978 95.2692C164.924 95.041 164.819 94.8425 164.664 94.6733C164.344 94.2588 163.833 93.9517 163.288 93.9826C163.234 93.9859 163.179 93.987 163.124 93.9892C162.73 94.0047 162.405 94.1017 162.064 94.2847C161.936 94.3536 161.821 94.4247 161.702 94.5085C161.592 94.5851 161.48 94.6573 161.365 94.7251C161.225 94.8084 161.095 94.9065 160.967 95.0073C160.511 95.3375 159.951 95.5729 159.685 96.0955C159.557 96.3463 159.501 96.5966 159.539 96.8783C159.577 97.1621 159.692 97.3617 159.877 97.5833C160.265 98.0485 160.831 98.1064 161.387 98.161C161.633 98.1852 161.881 98.1935 162.128 98.1797C162.54 98.156 162.911 98.0469 163.288 97.8942C163.585 97.774 163.797 97.6886 164.035 97.5083C164.328 97.2867 164.594 97.0965 164.749 96.7487C164.83 96.5685 164.906 96.3854 164.978 96.2019C165.061 95.8904 165.061 95.5801 164.978 95.2692Z" fill="white" />
        <defs>
            <linearGradient id="paint0_linear_2286_189406" x1="73.7255" y1="104.744" x2="99.0599" y2="111.857" gradientUnits="userSpaceOnUse">
                <stop stop-color="#5F5D9C" />
                <stop offset="1" stop-color="#E148D1" />
            </linearGradient>
            <linearGradient id="paint1_linear_2286_189406" x1="55.0365" y1="40.9718" x2="70.2539" y2="45.2445" gradientUnits="userSpaceOnUse">
                <stop stop-color="#5F5D9C" />
                <stop offset="1" stop-color="#E148D1" />
            </linearGradient>
            <linearGradient id="paint2_linear_2286_189406" x1="96.8043" y1="25.2447" x2="110.207" y2="29.0078" gradientUnits="userSpaceOnUse">
                <stop stop-color="#5F5D9C" />
                <stop offset="1" stop-color="#E148D1" />
            </linearGradient>
            <linearGradient id="paint3_linear_2286_189406" x1="173.751" y1="89.8125" x2="202.984" y2="98.5656" gradientUnits="userSpaceOnUse">
                <stop stop-color="#5F5D9C" />
                <stop offset="1" stop-color="#E148D1" />
            </linearGradient>
            <linearGradient id="paint4_linear_2286_189406" x1="145.006" y1="56.1885" x2="187.502" y2="68.1203" gradientUnits="userSpaceOnUse">
                <stop stop-color="#5F5D9C" />
                <stop offset="1" stop-color="#E148D1" />
            </linearGradient>
        </defs>
    </svg>

)
export const RedAlert = () => (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 0.5C8.85652 0.5 10.637 1.2375 11.9497 2.55025C13.2625 3.86301 14 5.64348 14 7.5C14 9.35652 13.2625 11.137 11.9497 12.4497C10.637 13.7625 8.85652 14.5 7 14.5C5.14348 14.5 3.36301 13.7625 2.05025 12.4497C0.737498 11.137 0 9.35652 0 7.5C0 5.64348 0.737498 3.86301 2.05025 2.55025C3.36301 1.2375 5.14348 0.5 7 0.5ZM7 13.5C8.5913 13.5 10.1174 12.8679 11.2426 11.7426C12.3679 10.6174 13 9.0913 13 7.5C13 5.9087 12.3679 4.38258 11.2426 3.25736C10.1174 2.13214 8.5913 1.5 7 1.5C5.4087 1.5 3.88258 2.13214 2.75736 3.25736C1.63214 4.38258 1 5.9087 1 7.5C1 9.0913 1.63214 10.6174 2.75736 11.7426C3.88258 12.8679 5.4087 13.5 7 13.5ZM7.75 10.75C7.75 10.9489 7.67098 11.1397 7.53033 11.2803C7.38968 11.421 7.19891 11.5 7 11.5C6.80109 11.5 6.61032 11.421 6.46967 11.2803C6.32902 11.1397 6.25 10.9489 6.25 10.75C6.25 10.5511 6.32902 10.3603 6.46967 10.2197C6.61032 10.079 6.80109 10 7 10C7.19891 10 7.38968 10.079 7.53033 10.2197C7.67098 10.3603 7.75 10.5511 7.75 10.75ZM7 3.5C7.13261 3.5 7.25979 3.55268 7.35355 3.64645C7.44732 3.74021 7.5 3.86739 7.5 4V8.5C7.5 8.63261 7.44732 8.75979 7.35355 8.85355C7.25979 8.94732 7.13261 9 7 9C6.86739 9 6.74021 8.94732 6.64645 8.85355C6.55268 8.75979 6.5 8.63261 6.5 8.5V4C6.5 3.86739 6.55268 3.74021 6.64645 3.64645C6.74021 3.55268 6.86739 3.5 7 3.5Z" fill="#EF1A25" />
    </svg>

)
export const GreenCheck = () => (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2286_188254)">
            <path d="M7.99979 1.39062C6.59334 1.39063 5.21848 1.80768 4.04906 2.58906C2.87965 3.37044 1.9682 4.48105 1.42998 5.78043C0.891753 7.07982 0.750929 8.50962 1.02531 9.88904C1.2997 11.2685 1.97696 12.5355 2.97147 13.5301C3.96598 14.5246 5.23306 15.2018 6.61248 15.4762C7.9919 15.7506 9.4217 15.6098 10.7211 15.0715C12.0205 14.5333 13.1311 13.6219 13.9125 12.4525C14.6938 11.283 15.1109 9.90818 15.1109 8.50174C15.1109 6.61575 14.3617 4.80701 13.0281 3.47342C11.6945 2.13983 9.88577 1.39063 7.99979 1.39062ZM7.99979 14.724C6.76915 14.724 5.56614 14.359 4.5429 13.6753C3.51967 12.9916 2.72215 12.0198 2.2512 10.8829C1.78026 9.74591 1.65704 8.49483 1.89712 7.28784C2.13721 6.08085 2.72982 4.97215 3.60001 4.10196C4.4702 3.23177 5.5789 2.63916 6.78589 2.39907C7.99288 2.15899 9.24397 2.28221 10.3809 2.75315C11.5179 3.2241 12.4897 4.02162 13.1734 5.04485C13.8571 6.06809 14.222 7.2711 14.222 8.50174C14.222 10.152 13.5665 11.7346 12.3996 12.9015C11.2327 14.0684 9.65002 14.724 7.99979 14.724Z" fill="#46BB2F" />
            <path d="M12.4446 5.87924C12.3614 5.79646 12.2487 5.75 12.1313 5.75C12.0139 5.75 11.9012 5.79646 11.818 5.87924L6.88463 10.7904L4.21797 8.12369C4.13663 8.03587 4.02375 7.98396 3.90414 7.97938C3.78453 7.97479 3.668 8.01791 3.58019 8.09924C3.49237 8.18057 3.44046 8.29346 3.43588 8.41307C3.43129 8.53267 3.47441 8.6492 3.55574 8.73702L6.88463 12.057L12.4446 6.51035C12.4863 6.46904 12.5194 6.41988 12.5419 6.36572C12.5645 6.31156 12.5761 6.25347 12.5761 6.1948C12.5761 6.13613 12.5645 6.07803 12.5419 6.02387C12.5194 5.96971 12.4863 5.92056 12.4446 5.87924Z" fill="#46BB2F" />
        </g>
        <defs>
            <clipPath id="clip0_2286_188254">
                <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
            </clipPath>
        </defs>
    </svg>

)