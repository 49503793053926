import {createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';

interface Product {
    proteksi:boolean
    price: any;
    diskon: any;
    _id: string;
    cartId: string;
    total_price: number;
    total_price_cart: number;
    total_stok: number;
    quantity: number;
    note: string;
    minimalOrder: number;
    name_product: string;
    image_product: string[]
    panjang: number;
    tinggi: number;
    lebar: number;
    berat: number;
    totalAsuransi?: number;
    InsuranceTotal: number
    isInsurance: boolean
}

interface DistDetail {
    DistId: string
    DistName: string;
    DistType: string;
    IdKendaraaan: string;
    ongkir: number;
    idJenisKendaraan: string
    idJenisLayanan: string
}

interface Vendor {
    nama_toko: string;
    userId: string
    _id: string
    idDist: string
    products: Product[]
    distributor: DistDetail
    tglPengiriman: string | null
}

interface CartState {
  vendors: Vendor[];
}

const initialState: CartState = {
  vendors: [],
};


const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers:{
    updateProductInsurance(state, action: PayloadAction<{ productId: string; isInsurance: boolean }>) {
        state.vendors.forEach(vendor => {
          const product = vendor.products.find(p => p._id === action.payload.productId);
          if (product) {
            product.isInsurance = action.payload.isInsurance;
          }
        });
      },
    calculateWeightPerKg(state) {
        state.vendors.forEach(vendor => {
            vendor.products.forEach(product => {
                product.berat = product.berat * product.quantity / 1000; // Hitung berat per kg
            });
        });
    },
    calculateInsuranceTotal(state) {
        state.vendors.forEach(vendor => {
          vendor.products.forEach(product => {
            if (product.isInsurance) {
              product.InsuranceTotal = product.quantity * 500;
            } else {
              product.InsuranceTotal = 0;
            }
          });
        });
      },
    setCartData(state, action: PayloadAction<Vendor[]>) {
        state.vendors = action.payload.map(vendor => ({
            ...vendor,
            distributor: vendor.distributor || { DistName: '', DistType: '', ongkir: 0 },
        }));
    },
    updateProductQuantity(state, action: PayloadAction<{ nama_toko: string; productId: string; quantity: number }>) {
        const { nama_toko, productId, quantity } = action.payload;
        const vendor = state.vendors.find(v => v.nama_toko === nama_toko);
        if (vendor) {
            const product = vendor.products.find(p => p._id === productId);
            if (product) {
                product.quantity = quantity;
            }
        }
    },
    setTanggalPengiriman(state, action: PayloadAction<{ nama_toko: string; tglPengiriman: string | null }>) {
        const { nama_toko, tglPengiriman } = action.payload;
        const vendor = state.vendors.find(v => v.nama_toko === nama_toko);
        if (vendor) {
            vendor.tglPengiriman = tglPengiriman;
        }
    },
    updateProductNote(state, action: PayloadAction<{ nama_toko: string; productId: string; note: string }>) {
        const { nama_toko, productId, note } = action.payload;
        const vendor = state.vendors.find(v => v.nama_toko === nama_toko);
        if (vendor) {
            const product = vendor.products.find(p => p._id === productId);
            if (product) {
                product.note = note;
            }
        }
    },
    addProductToCart(state, action: PayloadAction<{ nama_toko: string; userIdToko: string; _id: string; product: Product; distributor: DistDetail, tglPengiriman: string | null }>) {
        const { nama_toko, userIdToko, _id, product, distributor, tglPengiriman } = action.payload;
        const vendor = state.vendors.find(v => v.nama_toko === nama_toko);
        if (vendor) {
          vendor.products.push(product);
        } else {
          state.vendors.push({
            nama_toko,
            userId: userIdToko, // Tambahkan userIdToko di sini
            _id: _id,
            idDist: "",
            products: [product],
            distributor, // Menambahkan distributor pada vendor baru
            tglPengiriman,
          });
        }
      },
    setDistDetail(state, action: PayloadAction<{ nama_toko: string; distDetail: DistDetail }>) {
        const { nama_toko, distDetail } = action.payload;
        const vendor = state.vendors.find(v => v.nama_toko === nama_toko);
        if (vendor) {
            console.log('Updating distDetail for vendor:', nama_toko);
            console.log('Old DistDetail:', vendor.distributor);
            console.log('New DistDetail:', distDetail);
            vendor.distributor = distDetail;
        } else {
            console.log('Vendor not found:', nama_toko);
        }
    },
    setIdDist(state, action: PayloadAction<{ nama_toko: string; idDist: string }>) {
        const { nama_toko, idDist } = action.payload;
        const vendor = state.vendors.find(v => v.nama_toko === nama_toko);
        if (vendor) {
            console.log('Updating idDist for vendor:', nama_toko);
            vendor.idDist = idDist;
        } else {
            console.log('Vendor not found:', nama_toko);
        }
    }
}
});
export const selectTotalInsurance = (state: RootState) => {
    return state.cart.vendors.reduce((totalInsurance, vendor) => {
        const vendorInsuranceTotal = vendor.products.reduce((vendorTotalInsurance, product) => {
            if (product.isInsurance) {
                const productInsuranceTotal = 500 * product.quantity;
                console.log(`Adding insurance for product: ${productInsuranceTotal}`);
                return vendorTotalInsurance + productInsuranceTotal;
            }
            return vendorTotalInsurance;
        }, 0);
        return totalInsurance + vendorInsuranceTotal;
    }, 0);
};
export const selectLastProductQuantity = (state: RootState): number | null => {
    return state.cart.vendors.reduce((lastProductQty, vendor, vendorIndex, vendorsArray) => {
        // Jika ini adalah vendor terakhir
        if (vendorIndex === vendorsArray.length - 1) {
            return vendor.products.reduce((lastQty, product, productIndex, productsArray) => {
                // Jika ini adalah produk terakhir
                if (productIndex === productsArray.length - 1) {
                    return product.quantity;
                }
                return lastQty;
            }, lastProductQty);
        }
        return lastProductQty;
    }, null as number | null);
};
// export const selectTotalInsurance = (state: RootState) => {
//     return state.cart.vendors.reduce((totalInsurance, vendor) => {
//         return vendor.products.reduce((vendorTotalInsurance, product) => {
//             if (product.isInsurance) {
//                 console.log("yaaiqnnnnn");
//                 console.log(`${vendorTotalInsurance} + ${product.InsuranceTotal}`)
//                 return vendorTotalInsurance + product.InsuranceTotal;
//             }
//             return vendorTotalInsurance;
//         }, 0) + totalInsurance;
//     }, 0);
// };
export const selectTotalQuantity = (state: RootState) => {
    return state.cart.vendors.reduce((total, vendor) => {
        return total + vendor.products.reduce((vendorTotal, product) => vendorTotal + product.quantity, 0);
    }, 0);
};
export const selectYaaqinValue = (state: RootState) => {
    return state.cart.vendors.reduce((totalYaaqin, vendor) => {
        return vendor.products.reduce((vendorTotalYaaqin, product) => {
            const productYaaqin = product.total_price * product.quantity;
            return vendorTotalYaaqin + productYaaqin;
        }, 0) + totalYaaqin;
    }, 0);
};
export const selectBeratTotalPerProduct = (state: RootState) => {
    const beratTotalPerProduct: { [productId: string]: number } = {};

    state.cart.vendors.forEach(vendor => {
        vendor.products.forEach(product => {
            const { _id, berat, quantity } = product;
            const totalBerat = berat * quantity;

            if (beratTotalPerProduct[_id]) {
                beratTotalPerProduct[_id] += totalBerat;
            } else {
                beratTotalPerProduct[_id] = totalBerat;
            }
        });
    });

    return beratTotalPerProduct;
};
export const selectBeratVolumePerProduct = (state: RootState) => {
    const totalVolumePerProduct: { [productId: string]: number } = {};
    state.cart.vendors.forEach(vendor => {
        vendor.products.forEach(product => {
            const { _id, panjang, lebar, tinggi, quantity } = product;
            const totalVolume = panjang * lebar * tinggi * quantity;

            if (totalVolumePerProduct[_id]) {
                totalVolumePerProduct[_id] += totalVolume;
            } else {
                totalVolumePerProduct[_id] = totalVolume;
            }
        });
    });
    return totalVolumePerProduct
    
};
export const selectTotalOngkir = (state: RootState) => {
    return state.cart.vendors.reduce((totalOngkir, vendor) => {
        return totalOngkir + vendor.distributor.ongkir;
    }, 0);
};

export const { updateProductInsurance, setCartData, addProductToCart, setDistDetail, setIdDist, updateProductQuantity, updateProductNote, setTanggalPengiriman } = cartSlice.actions;
export default cartSlice.reducer;
