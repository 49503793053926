import React, { useEffect } from 'react';
import { useTypedSelector } from '../../../redux/store';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

export default function DashboardProfileDistributor() {
    const userroleinfo = useTypedSelector((state) => state.UserInfo);
    const cookies = new Cookies();
    const navigate = useNavigate();
    const token = cookies.get('token');

    useEffect(() => {
        if (!token) {
            navigate('/auth/login');
        }
    }, [token, navigate]);
    useEffect(() => {
        if (userroleinfo.role !== 'distributor') {
            navigate('/auth/login');
        }
    }, [navigate, userroleinfo]);
    return (
        <>
            <div className="mx-auto">
                <div className="flex">
                    <div className="z-40 fixed w-full">test</div>
                    <div className="fixed z-30 bg-white h-full w-[280px] border border-[#EAEBED]">test</div>
                    <div className="z-20 mt-[38px] fixed w-full">test</div>
                    <div className="mt-[100px] pl-[282px] w-full">
                        <div className="max-w-[1440px] bg-white mx-auto p-[32px]">test</div>
                    </div>
                </div>
            </div>
        </>
    );
}
