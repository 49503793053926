/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { chervonLeft, chervonRight } from '../../assets/icons';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface ProductId {
    _id: string;
    categoryId: string;
}

interface PromoData {
    _id: string;
    productId: ProductId;
    banner: string;
    __v: number;
}

interface PromoResponse {
    message: string;
    data: PromoData[];
}

const Slide: React.FC = () => {
    const [data, setData] = useState<PromoData[]>([]);
    console.log(data);
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef<Slider | null>(null);

    const banner = [
        "https://imagizer.imageshack.com/img922/8250/rZZ2tg.png",
        "https://imagizer.imageshack.com/img924/7876/ZvBRBW.png",
        "https://imagizer.imageshack.com/img923/4375/WM3v26.png"
    ];

    const fetchData = async (url: string, setState: React.Dispatch<React.SetStateAction<any>>) => {
        const token = cookies.get('token');
        if (!token) {
            navigate('/auth/login');
            return;
        }

        try {
            const response = await axios.get<PromoResponse>(url, {
                headers: {
                    Authorization: `${token}`,
                    'ngrok-skip-browser-warning': '69420', // for bypass ngrok webview
                    'User-Agent': 'Web',
                },
            });
            setState(response.data.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_API_URL}/promo/listPromo`, setData);
    }, []);

    useEffect(() => {
        if (!loading && sliderRef.current) {
            sliderRef.current.slickPlay(); // Start autoplay once data is loaded
        }
    }, [loading]);

    const handleAfterChange = (index: number) => {
        setCurrentIndex(index);
    };

    const settings = {
        dots: false,
        infinite: banner.length > 1,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: !loading && banner.length > 1,
        autoplaySpeed: 3000,
        centerMode: true,
        centerPadding: '0', // Disable default padding
        afterChange: handleAfterChange,
        arrows: false,
        beforeChange: (oldIndex: number, newIndex: number) => setCurrentIndex(newIndex),
    };

    const nextSlide = () => {
        sliderRef.current?.slickNext();
    };

    const prevSlide = () => {
        sliderRef.current?.slickPrev();
    };

    const goToSlide = (index: number) => {
        sliderRef.current?.slickGoTo(index);
        setCurrentIndex(index); // Update state to reflect the current index
    };

    return (
        <main className="w-full relative group mt-[30px]mx-auto">
        {loading ? (
            <div>Loading...</div>
        ) : (
            <>
                {Array.isArray(banner) && banner.length > 1 && (
                    <>
                        <div className="lg:mt-[200px] ml-[55px] absolute transform -translate-y-1/2 z-10 invisible group-hover:visible">
                            <button
                                onClick={prevSlide}
                                className="bg-[#EEF9FF] active:bg-slate-500 rounded-full lg:h-[70px] lg:w-[70px] h-[40px] w-[40px] p-auto focus:outline-none"
                                aria-label="Previous Slide"
                            >
                                <p className="lg:pl-[30px] pl-[10px] ml-[-3px]">{chervonLeft()}</p>
                            </button>
                        </div>
                        <div className="lg:mt-[200px] right-0 mr-[55px] absolute transform -translate-y-1/2 z-10 invisible group-hover:visible">
                            <button
                                onClick={nextSlide}
                                className="bg-[#EEF9FF] flex items-center ml-[20px] active:bg-slate-500 rounded-full lg:h-[70px] lg:w-[70px] h-[40px] w-[40px] focus:outline-none"
                                aria-label="Next Slide"
                            >
                                <p className="lg:pl-[20px] pl-[25px] ml-[8px]">{chervonRight()}</p>
                            </button>
                        </div>
                    </>
                )}
                <Slider
                    ref={sliderRef}
                    {...settings}
                    className="mt-[32px] h-[380px] group mx-[27px]" // Centered on large screens
                >
                    {Array.isArray(banner) && banner.length > 0 ? (
                        banner.map((item, index) => (
                           <div>
                             <div
                                key={index}
                                className={`duration-100 mx-auto w-[1280px] rounded-lg ${index === currentIndex ? 'h-[371px]' : 'mt-[20px] h-[320px]'}`}
                            >
                                <img
                                    src={item}
                                    alt=""
                                    className="w-full h-full object-fill rounded-lg"
                                />
                            </div>
                           </div>
                        ))
                    ) : (
                        <div>No banners available</div>
                    )}
                </Slider>
                {Array.isArray(banner) && banner.length > 1 && (
                    <div className="left-0 right-0 flex justify-center z-20 mt-4">
                        {banner.map((_, index) => (
                            <button
                                key={index}
                                className={`h-3 w-3 rounded-full mx-1 ${index === currentIndex ? 'bg-[#1069E5] w-[50px]' : 'bg-slate-400'}`}
                                onClick={() => goToSlide(index)}
                                aria-label={`Go to slide ${index + 1}`}
                            ></button>
                        ))}
                    </div>
                )}
            </>
        )}
    </main>
    
    );
};

export default Slide;
