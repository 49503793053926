import React from 'react'
import { RequireListDeleteAccount } from './data'

export default function RequireList() {
  return (
    <div className='flex flex-col gap-[30px] mt-[48px] mb-[80px]'>
      {RequireListDeleteAccount.map((item, index) => (
        <section className='flex items-start gap-[24px]'>
          <div className="relative h-[22px] w-[22px] bg-[#1454B8] rounded-full">
            <span className="absolute inset-0 flex items-center justify-center text-[#fff] text-[14px]">
              {index + 1}
            </span>
          </div>
          <section>
            <h6 className='text-[#25282B] text-[16px] font-medium'>{item.title}</h6>
            <p className='text-[14px] text-[#4A4D4F] font-normal'>{item.desk}</p>
          </section>
        </section>
      ))}
    </div>
  )
}
