import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    CariKendaraan,
    CariPengemudi,
    DistributorDetail,
    KeteranganPenjemputan,
    PengirimanDetail,
    ProsesPengirimanList,
    TrackingPenjemputan,
} from '../../../../../distributor/types';
import axiosInstance from '../../../../../api/axiosInstance';

interface ProsesPengirimanState {
    prosesPengirimanList: ProsesPengirimanList[];
    keteranganPenjemputan: KeteranganPenjemputan;
    trackingDetail: TrackingPenjemputan[];
    updateTracking: TrackingPenjemputan[];
    dataDistributor: DistributorDetail;
    loading: boolean;
    error: string | null;
    pengirimanDetail: PengirimanDetail;
    cariKendaraan: CariKendaraan[];
    cariPengemudi: CariPengemudi[];
}

const initialState: ProsesPengirimanState = {
    updateTracking: [],
    trackingDetail: [],
    keteranganPenjemputan: {
        waktu_pengiriman: '',
        kode_pengiriman: '',
        status_distributor: '',
        pengirimanId: '',
        distributorId: {
            _id: '',
            alamat_id: {
                pinAlamat: {
                    lat: {
                        $numberDecimal: '',
                    },
                    long: {
                        $numberDecimal: '',
                    },
                },
            },
        },
        tokoId: {
            _id: '',
            namaToko: '',
            address: {
                address_description: '',
                pinAlamat: {
                    lat: {
                        $numberDecimal: '',
                    },
                    long: {
                        $numberDecimal: '',
                    },
                },
            },
        },
        buyerId: {
            _id: '',
            address: {
                address_description: '',
                pinAlamat: {
                    lat: {
                        $numberDecimal: '',
                    },
                    long: {
                        $numberDecimal: '',
                    },
                },
            },
            namaSekolah: '',
        },
        _id: '',
        jenisPengiriman: {
            nama: '',
        },
        tarif_pengiriman: 0,
        id_kendaraan: {
            jenisKendaraan: {
                jenis: '',
            },
            merekKendaraan: {
                merk: '',
            },
            nomorPolisi: '',
        },
        jarakPengiriman: '',
        id_pengemudi: {
            no_telepon: 0,
            nama: '',
            profile: '',
        },
    },
    cariPengemudi: [],
    dataDistributor: {
        alamat_id: {
            pinAlamat: {
                lat: {
                    $numberDecimal: '',
                },
                long: {
                    $numberDecimal: '',
                },
            },
        },
        imageProfile: '',
        _id: '',
        jenisKelamin: '',
        nama_distributor: '',
        userId: {
            phone: {
                content: 0,
            },
            role: '',
        },
        individu: [],
        jenisPerusahaan: '',
        tanggal_lahir: '',
    },
    prosesPengirimanList: [],
    cariKendaraan: [],
    pengirimanDetail: {
        _id: '',
        distributorId: '',
        buyerId: {
            _id: '',
            namaSekolah: '',
            NPSN: '',
            address: {
                address_description: '',
                _id: '',
            },
            logoSekolah: '',
            namaBadanUsaha: '',
            nama: '',
        },
        buyerType: '',
        pengirimanId: [],
        tokoId: {
            _id: '',
            namaToko: '',
            address: {
                address_description: '',
            },
        },
        tokoType: '',
        jarakPengiriman: 0,
        kode_pengiriman: '',
        tarif_pengiriman: 0,
        status_distributor: '',
        jenisPengiriman: {
            nama: '',
            icon: '',
        },
        createdAt: '',
        produk_pengiriman: [],
        waktu_pengiriman: '',
    },

    loading: false,
    error: null,
};

export const fetchProsesPengirimanList = createAsyncThunk<ProsesPengirimanList[], string>(
    'PengirimanList/fetchPengirimanList',
    async (queryParams: string) => {
        try {
            const response = await axiosInstance.get(`/proses-pengiriman/list?${queryParams}`);
            console.log('response data pengiriman ', response.data.datas);
            return response.data.datas;
        } catch (error) {
            console.error('Error fetching pengiriman list:', error);
            throw error;
        }
    },
);

export const updatePengiriman = createAsyncThunk<PengirimanDetail, { detailPengirimanId: string; formData: FormData }>(
    'PengirimanList/updatePengiriman',
    async ({ detailPengirimanId, formData }, thunkAPI) => {
        try {
            const response = await axiosInstance.put(
                `/proses-pengiriman/mulai-penjemputan/${detailPengirimanId}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            );
            console.log('Response dari updatePesanan:', response.data);
            return response.data.data;
        } catch (error: any) {
            console.error('Error updating pesanan:', error);
            return thunkAPI.rejectWithValue(error.response?.data?.message || 'Gagal mengupdate data');
        }
    },
);

export const fetchPengirimanDetail = createAsyncThunk<PengirimanDetail, string>(
    'PengirimanList/fetchPengirimanDetail',
    async (prosesPengirimanId) => {
        try {
            const response = await axiosInstance.get(`/proses-pengiriman/detail/${prosesPengirimanId}`);
            console.log('response data pengiriman detail ', response.data.data);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching pengiriman detail:', error);
            throw error;
        }
    },
);

export const fetchTracking = createAsyncThunk<
    TrackingPenjemputan[],
    { idToko: string; idDistributor: string; pengirimanId: string; id_sekolah: string }
>('Tracking/fetchTracking', async ({ idToko, idDistributor, pengirimanId, id_sekolah }) => {
    try {
        const response = await axiosInstance.get(
            `/treking/lacak/${idToko}/${idDistributor}/${pengirimanId}/${id_sekolah}`,
        );
        console.log('response data tracking detail ', response.data.data);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching tracking detail:', error);
        throw error;
    }
});
export const updateTracking = createAsyncThunk<
    TrackingPenjemputan[],
    {
        latitude: number;
        longitude: number;
        idToko: string;
        idDistributor: string;
        pengirimanId: string;
    }
>('Tracking/updateTracking', async ({ latitude, longitude, idToko, idDistributor, pengirimanId }) => {
    try {
        const response = await axiosInstance.put('/treking/lacak/', {
            latitude,
            longitude,
            id_toko: idToko,
            id_distributor: idDistributor,
            pengirimanId,
        });
        console.log('response data tracking detail', response.data.data);
        return response.data.data;
    } catch (error) {
        console.error('Error updating tracking:', error);
        throw error;
    }
});

export const fetchCariKendaraan = createAsyncThunk<CariKendaraan[], string>(
    'CariKendaraan/fetchCariKendaraan',
    async (detailPengirimanId) => {
        try {
            const response = await axiosInstance.get(`/kendaraan-distributtor/cari-kendaraan/${detailPengirimanId}`);
            console.log('response data kendaraan ', response.data.datas);
            return response.data.datas;
        } catch (error) {
            console.error('Error fetching Kendaraan:', error);
            throw error;
        }
    },
);
export const fetchCariPengemudi = createAsyncThunk<CariPengemudi[], string>(
    'CariPengemudi/fetchCariPengemudi',
    async (detailPengirimanId) => {
        try {
            const response = await axiosInstance.get(`/pengemudi/pencarian-pengemudi/${detailPengirimanId}`);
            console.log('response data pengemudi ', response.data.datas);
            return response.data.datas;
        } catch (error) {
            console.error('Error fetching pengemudi:', error);
            throw error;
        }
    },
);
export const fetchKeteranganPenjemputan = createAsyncThunk<KeteranganPenjemputan, string>(
    'KeteranganPenjemputan/fetchKeteranganPenjemputan',
    async (detailPengirimanId) => {
        try {
            const response = await axiosInstance.get(`/treking/lacak/${detailPengirimanId}`);
            console.log('response data keterangan Penjemputan ', response.data.data);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching keterangan penjemputan:', error);
            throw error;
        }
    },
);

export const fetchDistributorDetail = createAsyncThunk<DistributorDetail, void>(
    'DistributorDetail/fetchDistributorDetail',
    async () => {
        try {
            const response = await axiosInstance.get(`/distributor/detail`);
            console.log('response data distributor ', response.data.data);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching pengemudi list:', error);
            throw error;
        }
    },
);

const ProsesPengirimanSlice = createSlice({
    name: 'prosesPengiriman',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProsesPengirimanList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProsesPengirimanList.fulfilled, (state, action: PayloadAction<ProsesPengirimanList[]>) => {
                state.loading = false;
                state.prosesPengirimanList = action.payload;
            })
            .addCase(fetchProsesPengirimanList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch pesanan list';
                console.error('Error fetching pesanan list:', action.error.message);
            })
            .addCase(fetchPengirimanDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPengirimanDetail.fulfilled, (state, action: PayloadAction<PengirimanDetail>) => {
                state.loading = false;
                state.pengirimanDetail = action.payload;
            })
            .addCase(fetchPengirimanDetail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Gagal mengambil detail pengiriman';
                console.error('Error saat mengambil detail pengiriman:', action.error.message);
            })
            .addCase(fetchTracking.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTracking.fulfilled, (state, action: PayloadAction<TrackingPenjemputan[]>) => {
                state.loading = false;
                state.trackingDetail = action.payload;
            })
            .addCase(fetchTracking.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Gagal mengambil tracking ';
                console.error('Error saat mengambil detail tracking:', action.error.message);
            })
            .addCase(fetchKeteranganPenjemputan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchKeteranganPenjemputan.fulfilled, (state, action: PayloadAction<KeteranganPenjemputan>) => {
                state.loading = false;
                state.keteranganPenjemputan = action.payload;
            })
            .addCase(fetchKeteranganPenjemputan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Gagal mengambil keterangan penjemputan';
                console.error('Error saat mengambil keterangan penjemputan:', action.error.message);
            })
            .addCase(fetchCariKendaraan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCariKendaraan.fulfilled, (state, action: PayloadAction<CariKendaraan[]>) => {
                state.loading = false;
                state.cariKendaraan = action.payload;
            })

            .addCase(fetchCariKendaraan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Gagal mengambil Kendaraan';
                console.error('Error saat mengambil Kendaraan:', action.error.message);
            })
            .addCase(fetchCariPengemudi.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCariPengemudi.fulfilled, (state, action: PayloadAction<CariPengemudi[]>) => {
                state.loading = false;
                state.cariPengemudi = action.payload;
            })

            .addCase(fetchCariPengemudi.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Gagal mengambil Pengemudi';
                console.error('Error saat mengambil kendaraan:', action.error.message);
            })
            .addCase(updatePengiriman.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updatePengiriman.fulfilled, (state, action: PayloadAction<PengirimanDetail>) => {
                state.loading = false;
                state.pengirimanDetail = action.payload;
            })
            .addCase(updatePengiriman.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to update status pengiriman';
                console.error('Error updating status pengiriman:', action.error.message);
            })
            .addCase(updateTracking.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateTracking.fulfilled, (state, action: PayloadAction<TrackingPenjemputan[]>) => {
                state.loading = false;
                state.updateTracking = action.payload;
            })
            .addCase(updateTracking.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to update status tracking';
                console.error('Error updating status tracking:', action.error.message);
            })
            .addCase(fetchDistributorDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDistributorDetail.fulfilled, (state, action: PayloadAction<DistributorDetail>) => {
                state.loading = false;
                state.dataDistributor = action.payload;
            })
            .addCase(fetchDistributorDetail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch kendaraan';
                console.error('Error fetching kendaraan:', action.error.message);
            });
    },
});

export const ProsesPengirimanService = { action: ProsesPengirimanSlice.actions, fetchProsesPengirimanList };
const prosesPengirimanReducer = ProsesPengirimanSlice.reducer;
export default prosesPengirimanReducer;
