import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { NotificationState } from '../../interfaces';
import { ResponseNotification } from '../../../../Vendor/types';
import Cookies from 'universal-cookie';
import axios from 'axios';

const initialState: NotificationState = {
  dataNotification: {
    jenis: '',
    title: '',
    message: '',
    rekomendasiMakanan: [],
    waktu: '',
  },
  loadingResponse: false,
  errorResponse: null,
  countBadge: 0,
  isHidden: false,
};

export const fetchGetDataNotification = createAsyncThunk<ResponseNotification>('notificationVendor/fetchGetDataNotification', async () => {
  try {
    const cookies = new Cookies();
    const token = cookies.get('token');
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/vendor/notifikasi/rekomendasi`, {
      headers: {
        Authorization: `${token}`
      }
    });
    console.log('token redux --->', token);
    console.log('response fetchGetDataNotification --->', response);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error('Data tidak sesuai yang diharapkan');
  }
});

const NotificationVendorSlice = createSlice({
  name: 'notificationVendor',
  initialState,
  reducers: {
    setCountBadge: (state, action: PayloadAction<number>) => {
      state.countBadge = action.payload;
  },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetDataNotification.pending, (state) => {
        state.loadingResponse = true;
        state.errorResponse = null;
      })
      .addCase(fetchGetDataNotification.fulfilled, (state, action: PayloadAction<ResponseNotification>) => {
        state.dataNotification = action.payload;
        state.loadingResponse = false;
      })
      .addCase(fetchGetDataNotification.rejected, (state, action) => {
        state.loadingResponse = false;
        state.errorResponse = action.error.message || 'Failed to fetch data';
      });
  },
});

export const NotificationVendorService = { action: NotificationVendorSlice.actions };
const NotificationVendorReducer = NotificationVendorSlice.reducer;
export default NotificationVendorReducer;