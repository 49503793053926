import { useNavigate } from 'react-router-dom'
import Footer from '../../Components/footer'
import { DellAccountReqIcon } from './icon'

export default function DellAccountRequest() {
    const navigate = useNavigate()
    return (
        <div className='pt-[160px]'>
            <section className='mx-auto w-[976px] pt-[24px] pb-[40px] px-[16px] shadow-custom-drop flex flex-col text-center items-center'>
                <h6 className='mb-[40px] text-[#25282B] font-bold text-[24px]'>Hapus Akun</h6>
                <DellAccountReqIcon />
                <p className='w-[356px] text-[16px] font-bold text-[#000] mt-[40] mb-[24px]'>Permintaan penghapusan akun Anda sedang diproses</p>
                <p className='w-[592px] text-[14px] font-normal text-[#4A4D4F]'>Penghapusan akun Anda sedang dalam proses peninjauan. Kami akan segera mengabari Anda terkait penghapusan Akun melalui Email</p>
                <button onClick={()=>navigate('/')} className='w-[320px] bg-[#1454B8] py-[8px] text-[#fff] rounded-full mt-[80px] text-[12px]'>Kembali ke Beranda</button>
            </section>
            <Footer />
        </div>
    )
}
