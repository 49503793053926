import React from 'react';

const Data = [
    {
        id: 1,
        category: 'Sepatu Pria',
    },
    {
        id: 2,
        category: 'Sepatu Wanita',
    },
    {
        id: 3,
        category: 'Sepatu Sapety',
    },
    {
        id: 4,
        category: 'Sepatu Basket',
    },
    {
        id: 5,
        category: 'Sepatu Pria',
    },
    {
        id: 6,
        category: 'Sepatu Pria',
    },
];

const DataToko = [
    {
        id: 1,
        image: 'sepatu',
        toko: 'Nike Old',
        address: 'Jakarta Timur',
    },
    {
        id: 2,
        image: 'sepatu',
        toko: 'Nike Old',
        address: 'Jakarta Timur',
    },
    {
        id: 3,
        image: 'sepatu',
        toko: 'Nike Old',
        address: 'Jakarta Timur',
    },
    {
        id: 4,
        image: 'sepatu',
        toko: 'Nike Old',
        address: 'Jakarta Timur',
    },
];

export default function SharedCategory() {
    const searchValue = ''
    const filteredData = Data.filter((item) => item.category.toLowerCase().includes(searchValue.toLowerCase()));

    return (
        <div className="bg-white shadow-lg pt-[30px] w-full h-[400px] px-[3%]">
            <div className=" border-b h-[80px]">
                <div className="flex justify-between w-auto h-auto ">
                    {filteredData.map((item, index) => (
                        <div key={index} className="bg-[#EEF9FF] rounded-full font-semibold py-[10px] px-[20px]">
                            <p className="text-[18px] font-inter">{item.category}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className="mt-[30px] font-inter  text-left">
                <p className="text-[20px] font-bold">Toko</p>
                <div className="flex flex-wrap gap-[10%] pt-[20px]">
                    {DataToko.map((item, index) => (
                        <div key={index} className="flex">
                            <div className="w-[69px] h-[69px] object-cover ">
                                <img src={item.image} alt="toko" className="w-full h-full rounded-full" />
                            </div>
                            <div className="pl-[20px] leading-20">
                                <p className="text-[20px] font-bold">{item.toko}</p>
                                <p className="text-[16px] pt-[10px] text-[#A7A7A7]">{item.address}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
