import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DistributorDetail, PesananDetail, PesananList } from '../../../../../distributor/types';
import axiosInstance from '../../../../../api/axiosInstance';

interface PesananProdukState {
    pesananList: PesananList[];
    dataDistributor: DistributorDetail;
    pesananDetail: PesananDetail;
    loading: boolean;
    error: string | null;
}

const initialState: PesananProdukState = {
    dataDistributor: {
        alamat_id: {
            pinAlamat: {
                lat: {
                    $numberDecimal: '',
                },
                long: {
                    $numberDecimal: '',
                },
            },
        },
        imageProfile: '',
        _id: '',
        jenisKelamin: '',
        nama_distributor: '',
        userId: {
            phone: {
                content: 0,
            },
            role: '',
        },
        individu: [],
        jenisPerusahaan: '',
        tanggal_lahir: '',
    },
    pesananDetail: {
        id: '',
        distributorId: '',
        total_ongkir: 0,
        waktu_pengiriman: '',
        kode_pengiriman: '',
        orderId: {
            _id: '',
            status: '',
            createdAt: '',
            sekolahId: {
                namaSekolah: '',
                address: {
                    address_description: '',
                },
            },
        },
        id_toko: {
            _id: '',
            namaToko: '',
            address: {
                address_description: '',
            },
        },

        productToDelivers: [],
        jenis_pengiriman: {
            _id: '',
            nama: '',
        },
        status_distributor: '',
    },
    pesananList: [],
    loading: false,
    error: null,
};

export const fetchPesananList = createAsyncThunk<PesananList[], string>(
    'PesananList/fetchPesananList',
    async (distributorId) => {
        try {
            const response = await axiosInstance.get(`/pesanan-distributor/list/${distributorId}`);
            console.log('response data pesanan ', response.data.data);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching pesanan list:', error);
            throw error;
        }
    },
);

export const updatePesanan = createAsyncThunk<PesananDetail, { formData: FormData }>(
    'PesananList/updatePesanan',
    async ({ formData }) => {
        try {
            const response = await axiosInstance.put(`/pesanan-distributor/terima`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Response dari updatePesanan:', response.data);
            return response.data.data;
        } catch (error: any) {
            console.error('Error updating pesanan:', error);
            throw new Error(error.response?.data?.message || 'Gagal mengupdate data');
        }
    },
);

export const fetchPesananDetail = createAsyncThunk<PesananDetail, string>(
    'PesananList/fetchPesananDetail',
    async (pesananListId) => {
        try {
            const response = await axiosInstance.get(`/pesanan-distributor/detail/${pesananListId}`);
            console.log('response data pesanan detail ', response.data.data);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching pesanan detail:', error);
            throw error;
        }
    },
);

export const fetchDistributorDetail = createAsyncThunk<DistributorDetail, void>(
    'DistributorDetail/fetchDistributorDetail',
    async () => {
        try {
            const response = await axiosInstance.get(`/distributor/detail`);
            console.log('response data distributor ', response.data.data);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching pengemudi list:', error);
            throw error;
        }
    },
);

const PesananProdukSlice = createSlice({
    name: 'manajemenPengemudi',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPesananList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPesananList.fulfilled, (state, action: PayloadAction<PesananList[]>) => {
                state.loading = false;
                state.pesananList = action.payload;
            })
            .addCase(fetchPesananList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch pesanan list';
                console.error('Error fetching pesanan list:', action.error.message);
            })
            .addCase(fetchPesananDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPesananDetail.fulfilled, (state, action: PayloadAction<PesananDetail>) => {
                state.loading = false;
                state.pesananDetail = action.payload;
            })
            .addCase(fetchPesananDetail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch pesanan detail';
                console.error('Error fetching pesanan detail:', action.error.message);
            })
            .addCase(fetchDistributorDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDistributorDetail.fulfilled, (state, action: PayloadAction<DistributorDetail>) => {
                state.loading = false;
                state.dataDistributor = action.payload;
            })
            .addCase(fetchDistributorDetail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch kendaraan';
                console.error('Error fetching kendaraan:', action.error.message);
            })
            .addCase(updatePesanan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updatePesanan.fulfilled, (state, action: PayloadAction<PesananDetail>) => {
                state.loading = false;
                state.pesananDetail = action.payload;
            })
            .addCase(updatePesanan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to update status pengiriman';
                console.error('Error updating status pengiriman:', action.error.message);
            });
    },
});

export const PesananProdukService = { action: PesananProdukSlice.actions, fetchPesananList };
const pesananProdukReducer = PesananProdukSlice.reducer;
export default pesananProdukReducer;
