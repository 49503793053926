import { lazy, Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import LoadingComponent from '../GlobalComponent/LoadingComponent';
import HomeTest from '../konsumnen/pages/homeTest';
import DeletedAccountRequire from '../konsumnen/pages/deleteAccount';
import DeleteAccountReason from '../konsumnen/pages/deleteAccountReason';
import DellAccountRequest from '../konsumnen/pages/dellAccountReq';
import DashboardProfileDistributor from './dashboardDistributor/dashboardProfile';
const NotFoundPage = lazy(() => import('../konsumnen/Components/notFound'));
const ErrorPage = lazy(() => import('../konsumnen/Components/pageError'));
const DetailProduct = lazy(() => import('../konsumnen/Components/product/detailProduct'));
const SuccessPayment = lazy(() => import('../konsumnen/Components/product/paymentProduct/successPayment'));
const PaymentProduct = lazy(() => import('../konsumnen/Components/product/paymentProduct'));
const PaymentFirst = lazy(() => import('../konsumnen/Components/product/paymentProduct/paymentFirst'));
const RemainingPayment = lazy(() => import('../konsumnen/Components/product/paymentProduct/remainingPayment'));
const DistributorProduct = lazy(() => import('../konsumnen/Components/product/distributorProduct'));
const PackageDistributor = lazy(() => import('../konsumnen/Components/product/distributorProduct/packageDistributor'));
const WaitingPayment = lazy(() => import('../konsumnen/Components/product/paymentProduct/waitingPayment'));
const OrderProductPage = lazy(() => import('../Vendor/pages/orderProductPage'));
const AddProductPage = lazy(() => import('../Vendor/pages/addProductPage'));
const ListProductPage = lazy(() => import('../Vendor/pages/listProductPage'));
const EditProduct = lazy(() => import('../Vendor/pages/listProductPage/EditProducts'));
const MyTokoPage = lazy(() => import('../Vendor/pages/myTokoPage'));
const PromotionProductPage = lazy(() => import('../Vendor/pages/promotionProductPage'));
const AddPromotion = lazy(() => import('../Vendor/Components/promotionProduct/AddPromotion'));
const EditPromotion = lazy(() => import('../Vendor/Components/promotionProduct/editPromotion'));
const IncomePage = lazy(() => import('../Vendor/pages/incomePage'));
const DeleveryPage = lazy(() => import('../Vendor/pages/deleveryPage'));
const LocationDelevery = lazy(() => import('../Vendor/Components/Delevery/locationDelevery'));
const RivewPage = lazy(() => import('../Vendor/pages/reviewPage'));
const AnalisisPage = lazy(() => import('../Vendor/pages/AnalisisPage'));
const RegisterVendor = lazy(() => import('../Vendor/pages/registerVendor'));
const RegisterVendorOTP = lazy(() => import('../Vendor/pages/registerVendor/sections/RegisterVendorOTP'));
const RoleRegisterVendor = lazy(() => import('../Vendor/pages/registerVendor/sections/roleRegisterVendor'));
const SectionRoleRegisterVendor = lazy(() => import('../Vendor/pages/registerVendor/sections/formAfterSelectRole'));
const HomeDistributor = lazy(() => import('../distributor/pages/HomeDistributor'));
const DashboardNotAcc = lazy(() => import('../Vendor/pages/dahsboardNotAcc'));
const SubmissionComplate = lazy(() => import('../Vendor/Components/SubmissionComplate'));
const FormBiodataVendor = lazy(() => import('../Vendor/Components/addBiodataVendor'));
const FormBiodataKonsumen = lazy(() => import('../konsumnen/Components/addBiodataKonsumen'));
const AddBioDistributor = lazy(() => import('../distributor/components/AddBioDistributor'));
const SectionRoleRegisterDistributor = lazy(
    () => import('../distributor/pages/RegisterDistributor/sections/formAfterSelectRoleDistributor'),
);
const FormBiodataDistributor = lazy(() => import('../distributor/components/AddBioDistributor'));
const AddKendaraanIndividu = lazy(() => import('../distributor/pages/KendaraanDriverPage/addKendaraanIndividu'));
const ManajemenKendaraanPage = lazy(() => import('../distributor/pages/ManajemenKendaraanPage'));
const ManajerPengemudiPage = lazy(() => import('../distributor/pages/ManajerPengemudiPage'));
const AnalisisDistributorPage = lazy(() => import('../distributor/pages/AnalisisDistributorPage'));
const KeuanganPage = lazy(() => import('../distributor/pages/KeuanganPage'));
const UlasanPage = lazy(() => import('../distributor/pages/UlasanPage'));
const PesananProduk = lazy(() => import('../distributor/pages/PesananProduk'));
const NotifikasiVendorPage = lazy(() => import('../Vendor/pages/notifikasiVendor'));
const NotificationFoods = lazy(() => import('../Vendor/pages/notifikasiVendor/sections/recommendFood'));
const NotificationOrderan = lazy(() => import('../Vendor/pages/notifikasiVendor/sections/orderanNotification'));
const UpdateKendaraanIndividu = lazy(() => import('../distributor/pages/KendaraanDriverPage/updateKendaraanIndividu'));
const DetailPengemudi = lazy(() => import('../distributor/pages/ManajerPengemudiPage/detail'));
const UpdatePengemudiPerusahaan = lazy(() => import('../distributor/pages/ManajerPengemudiPage/updatePengemudi'));
const AddKendaraanPerusahaan = lazy(() => import('../distributor/pages/ManajemenKendaraanPage/addKendaraan'));
const UpdateKendaraanPerusahaan = lazy(() => import('../distributor/pages/ManajemenKendaraanPage/updateKendaraan'));
const DetailKendaraanPerusahaan = lazy(() => import('../distributor/pages/ManajemenKendaraanPage/detailKendaraan'));
const DetailPesananProduk = lazy(() => import('../distributor/pages/PesananProduk/detailPesanan'));
const UpdatePesananProduk = lazy(() => import('../distributor/pages/PesananProduk/updatePesanan'));
const FirstPage = lazy(() => import('../distributor/pages/KendaraanDriverPage/firstPage'));
const KendaraanDriverPage = lazy(() => import('../distributor/pages/KendaraanDriverPage'));
const ProsesPengiriman = lazy(() => import('../distributor/pages/ProsesPengirimanPage'));
const RutePengiriman = lazy(() => import('../distributor/pages/ProsesPengirimanPage/RutePerjalanan'));
const PusatBantuanDashboard = lazy(() => import('./dashboardKonsumen/PusatBantuan'));

// router ppob
const PpobDasbord = lazy(() => import('../konsumnen/pages/ppob'));
const Topup = lazy(() => import('../konsumnen/pages/topup'));
const Pulsa = lazy(() => import('../konsumnen/pages/topup/components/pulsa'));
const PaketData = lazy(() => import('../konsumnen/pages/topup/components/paketdata'));
const SuccsesValidate = lazy(() => import('../konsumnen/pages/topup/components/validate'));
const DashboardPpob = lazy(() => import('./ppob'));

// router udinpay
const UdinPay = lazy(() => import('../konsumnen/pages/dashboardUdinpay'));
const TopupUdin = lazy(() => import('../konsumnen/pages/dashboardUdinpay/components/topup'));
const Transfer = lazy(() => import('../konsumnen/pages/dashboardUdinpay/components/transfer'));
const Riwayat = lazy(() => import('../konsumnen/pages/dashboardUdinpay/components/riwayat'));
const Indomaret = lazy(() => import('../konsumnen/pages/dashboardUdinpay/components/topup/indomaret'));
const Alfamart = lazy(() => import('../konsumnen/pages/dashboardUdinpay/components/topup/alfamart'));
const Oneklik = lazy(() => import('../konsumnen/pages/dashboardUdinpay/components/topup/oneklik/index'));
const Mbanking = lazy(() => import('../konsumnen/pages/dashboardUdinpay/components/topup/mbanking'));
const Atm = lazy(() => import('../konsumnen/pages/dashboardUdinpay/components/topup/atm'));
const CreditCard = lazy(() => import('../konsumnen/pages/dashboardUdinpay/components/topup/debit'));
const TransferUdinBank = lazy(() => import('../konsumnen/pages/dashboardUdinpay/components/transfer'));
const TransferUdin = lazy(() => import('../konsumnen/pages/dashboardUdinpay/components/transfer/transferudin'));
const TransferBank = lazy(() => import('../konsumnen/pages/dashboardUdinpay/components/transfer/transferbank'));
const PesananSampai = lazy(
    () =>
        import(
            '../konsumnen/pages/profile/menu/daftarTransaksiList/detailTransaksiList/Component/section/PesananSampai'
        ),
);
const AddPin = lazy(() => import('../konsumnen/pages/AddPin'));
const DaftarKeinginan = lazy(() => import('../konsumnen/pages/profile/menu/daftarKeinginan'));
const PengaturanBahasa = lazy(() => import('../konsumnen/pages/profile/menu/pengaturanBahasa'));
const Ulasan = lazy(() => import('../konsumnen/pages/profile/menu/ulasan'));
const PesananDiKomplain = lazy(() => import('../konsumnen/pages/profile/menu/pesananDiKomplain'));

//webPack Setting---------------------------------------
const DashboardKonsumen = lazy(() => import('./dashboardKonsumen'));
const DashboardDistributor = lazy(() => import('./dashboardDistributor'));
const DashboardVendor = lazy(() => import('./DashboardVendor'));
const LoginRegister = lazy(() => import('./Login'));
const PaymentUpdate = lazy(() => import('../konsumnen/pages/paymentUpdate'));
const SpesifikCategory = lazy(() => import('../konsumnen/pages/Category/SpesifikCategory'));
const NotPayment = lazy(() => import('../konsumnen/pages/profile/menu/daftarTransaksiList/notPayment'));
const Berlangsung = lazy(() => import('../konsumnen/pages/profile/menu/daftarTransaksiList/TransaksiLive/Berlangsung'));
const CancelTransaksiList = lazy(
    () => import('../konsumnen/pages/profile/menu/daftarTransaksiList/cancelTransaksiList'),
);
const SuccesTransaksiList = lazy(
    () => import('../konsumnen/pages/profile/menu/daftarTransaksiList/succesTransaksiList'),
);
const DetailTrasaksiList = lazy(
    () => import('../konsumnen/pages/profile/menu/daftarTransaksiList/detailTransaksiList'),
);
const Diproses = lazy(
    () => import('../konsumnen/pages/profile/menu/daftarTransaksiList/TransaksiLive/proccessTransaksi'),
);
const Dikirim = lazy(() => import('../konsumnen/pages/profile/menu/daftarTransaksiList/TransaksiLive/Dikirim'));
const PesananSelesai = lazy(
    () => import('../konsumnen/pages/profile/menu/daftarTransaksiList/TransaksiLive/PesananSelesai'),
);
const TrackingTransaction = lazy(() => import('../konsumnen/pages/profile/menu/daftarTransaksiList/LacakTransaksi'));
const DetailToko = lazy(() => import('../konsumnen/pages/DetailToko'));
const CartProducts = lazy(() => import('../konsumnen/pages/CartsProducts'));
const Home = lazy(() => import('../konsumnen/pages/home'));
const Profile = lazy(() => import('../konsumnen/pages/profile'));
const Category = lazy(() => import('../konsumnen/pages/Category'));
const Discon = lazy(() => import('../konsumnen/pages/Discon'));
const LoginPage = lazy(() => import('../konsumnen/pages/login'));
const InputPIN = lazy(() => import('../konsumnen/pages/InputPin'));
const RegisterForm = lazy(() => import('../konsumnen/pages/register'));
const SectionLoginOTP = lazy(() => import('../konsumnen/pages/login/sections/sectionLoginOTP'));
const InputPageOTP = lazy(() => import('../konsumnen/pages/register/sections/inputPageOTP'));
const RoleRegisterPage = lazy(() => import('../konsumnen/pages/register/sections/roleRegisterPage'));
const SectionRoleRegister = lazy(() => import('../konsumnen/pages/register/sections/formAfterSelectRole'));
const SectionFormsRegister = lazy(() => import('../konsumnen/pages/register/sections/sectionFormsRegister'));
const RegisterDistributorOTP = lazy(
    () => import('../distributor/pages/RegisterDistributor/sections/RegisterVendorOTP'),
);
const AddPengemudiPerusahaan = lazy(() => import('../distributor/pages/ManajerPengemudiPage/addPengemudi'));
const Setting = lazy(() => import('../konsumnen/pages/profile/menu/Setting'));
const DataSekolah = lazy(() => import('../konsumnen/pages/profile/menu/DataSekolah'));
const DaftarTransaksiList = lazy(() => import('../konsumnen/pages/profile/menu/daftarTransaksiList'));
const ProductFavorit = lazy(() => import('../konsumnen/pages/profile/menu/MyProfile/ProductFavorit'));
const TokoFavorit = lazy(() => import('../konsumnen/pages/profile/menu/MyProfile/TokoFavorit'));
const AddSchool = lazy(() => import('../konsumnen/pages/profile/menu/DataSekolah/section/addSchool'));
const StudentList = lazy(() => import('../konsumnen/pages/profile/menu/DataSekolah/section/addSchool/studentList'));
const ChatSection = lazy(() => import('../konsumnen/pages/profile/menu/Chat'));
const DetailSekolah = lazy(() => import('../konsumnen/pages/profile/menu/DataSekolah/section/detailSekolah'));
const PutDataSekolah = lazy(() => import('../konsumnen/pages/profile/menu/DataSekolah/section/putSchoolData'));
const CreateRate = lazy(() => import('../konsumnen/pages/profile/menu/daftarTransaksiList/Ulasan'));
const NotificationUser = lazy(() => import('../konsumnen/pages/Notification'));
const ProductDetailUpdate = lazy(() => import('../konsumnen/Components/product/detailProduct/detail'));
const CartUpdate = lazy(() => import('../konsumnen/pages/cartUpdate'));
const KetentuanDanPrivasi = lazy(() => import('../konsumnen/pages/profile/menu/ketentuanDanPrivasi'));
const PusatBantuan = lazy(() => import('../konsumnen/pages/pusatBantuan'));
const SectionOtpPutPassword = lazy(() => import('../konsumnen/pages/otpForgotPassword'));
const SectionForgotPasswword = lazy(() => import('../konsumnen/pages/putNewPassword'));
const Pemesanan = lazy(() => import('../konsumnen/pages/pusatBantuan/menu/pemesanan'));
const Pengiriman = lazy(() => import('../konsumnen/pages/pusatBantuan/menu/pengiriman'));
const Promo = lazy(() => import('../konsumnen/pages/pusatBantuan/menu/promo'));
const Transaksi = lazy(() => import('../konsumnen/pages/pusatBantuan/menu/transaksi'));
const AkunDanAplikasi = lazy(() => import('../konsumnen/pages/pusatBantuan/menu/akunDanAplikasi'));
const SaldoDanTagihan = lazy(() => import('../konsumnen/pages/pusatBantuan/menu/saldoDanTagihan'));
const KeamananAkun = lazy(() => import('../konsumnen/pages/pusatBantuan/menu/keamananAkun'));
const TentangMBG = lazy(() => import('../konsumnen/pages/pusatBantuan/menu/TentangMbg/inde'));
const MBGPoint = lazy(() => import('../konsumnen/pages/point'));
const GetDistributor = lazy(() => import('../konsumnen/pages/pilihDistributor'));
const PilihLayanan = lazy(() => import('../konsumnen/pages/pilihDistributor/section'));
//webPack Setting---------------------------------------

export const routes = createBrowserRouter([
    // Route Konsumen
    {
        path: '/',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <DashboardKonsumen />
            </Suspense>
        ),
        errorElement: <NotFoundPage />,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <Home />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },

            {
                path: '/profile',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <Profile />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
                children: [
                    {
                        path: 'notification',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <NotificationUser />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'ulas/:params',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <CreateRate />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'data-sekolah',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <DataSekolah />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'data-sekolah/tambah',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <AddSchool />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'data-sekolah/:id/ubah',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <PutDataSekolah />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'data-sekolah/:id',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <DetailSekolah />
                            </Suspense>
                        ),
                    },
                    {
                        path: 'data-sekolah/detail/list-siswa',
                        element: (
                            <Suspense fallback={<LoadingComponent />}>
                                <StudentList />
                            </Suspense>
                        ),
                    },
                    { path: 'chat', element: <ChatSection /> },
                    { path: 'ulasan', element: <Ulasan /> },
                    { path: 'pesanan-dikomplain', element: <PesananDiKomplain /> },
                    { path: 'daftar-keinginan', element: <DaftarKeinginan /> },
                    { path: 'pengaturan-bahasa', element: <PengaturanBahasa /> },
                    { path: 'product-favorite', element: <ProductFavorit /> },
                    { path: 'toko-favorite', element: <TokoFavorit /> },
                    { path: 'setting', element: <Setting /> },
                    { path: 'ketentuan&privasi', element: <KetentuanDanPrivasi /> },
                    { path: 'detail-transaksi/:status/:orderId', element: <DetailTrasaksiList /> },
                    { path: 'pesanan-sampai', element: <PesananSampai /> },
                    {
                        path: 'lacak/:id_toko/:id_distributor/:pengirimanId/:id_sekolah',
                        element: <TrackingTransaction />,
                    },
                    {
                        path: 'daftar-transaksi',
                        element: <DaftarTransaksiList />,
                        errorElement: <ErrorPage />,
                        children: [
                            { path: 'berlangsung', element: <Berlangsung /> },
                            { path: 'diproses', element: <Diproses /> },
                            { path: 'Dikirim', element: <Dikirim /> },
                            { path: 'Selesai', element: <PesananSelesai /> },
                            { path: 'Belum Bayar', element: <NotPayment /> },
                            { path: 'Dibatalkan', element: <CancelTransaksiList /> },
                            { path: 'Berhasil', element: <SuccesTransaksiList /> },
                        ],
                    },
                ],
            },
            {
                path: '/detail-toko/:id',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <DetailToko />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: '/category/subCategory/product-category/:categoryProduct',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SpesifikCategory />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: '/category/sub-category/:subCategory',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <Category />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: '/category/:category',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <Category />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: '/discon',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <Discon />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: '/detail-product/:id',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <DetailProduct />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: '/detail-product/update/:id',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <ProductDetailUpdate />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },

            {
                path: '/waiting-payment',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <WaitingPayment />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: '/success-payment',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SuccessPayment />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'detail-product/payment',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <PaymentProduct />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'payment',
                element: <PaymentUpdate />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'delete-account',
                element: <DeletedAccountRequire />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'delete-account/reason',
                element: <DeleteAccountReason />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'delete-account/request-submitted',
                element: <DellAccountRequest />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'detail-product/cart-products',
                element: <CartProducts />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'detail-product/cart/update',
                element: <CartUpdate />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'detail-product/payment/1',
                element: <PaymentFirst />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'reamining-payment',
                element: <RemainingPayment />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'pilih-distributor/:tokoId/:addressId',
                element: <GetDistributor />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'pilih-layanan',
                element: <PilihLayanan />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'detail-product/payment/distributor',
                element: <DistributorProduct />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'detail-product/payment/distributor/package',
                element: <PackageDistributor />,
                errorElement: <ErrorPage />,
            },

            {
                path: 'Dashbord-Udinpay',
                element: <UdinPay />,
                errorElement: <ErrorPage />,
            },

            {
                path: 'Dashbord-Udinpay/Topup',
                element: <TopupUdin />,
                errorElement: <ErrorPage />,
            },

            {
                path: 'Dashbord-Udinpay/Riwayat',
                element: <Riwayat />,
                errorElement: <ErrorPage />,
            },

            {
                path: 'Dashbord-Udinpay/Transfer',
                element: <Transfer />,
                errorElement: <ErrorPage />,
            },

            //childs component UdinPay

            {
                path: 'Dashbord-Udinpay/Topup/indomart',
                element: <Indomaret />,
                errorElement: <ErrorPage />,
            },

            {
                path: 'Dashbord-Udinpay/Topup/alfamart',
                element: <Alfamart />,
                errorElement: <ErrorPage />,
            },

            {
                path: 'Dashbord-Udinpay/Topup/oneklik',
                element: <Oneklik />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'Dashbord-Udinpay/Topup/mbanking',
                element: <Mbanking />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'Dashbord-Udinpay/Topup/atm',
                element: <Atm />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'Dashbord-Udinpay/Topup/credit-card',
                element: <CreditCard />,
                errorElement: <ErrorPage />,
            },

            // TRANSFER

            {
                path: 'Dashbord-Udinpay/Transfer',
                element: <TransferUdinBank />,
                errorElement: <ErrorPage />,
            },

            {
                path: 'Dashbord-Udinpay/Transfer/kesesamaudin',
                element: <TransferUdin />,
                errorElement: <ErrorPage />,
            },

            {
                path: 'Dashbord-Udinpay/Transfer/transferbank',
                element: <TransferBank />,
                errorElement: <ErrorPage />,
            },

            // // RIWAYAT
            // {
            //     path: 'Dashbord-Udinpay/Riwayat',
            //     element: <Riwayat />,
            //     errorElement: <ErrorPage />,
            // },
        ],
    },
    {
        path: '/hometest',
        element: <HomeTest />,
    },
    // Route form Register and Login
    {
        path: 'auth',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <LoginRegister />
            </Suspense>
        ),
        errorElement: <NotFoundPage />,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <NotFoundPage />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'login',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <LoginPage />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'register',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <RegisterForm />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },

            {
                path: 'input-pin',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <InputPIN />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: '*',
                element: <NotFoundPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'otp-login',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SectionLoginOTP />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'otp',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SectionOtpPutPassword />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'add-pin',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <AddPin />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'put-password',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SectionForgotPasswword />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'input-otp-register',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <InputPageOTP />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'register-input-form',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SectionFormsRegister />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'role-register',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <RoleRegisterPage />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'select-role-register',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SectionRoleRegister />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'register-vendor',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <RegisterVendor />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'input-otp-vendor',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <RegisterVendorOTP />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'role-register-vendor',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <RoleRegisterVendor />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'select-role-register-vendor',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SectionRoleRegisterVendor />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'submission-success',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SubmissionComplate />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'adding-form-biodata',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <FormBiodataVendor />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'add-bio-konsumen',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <FormBiodataKonsumen />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },

            // RegisterDistributor

            {
                path: 'select-role-register-distributor',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SectionRoleRegisterDistributor />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'register-distributor',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <AddBioDistributor />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'input-otp-distributor',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <RegisterDistributorOTP />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: 'adding-form-biodata-distributor',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <FormBiodataDistributor />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
        ],
    },

    // Route Vendor
    {
        path: '/vendor',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <DashboardVendor />
            </Suspense>
        ),
        errorElement: <NotFoundPage />,
        children: [
            { index: true, element: <NotFoundPage />, errorElement: <ErrorPage /> },
            {
                path: '/vendor/verifikasi',
                element: <DashboardNotAcc />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/vendor/pesanan',
                element: <OrderProductPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/vendor/add-product',
                element: <AddProductPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/vendor/list-product',
                element: <ListProductPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/vendor/list-product/edit',
                element: <EditProduct />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/vendor/my-toko',
                element: <MyTokoPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/vendor/promotion-product',
                element: <PromotionProductPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/vendor/promotion-product/edit',
                element: <EditPromotion />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/vendor/add-promotion-product',
                element: <AddPromotion />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/vendor/income',
                element: <IncomePage />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/vendor/delevery',
                element: <DeleveryPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/vendor/delevery/location',
                element: <LocationDelevery />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/vendor/review',
                element: <RivewPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/vendor/analisis',
                element: <AnalisisPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/vendor/notifikasi-vendor',
                element: <NotifikasiVendorPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/vendor/notifikasi-vendor',
                element: <NotifikasiVendorPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/vendor/notifikasi-vendor-foods',
                element: <NotificationFoods />,
                errorElement: <ErrorPage />,
            },
            {
                path: '/vendor/notifikasi-orderan',
                element: <NotificationOrderan />,
                errorElement: <ErrorPage />,
            },
        ],
    },
    //route distributor
    {
        path: '/distributor',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <DashboardDistributor />
            </Suspense>
        ),
        errorElement: <NotFoundPage />,
        children: [
            { index: true, element: <NotFoundPage />, errorElement: <ErrorPage /> },

            {
                path: '/distributor/dashboard',
                element: <HomeDistributor />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'kendaraan-driver',
                element: <KendaraanDriverPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'kendaraan-driver/add-kendaraan-individu',
                element: <AddKendaraanIndividu />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'kendaraan-driver/update-kendaraan-individu',
                element: <UpdateKendaraanIndividu />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'kendaraan-driver/first-page',
                element: <FirstPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'manajemen-kendaraan',
                element: <ManajemenKendaraanPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'manajemen-kendaraan/add-kendaraan-perusahaan',
                element: <AddKendaraanPerusahaan />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'manajemen-kendaraan/detail-kendaraan-perusahaan/:id',
                element: <DetailKendaraanPerusahaan />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'manajemen-kendaraan/update-kendaraan-perusahaan/:id',
                element: <UpdateKendaraanPerusahaan />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'manajemen-pengemudi',
                element: <ManajerPengemudiPage />,
                errorElement: <ErrorPage />,
            },

            {
                path: 'manajemen-pengemudi/add-pengemudi-perusahaan',
                element: <AddPengemudiPerusahaan />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'manajemen-pengemudi/detail-pengemudi-perusahaan/:id',
                element: <DetailPengemudi />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'manajemen-pengemudi/update-pengemudi-perusahaan/:id',
                element: <UpdatePengemudiPerusahaan />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'analisis',
                element: <AnalisisDistributorPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'keuangan',
                element: <KeuanganPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'ranting-ulasan',
                element: <UlasanPage />,
                errorElement: <ErrorPage />,
            },

            {
                path: 'proses-pengiriman',
                element: <ProsesPengiriman />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'proses-pengiriman/rute-pengiriman/:id',
                element: <RutePengiriman />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'pesanan-produk',
                element: <PesananProduk />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'pesanan-produk/detail-pesanan-produk/:id',
                element: <DetailPesananProduk />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'pesanan-produk/update-pesanan-produk/:id',
                element: <UpdatePesananProduk />,
                errorElement: <ErrorPage />,
            },
        ],
    },

    //rute profile distributor
    {
        path: '/distributor/profile',
        element: <DashboardProfileDistributor />,
        errorElement: <NotFoundPage />,
        children: [{ index: true, element: <NotFoundPage />, errorElement: <ErrorPage /> }],
    },

    // Pusat Bantuan Routing
    {
        path: '/pusat-bantuan',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <PusatBantuanDashboard />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
        children: [
            { index: true, element: <PusatBantuan />, errorElement: <ErrorPage /> },
            {
                path: 'pemesanan',
                element: <Pemesanan />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'pengiriman',
                element: <Pengiriman />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'promo',
                element: <Promo />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'transaksi',
                element: <Transaksi />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'akun&aplikasi',
                element: <AkunDanAplikasi />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'saldo&tagihan',
                element: <SaldoDanTagihan />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'keamanan-akun',
                element: <KeamananAkun />,
                errorElement: <ErrorPage />,
            },
            {
                path: 'tentang-mbg',
                element: <TentangMBG />,
                errorElement: <ErrorPage />,
            },
        ],
    },

    //custom Routing
    {
        path: '/point',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <MBGPoint />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },

    // Route PPOB
    {
        path: '/ppob',
        element: (
            <Suspense fallback={<LoadingComponent />}>
                <DashboardPpob />
            </Suspense>
        ),
        errorElement: <NotFoundPage />,
        children: [
            { index: true, element: <NotFoundPage />, errorElement: <ErrorPage /> },

            {
                path: 'Ppobdashbord',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <PpobDasbord />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },

            {
                path: 'ppob-topup',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <Topup />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },

            {
                path: 'pulsa',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <Pulsa />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },

            {
                path: 'PaketData',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <PaketData />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },

            {
                path: 'succsesvalidate',
                element: (
                    <Suspense fallback={<LoadingComponent />}>
                        <SuccsesValidate />
                    </Suspense>
                ),
                errorElement: <ErrorPage />,
            },
        ],
    },

    // Route UdinPay
    {
        path: '/udinpay',
        element: <DashboardPpob />,
        errorElement: <NotFoundPage />,
        children: [{ index: true, element: <NotFoundPage />, errorElement: <ErrorPage /> }],
    },
]);
