import React, { useEffect } from 'react';
import { PhoneIcon } from '../../assets/icons';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <header className="w-full h-[38px] bg-blue-800  ">
            <div className="mx-[40px] flex items-center justify-between py-[7px]">
                <div className="flex items-center">
                    <PhoneIcon />
                    <p className="ml-2 font-inter font-medium text-sm text-[#FFFFFF]">Download Apikasi MBG</p>
                </div>
                <ul className="flex space-x-[30px] text-[#FFFF] text-sm">
                    <li className="list-none">
                        <section
                            className="font-inter font-normal"
                        >
                            Bantuan
                        </section>
                    </li>
                    <li className="list-none">
                        <section
                            className="font-inter font-normal"
                        >
                            Tentang MBG
                        </section>
                    </li>
                    <li className="list-none">
                        <Link
                            to={'/discon'}
                            className="font-inter font-normal text-sm text-[#FFFF]"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Diskon
                        </Link>
                    </li>
                </ul>

            </div>
        </header>
    );
};

export default Header;
