/* eslint-disable */
import { useEffect, useState, useRef } from 'react';
import {
    basket,
    pinIcon,
    mailIcon,
    IconWallet,
    IconToko,
} from './icon';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ListCategory from '../CategoryProduct/listCategory';
import SharedCategory from '../CategoryProduct/sharedCategory';
import axios from 'axios';
import { UserData } from '../../helpers/interface/userData';
import { AddressUsers } from '../../helpers/interface/userData/address';
import { DataCategory } from '../../helpers/interface/product/category';
import { IconLogout, MBGIconOrange, UserIcon } from '../../assets/icons';
import Header from '../header';
import {
    setAddress,
    setEmail,
    setOfficeTelepon,
    setPhone,
    setPoin,
    setProfilePict,
    setTotalPrice,
    setUserId,
    setUserName,
} from '../../../redux/store/reducers/detailKonsumen/detailKonsumen';
import { useAppDispatch, useTypedSelector } from '../../../redux/store';
import { GetNotificationKonsumen } from '../../../redux/store/reducers/Konsumen/notification';
import { GetCartList } from '../../../redux/store/reducers/Konsumen/cart';
import NotificationWthNumber from './component/notifIcon';
import CartIcon from './component/cartIcon';
import MessageIcon from './component/msgIcon';
import axiosInstance from '../../../api/axiosInstance';

const Navbar = () => {
    const [userData, setUserData] = useState<UserData | null>(null);
    const [dataAddress, setDataAddress] = useState<AddressUsers | null>(null);
    const [category, setCategory] = useState<DataCategory | null>(null);
    const [showCategory, setShowCategory] = useState(false);
    const [showSharedCategory, setShowSharedCategory] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [closeTimeout, setCloseTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    console.log(loading);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [role, setRole] = useState<string | null>(null);
    const cookies = new Cookies();
    const token = cookies.get('token');
    const dropdownSharedRef = useRef<HTMLDivElement>(null);
    const dropdownCategoryRef = useRef<HTMLDivElement>(null);

    //-----------------------------------------------------------------get notification here-------------------------------------------------------------------
    useEffect(() => {
        dispatch(GetNotificationKonsumen());
        dispatch(GetCartList());
    }, []);
    //-----------------------------------------------------------------get notification here-------------------------------------------------------------------

    const fetchData = async (url: string, setState: React.Dispatch<React.SetStateAction<any>>) => {
        if (!token) {
            navigate('/auth/login');
            return;
        }
        try {
            const response = await axiosInstance.get(url, {
                headers: {
                    Authorization: `${token}`,
                    'ngrok-skip-browser-warning': '69420', //for bypass ngrok webview
                },
            });
            setState(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const addUserDetailToRedux = () => {
        if (userData) {
            dispatch(setUserId(userData?.datas?.userId._id));
            dispatch(setAddress(userData?.datas?.address._id));
            dispatch(setOfficeTelepon(userData?.datas.noTeleponKantor));
            dispatch(setEmail(userData?.datas?.userId.email.content));
            dispatch(setPhone(userData?.datas?.userId.phone.content));
            dispatch(setProfilePict(userData?.datas?.profile_pict));
            dispatch(setUserName(userData?.datas?.userId._id));
            dispatch(setPoin(userData?.datas?.userId.poin));
            dispatch(setTotalPrice(userData?.datas?.userId.saldo));
        }
    };

    useEffect(() => {
        if (userData) {
            setRole(userData.datas.userId.role); // Assuming userData has a role field
        }
    }, [userData]);

    useEffect(() => {
        addUserDetailToRedux();
    }, [userData]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            // Close SharedCategory dropdown if clicked outside
            if (dropdownSharedRef.current && !dropdownSharedRef.current.contains(event.target as Node)) {
                setShowSharedCategory(false);
            }

            // Close Category dropdown if clicked outside
            if (dropdownCategoryRef.current && !dropdownCategoryRef.current.contains(event.target as Node)) {
                setShowCategory(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // fetchData(`${process.env.REACT_APP_API_URL}/konsumen/detail`, setUserData);
        // fetchData(`${process.env.REACT_APP_API_URL}/vendor/detail`, setUserData);
        fetchData(`${process.env.REACT_APP_API_URL}/address/list`, setDataAddress);
        fetchData(`${process.env.REACT_APP_API_URL}/category/specific-category`, setCategory);
    }, []);

    const handleToggle = (setter: React.Dispatch<React.SetStateAction<boolean>>) => () => {
        setter((prev) => !prev);
    };

    const handleHideCategory = () => {
        setShowCategory(false);
    };

    const handleLogout = () => {
        if (cookies.get('token')) {
            cookies.remove('token', { path: '/' });
            localStorage.removeItem('token');

            window.location.reload();
        } else {
            console.log('Token not found');
        }
    };

    const handleMouseEnter = () => {
        clearTimeout(closeTimeout);
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        const timeoutId = setTimeout(() => {
            setIsHovered(false);
        }, 1000); // 1000 milliseconds = 1 second
        setCloseTimeout(timeoutId);
    };
    //--------------------------------------------------ini masi diambil manual karena by redux masi telat--------------------------------
    const [saldo, setSaldo] = useState<number>(0);
    const dataKonsumen = useTypedSelector((state) => state.DetailKonsumen.data);

    const getKonsumenDetail = async () => {
        await axiosInstance
            .get(`${process.env.REACT_APP_API_URL}/konsumen/detail`)
            .then((res) => {
                setSaldo(res.data.datas.userId.saldo);
            })
            .catch((err) => {
                console.log();
            });
    };
    useEffect(() => {
        getKonsumenDetail();
    }, []);

    const dataUser = useTypedSelector((state) => state.detailUser);
    const handleNavigateToCategory = (categoryId: string, NamaKategori: string) => {
        navigate(`/category/subCategory/product-category/${categoryId}`, { state: { NamaKategori: NamaKategori } });
    };
    return (
        <div className="w-full z-20 shadow-sm fixed bg-white ">
            <Header />
            <div className="mx-[40px] py-[10px] flex justify-between gap-[20px] items-center">
                <div onClick={() => navigate(`/`)} className="w-[80px] cursor-pointer">
                    <MBGIconOrange />
                </div>
                <div
                    onClick={handleToggle(setShowSharedCategory)}
                    className="h-[50px] px-[26px] py-[13px] flex-1 mr-[20px] rounded-[100px] border border-zinc-200 items-center gap-2.5 inline-flex"
                >
                    <input
                        placeholder="Cari Makanan"
                        className="text-neutral-400 outline-none w-full text-base font-normal font-Inter leading-normal"
                    />
                </div>
                <div className="flex items-right overflow-hidden">
                    <div className="flex h-[26px] pt-0.5 justify-between items-center gap-8 inline-flex">
                        <div onClick={() => navigate('/profile/chat')} className="w-6 h-6 relative">
                            <MessageIcon msgLength={9100}/>
                        </div>
                        <div onClick={() => navigate('/profile/notification')} className="w-6 h-6 relative">
                            <NotificationWthNumber notifLength={5} />
                        </div>
                        <div onClick={() => navigate('/detail-product/cart-products')} className="w-6 h-6 relative">
                            <CartIcon cartLength={10}/>
                        </div>
                    </div>
                    <div className="ml-8  h-full origin-top-left border-[1px] w-[1px] border-zinc-200 my-auto"></div>
                    {/* Profil */}
                    <div className="flex items-center ml-auto space-x-2">
                        {dataUser ? (
                            <div
                                className="flex items-center space-x-2 cursor-pointer"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <img
                                    src={dataUser.ProfilePict || ''}
                                    alt="profile"
                                    className="w-[32px] h-[32px] object-cover rounded-full bg-[#31DC41]"
                                />

                                <p className="text-[16px]  font-inter font-bold text-right">
                                    {dataUser && dataUser.UserName ? (
                                        <span
                                            title={dataUser.UserName}
                                            className="inline-block capitalize max-w-full whitespace-nowrap overflow-hidden"
                                        >
                                            {dataUser.UserName.length > 7
                                                ? `${dataUser.UserName.slice(0, 8)}...`
                                                : dataUser.UserName}
                                        </span>
                                    ) : (
                                        'No Name'
                                    )}
                                </p>
                            </div>
                        ) : (
                            <div
                                className="flex items-center space-x-2"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className="bg-white"> {UserIcon()}</div>
                                <p className="text-[16px] font-inter font-bold">No Data Available</p>
                            </div>
                        )}
                    </div>
                    {isHovered && (
                        <div
                            className="fixed mt-[30px] mr-[24px] bg-white shadow-md rounded-lg right-[0px] p-[10px] text-left"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div
                                onClick={() => navigate(`/profile/setting`)}
                                className="flex mb-[5px] p-[5px] hover:bg-slate-100 active:bg-slate-300 hover:rounded-md cursor-pointer"
                            >
                                <img
                                    src={dataUser.ProfilePict || ''}
                                    alt="profile"
                                    className="w-[30px] h-[30px] object-cover rounded-full bg-[#31DC41]"
                                />
                                <p className="font-inter p-[5px]">Profile</p>
                            </div>
                            <div
                                onClick={handleLogout}
                                className="flex  p-[5px] hover:bg-slate-100 active:bg-slate-300 hover:rounded-md cursor-pointer"
                            >
                                {IconLogout()}
                                <p className="pl-[5px]">Keluar</p>
                            </div>
                        </div>
                    )}
                    {/*role vendor  di tampilkan yang ini */}
                    {isHovered && role === 'vendor' && (
                        <div
                            ref={dropdownSharedRef}
                            className="fixed mt-[30px] bg-white border shadow-md rounded-lg right-[0px] w-[180px] p-[10px] text-left"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Link to={`/profile`}>
                                <div className="flex gap-[16px] mt-[8px] border-b  px-[8px] py-[4px]">
                                    <img
                                        src={userData?.datas.profile_pict}
                                        alt="profile"
                                        className="w-[30px] h-[30px] object-cover rounded-full bg-[#31DC41]"
                                    />

                                    <p className=" text-[14px] font-normal font-[inter] py-[6px] mr-[30px]">Profil</p>
                                    <p className="bg-[#007F73] w-[8px] h-[8px] rounded-full my-[10px]"></p>
                                </div>
                            </Link>
                            <Link to={`/vendor/pesanan`}>
                                <div className="flex gap-[16px]  border-b px-[8px] py-[4px] ">
                                    <div className="w-[30px] h-[30px] bg-[#D9ECEA] rounded-full p-[8px]">
                                        {IconToko()}
                                    </div>
                                    <p className=" text-[14px] font-normal font-[inter] py-[6px]">Toko Saya</p>
                                </div>
                            </Link>
                            <div
                                onClick={handleLogout}
                                className="flex gap-[16px] ml-[8px] hover:bg-slate-100 active:bg-slate-300 hover:rounded-md cursor-pointer"
                            >
                                <div className="w-[30px] h-[30px]  p-[8px]">{IconLogout()}</div>
                                <p className="p-[5px]">Keluar</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex justify-between mx-[40px] pb-[10px]">
                {category && (
                    <div className="h-6 justify-start items-center inline-flex">
                        {category.data.slice(0, 4).map((item, index) => (
                            <div
                                key={index}
                                className="capitalize pr-[24px] cursor-pointer "
                                onClick={() => handleNavigateToCategory(item._id, item.name)}
                            >
                                <p className="text-neutral-400 text-base font-normal font-Inter leading-tight">
                                    {item.name}
                                </p>
                            </div>
                        ))}
                        <div>
                            <span className="text-[#A6A7A8] pr-[24px]">|</span>
                        </div>
                        <div>
                            <button
                                onClick={handleToggle(setShowCategory)}
                                className="text-gray-500 text-base font-medium font-Inter leading-tight"
                            >
                                Pilih Kategori
                            </button>
                        </div>
                    </div>
                )}
                <div className="flex items-center space-x-4">
                    <div className="flex items-center space-x-2">
                        {IconWallet()}
                        <p className="h-4 text-gray-500 text-sm font-medium font-Inter leading-[16.80px]">
                            Saldo Rp {dataKonsumen?.userId.saldo.toLocaleString('de-DE')}
                        </p>
                    </div>
                    {dataAddress && dataAddress.datas && dataAddress.datas.length > 0 ? (
                        <div className="flex gap-[8px]">
                            {pinIcon()}
                            {dataAddress.datas[0].regency}
                        </div>
                    ) : (
                        // dataAddress.datas.map(
                        //     (item, index) =>
                        //         item.isMain && (
                        //             <div className="flex items-center space-x-1" key={index}>
                        //                 {pinIcon()}
                        //                 <p className="text-zinc-800 text-base font-medium font-['Inter'] leading-tight">
                        //                     {item.province}
                        //                 </p>
                        //             </div>
                        //         ),
                        // )
                        <div className="flex items-center space-x-1">
                            {pinIcon()}
                            <p className="text-zinc-800 text-base font-medium font-Inter leading-tight">
                                Isi Alamat{' '}
                                <span className="text-gray-500 text-base font-medium font-Inter leading-tight">
                                    Kamu dulu
                                </span>
                            </p>
                        </div>
                    )}
                </div>
            </div>
            {showCategory && (
                <div ref={dropdownCategoryRef} className=" bg-white z-40 max-w-[1440px] mx-auto   ">
                    <ListCategory onProductClick={handleHideCategory} />
                </div>
            )}
            {showSharedCategory && (
                <div ref={dropdownSharedRef} className="absolute w-full z-40">
                    <SharedCategory />
                </div>
            )}
        </div>
    );
};

export default Navbar;
