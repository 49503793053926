import React, { useState, useEffect, useCallback } from 'react';
import { chervonLeft2, chervonRight2 } from '../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchCategories } from '../../../../redux/store/reducers/category/categorySlice';
import { RootState } from '../../../../redux/store';

interface ListCategoryProps {
    onProductClick: () => void;
}

const ListCategory: React.FC<ListCategoryProps> = ({ onProductClick }) => {
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [selectedSubCategory, setSelectedSubCategory] = useState<string>('');
    console.log(selectedSubCategory);
    const [activeProduct, setActiveProduct] = useState<string>('');
    const [activeCategoryIndex, setActiveCategoryIndex] = useState<number>(0);
    const [visibleCount, setVisibleCount] = useState<number>(11);

    const dispatch = useDispatch();
    const { categories } = useSelector((state: RootState) => state.categorySlice);
    console.log(categories);

    useEffect(() => {
        dispatch(fetchCategories() as any);
    }, [dispatch]);

    useEffect(() => {
        if (categories?.length > 0) {
            const firstCategory = categories[0];
            setSelectedCategory(firstCategory.name);
        }
    }, [categories]);

    const handleShowSelectCategory = useCallback((categoryName: string) => {
        setSelectedCategory(categoryName);
        setSelectedSubCategory('');
        setActiveProduct('');
    }, []);

    const handleActiveProduct = useCallback(
        (productName: string) => {
            setActiveProduct(productName);
            onProductClick();
        },
        [onProductClick],
    );

    const handleNextCategory = useCallback(() => {
        setActiveCategoryIndex((prevIndex) => {
            if (prevIndex < categories?.length - visibleCount) {
                return prevIndex + 1;
            }
            return prevIndex;
        });
    }, [categories.length, visibleCount]);

    const handlePrevCategory = useCallback(() => {
        setActiveCategoryIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }, []);

    const updateVisibleCount = useCallback(() => {
        const containerWidth = document.getElementById('category-container')?.offsetWidth || window.innerWidth;
        const itemWidth = 170; // Lebar setiap kategori plus margin
        const count = Math.floor(containerWidth / itemWidth);
        setVisibleCount(count);
    }, []);

    useEffect(() => {
        updateVisibleCount();
        window.addEventListener('resize', updateVisibleCount);
        return () => {
            window.removeEventListener('resize', updateVisibleCount);
        };
    }, [updateVisibleCount]);

    const visibleCategories = categories.slice(activeCategoryIndex, activeCategoryIndex + visibleCount);
    const selectedCategoryObject = categories.find((category) => category.name === selectedCategory);

    if (categories?.length === 0) {
        return (
            <div className="flex justify-center items-center h-screen">
                <p>Data belum tersedia</p>
            </div>
        );
    }

    return (
        <div className="relative lg:h-[450px] font-inter w-full">
            <div className="flex border-b h-[90px] mx-[60px] items-center" id="category-container">
                {activeCategoryIndex > 0 && (
                    <div className="mr-[10px] absolute bg-slate-100 mt-1 rounded-full flex items-center justify-center">
                        <button onClick={handlePrevCategory} aria-label="Previous category" className="p-[12px]">
                            {chervonLeft2()}
                        </button>
                    </div>
                )}
                <div className=" right-20 bg-slate-100 rounded-full absolute mt-1 flex items-center justify-center">
                    {activeCategoryIndex < categories?.length - visibleCount && (
                        <button onClick={handleNextCategory} aria-label="Next category" className="p-[15px] ">
                            {chervonRight2()}
                        </button>
                    )}
                </div>
                <div className=" flex overflow-hidden">
                    {visibleCategories.map((category) => (
                        <div key={category._id} className="mt-[10px]">
                            <button
                                onClick={() => handleShowSelectCategory(category.name)}
                                className={`py-1 px-[20px] capitalize  ${
                                    selectedCategory === category.name
                                        ? 'border border-blue-500 text-blue-500 rounded-full font-inter  text-[16px]'
                                        : 'text-[16px]'
                                }`}
                            >
                                {category.name}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <div className="overflow-auto w-full px-[60px] h-[350px]">
                {selectedCategoryObject && (
                    <div className="grid lg:grid-cols-5 gap-10 h-auto text-left w-[100%] mt-[30px]">
                        {selectedCategoryObject?.contents?.length > 0 ? (
                            selectedCategoryObject?.contents?.map((subCategory) => (
                                <div key={subCategory._id}>
                                    <h3 className="font-bold capitalize">{subCategory.name}</h3>
                                    <div className="leading-7">
                                        {subCategory.contents.map((product) => (
                                            <Link
                                                to={`/category/subCategory/product-category/${product._id}`}
                                                onClick={() => handleActiveProduct(product.name)}
                                                key={product._id}
                                                className={`flex flex-col text-left capitalize ${
                                                    activeProduct === product.name ? 'text-blue-500' : ''
                                                }`}
                                            >
                                                {product.name}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="absolute text-center w-[100%] min-h-screen">
                                <p>Data Tidak Tersedia</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ListCategory;
