import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { LacakPengiriman } from '../../../../../konsumnen/pages/profile/menu/daftarTransaksiList/LacakTransaksi/interface';

export const TrackingShipment = createAsyncThunk(
  'tracking/shipment',
  async ({
    id_toko,
    id_distributor,
    pengirimanId,
    id_sekolah
  }: {
    id_toko: string;
    id_distributor: string;
    pengirimanId: string;
    id_sekolah: string;
  }) => { 
    console.log('dispatchnya dah jalan');
    
    const response = await axiosInstance.get(`/treking/lacak/${id_toko}/${id_distributor}/${pengirimanId}/${id_sekolah}`); 
    console.log(response);
    return response.data;
    
  }
);

// Redux slice
const shipmentTracking = createSlice({
    name: 'shipmentTracking',
    initialState: {
      data: null as LacakPengiriman | null,
      loading: false,
      error: null as string | null | undefined, 
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(TrackingShipment.pending, (state) => {
          state.loading = true;
          state.error = null;  
        })
        .addCase(TrackingShipment.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(TrackingShipment.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;  
        });
    },
  });
  

export default shipmentTracking.reducer;
