import React, { useState } from 'react'
import { ReasonDellAcoount } from '../../data'
interface inputReasonprops {
  inputReason: (value: string) => void;

}

export default function DellAccountReasonInput({ inputReason }: inputReasonprops) {
  const [selectedReason, setSelectedReason] = useState<string | null>(null);
  const handleCheckboxChange = (reason: string) => {
    inputReason(reason)
    if (selectedReason === reason) {
      setSelectedReason(null);
    } else {
      setSelectedReason(reason);
    }
  };
  return (
    <div className='mt-[48px]'>
      <h5 className='text-[16px] text-[#25282B] font-nedium'>Alasan Menutup akun ?</h5>
      <div className='flex flex-col mt-[10px] gap-[12px]'>
        {ReasonDellAcoount.map((item, key) => (
          <section key={key} className='flex items-center gap-[12px]'>
            <input
              type='radio'
              checked={selectedReason === item}
              onChange={() => handleCheckboxChange(item)}
            />
            <p className='text-[14px] text-[#25282B] font-normal'>{item}</p>
          </section>
        ))}

        <section className='flex items-center gap-[12px]'>
          <input
            type='radio'
            checked={selectedReason === ''}
            onChange={() => handleCheckboxChange('')}
          />
          <p className='text-[14px] text-[#25282B] font-normal'>Lainnya</p>
        </section>

        {selectedReason === '' && (
          <input
            onChange={(e) => inputReason(e.target.value)}
            type='text'
            placeholder='Ketikan Seusatu'
            className='border mt-2 p-[16px] max-w-[413px] rounded-[8px]'
          />
        )}
      </div>
    </div>
  )
}
