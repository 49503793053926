import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DisplayPayments } from '../../../../../../konsumnen/pages/paymentUpdate/inteface/displayPayment';
import { UpdateProductQuantityPayload } from './interfaceReducers';

interface dataForDisplayPayment {
  data: DisplayPayments | null
}

const initialState: dataForDisplayPayment = {
  data: null
};

const DisplayPayment = createSlice({
  name: 'display payment page',
  initialState,
  reducers: {
    setDisplayPaymentData(state, action: PayloadAction<DisplayPayments>) {
      state.data = action.payload;
    },
    editProductQuantity(state, action: PayloadAction<UpdateProductQuantityPayload>) {
      const { toko_id, product_id, newQty } = action.payload;
      if (state.data) {
        const targetToko = state.data.List.find(toko => toko.toko_id === toko_id);
        if (targetToko) {
          const targetProduct = targetToko.product.find(prod => prod.id === product_id);
          if (targetProduct) {
            targetProduct.qty = newQty;
          }else {
            console.log(`gk ada product dengan id ${product_id}`);
            
          }
        }else {
          console.log(`gk ada toko dengan Id ${toko_id}`);
          
        }
      } 
    },
    resetDisplayPaymentData(state) {
      state.data = null;
    }
  },
});

export const { setDisplayPaymentData, resetDisplayPaymentData, editProductQuantity } = DisplayPayment.actions;

export default DisplayPayment.reducer;
