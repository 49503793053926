import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Daum, SekolahList } from './interface';
import axiosInstance from '../../../../../api/axiosInstance';

// Interface SekolahState
interface SekolahState {
  data: SekolahList;
  loading: boolean;
  error: string | null;
}

// Async thunk untuk mengambil list sekolah
export const GetListSekolah = createAsyncThunk<SekolahList, { IdKonsumen: string }, { rejectValue: string }>(
  'sekolah/fetchSekolah',
  async ({ IdKonsumen }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<SekolahList>(
        `${process.env.REACT_APP_API_URL}/konsumen/sekolah/list/${IdKonsumen}`
      );
      console.log(IdKonsumen);
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to fetch schools');
    }
  }
);

// Initial state
const initialState: SekolahState = {
  data: { message: '', data: [] },
  loading: false,
  error: null,
};

// Slice untuk mengelola state
const sekolahSlice = createSlice({
  name: 'sekolah',
  initialState,
  reducers: {
    setSekolahList(state, action: PayloadAction<Daum[]>) {
      state.data.data = action.payload;
    },
    addSekolah(state, action: PayloadAction<Daum>) {
      state.data.data.push(action.payload);
    },
    removeSekolah(state, action: PayloadAction<string>) {
      state.data.data = state.data.data.filter((sekolah) => sekolah._id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetListSekolah.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetListSekolah.fulfilled, (state, action: PayloadAction<SekolahList>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(GetListSekolah.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setSekolahList, addSekolah, removeSekolah } = sekolahSlice.actions;
export default sekolahSlice.reducer;
