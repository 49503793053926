import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreList } from '../../../konsumnen/helpers/types';

export type GetListDua = {
    datas: StoreList[];
};

export interface CartState {
    list: GetListDua | null;
    cartId: string;
    minimalOrder: number | null;
    batasMinOrder: number | null;
    paramsId: string;
}

const initialState: CartState = {
    list: null,
    cartId: '',
    minimalOrder: null,
    batasMinOrder: null,
    paramsId: '',
};

const CartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setList: (state, action: PayloadAction<GetListDua | null>) => {
            state.list = action.payload;
        },
        setBatasMinOrder: (state, action: PayloadAction<number | null>) => {
            state.batasMinOrder = action.payload;
        },
        setMinimalOrder: (state, action: PayloadAction<number | null>) => {
            state.minimalOrder = action.payload;
        },
        setParamsId: (state, action: PayloadAction<string>) => {
            state.paramsId = action.payload;
        },
    },
});

export const CartSliceServis = { actions: CartSlice.actions };
const CartReducer = CartSlice.reducer;
export default CartReducer;
