import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface ResponseData {
   
        address: {
            address: string;
            address_description: string;
            code_pos: string;
            district: string;
            isMain: boolean;
            isPic: boolean;
            isUsed: boolean;
            pinAlamat: {
                lat: number;
                long: number;
            };
            province: string;
            regency: string;
            userId: string;
            village: string;
            __v: number;
            _id: string;
        };
        jenis_kelamin: string | null;
        jenis_perusahaan: string;
        nama: string;
        nilai_review: number;
        noTeleponKantor: string;
        pic: {
            alamat: string;
            detailId: string;
            file_npwp: string;
            jabatan: string;
            ktp_file: string;
            nama: string;
            nik: string;
            nomorNpwp: string;
            userId: string;
            __v: number;
            _id: string;
        };
        profile_pict: string;
        tanggal_lahir: string;
        userId: {
            codeOtp: {
                code: string;
                expire: string;
            };
            createdAt: string;
            email: {
                content: string;
                isVerified: boolean;
            };
            isActive: boolean;
            isBlocked: boolean;
            isDetailVerified: boolean;
            isVerifikasiDocument: boolean;
            phone: {
                content: string;
                isVerified: boolean;
            };
            pin: string | null;
            poin: number;
            role: string;
            saldo: number;
            updatedAt: string;
            __v: number;
            _id: string;
            defaultAddress: any[];
            isIndividu: boolean;
        };
        _id: string
    
}

interface ResponseDataState {
    data: ResponseData | null;
}

const initialState: ResponseDataState = {
    data: null,
};

const responseDataSlice = createSlice({
    name: 'responseData',
    initialState,
    reducers: {
        setResponseData(state, action: PayloadAction<ResponseData>) {
            state.data = action.payload;
        }
    }
});

export const { setResponseData } = responseDataSlice.actions;

export default responseDataSlice.reducer;
