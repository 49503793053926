import React from 'react'

export default function Head() {
  return (
    <div className='w-full'>
       <section className='bg-[#E8EEF8] p-[10px] w-full flex gap-[12px] items-center rounded-[12px] mt-[40px]'>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.0008 0.5C13.7857 0.5 16.4566 1.60633 18.4259 3.5756C20.3952 5.54487 21.5015 8.21578 21.5015 11.0007C21.5015 13.7857 20.3952 16.4566 18.4259 18.4259C16.4566 20.3952 13.7857 21.5015 11.0008 21.5015C8.21578 21.5015 5.54487 20.3952 3.5756 18.4259C1.60633 16.4566 0.5 13.7857 0.5 11.0007C0.5 8.21578 1.60633 5.54487 3.5756 3.5756C5.54487 1.60633 8.21578 0.5 11.0008 0.5ZM12.5758 6.947C13.3558 6.947 13.9887 6.4055 13.9887 5.603C13.9887 4.8005 13.3542 4.259 12.5758 4.259C11.7957 4.259 11.1657 4.8005 11.1657 5.603C11.1657 6.4055 11.7957 6.947 12.5758 6.947ZM12.8502 15.3875C12.8502 15.227 12.9057 14.81 12.8742 14.573L11.6413 15.992C11.3863 16.2605 11.0667 16.4465 10.9167 16.397C10.8487 16.372 10.7918 16.3235 10.7563 16.2602C10.7209 16.197 10.7091 16.1231 10.7233 16.052L12.7782 9.56C12.9462 8.7365 12.4843 7.985 11.5048 7.889C10.4713 7.889 8.95025 8.9375 8.02475 10.268C8.02475 10.427 7.99475 10.823 8.02625 11.06L9.25775 9.6395C9.51275 9.374 9.80975 9.1865 9.95975 9.2375C10.0337 9.26402 10.0942 9.31847 10.1284 9.38914C10.1626 9.45981 10.1678 9.54108 10.1427 9.6155L8.10575 16.076C7.87025 16.832 8.31575 17.573 9.39575 17.741C10.9858 17.741 11.9248 16.718 12.8517 15.3875H12.8502Z" fill="#1454B8" />
        </svg>
        <p className='text-[12px] text-[#1454B8] font-normal'>Penghapusan akun ini bersifat permanen dan tidak dapat dikembalikan</p>
      </section>
      <p className='text-[#25282B] text-[16px] font-medium my-[16px]'>Persyaratan yang harus dipenuhi sebelum menghapus akun MBG</p>
      <p className='text-[14px] text-[#818284] font-normal'>Beberapa hal yang harus kamu tuntaskan</p>
    </div>
  )
}
