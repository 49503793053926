import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BodyPayment } from '../../../../../../konsumnen/pages/paymentUpdate/inteface/bodyPayment';

interface payloadPayment {
  data: BodyPayment | null
}

const initialState: payloadPayment = {
  data: null
};

const PaymentPayload = createSlice({
  name: 'bodyPayment',
  initialState,
  reducers: {
    setBodyPayment(state, action: PayloadAction<BodyPayment>) {
      state.data = action.payload;
    },
    updateItemProductQty(state, action: PayloadAction<{ idTokoVendor: string, productId: string, quantity: number }>) {
      if (state.data) {
        const { idTokoVendor, productId, quantity } = action.payload;

        const item = state.data.items.find(item => item.idTokoVendor === idTokoVendor);
        if (item) {
          const product = item.product.find(prod => prod.productId === productId);
          if (product) {
            product.quantity = quantity;
          } else {
            console.log('Error: Product not found in items.');
          }
        } else {
          console.log('Error: Vendor not found in items.');
        }
      } else {
        console.log('Error: state.data is null. Product quantity in items gagal diubah.');
      }
    }, 
    updateShipmentProductQty(state, action: PayloadAction<{ productId: string, quantity: number }>) {
      if (state.data) {
        const { productId, quantity } = action.payload;

        let productFound = false;
        state.data.shipments.forEach(shipment => {
          const product = shipment.products.find(prod => prod.productId === productId);
          if (product) {
            product.quantity = quantity;
            productFound = true;
          }
        });
        if (!productFound) {
          console.log('Error: Product not found in any shipment.');
        }
      } else {
        console.log('Error: state.data is null. Product quantity in shipment gagal diubah.');
      }
    },
    updateDistributorId(state, action: PayloadAction<{ id_toko_vendor: string, id_distributor: string }>) {
      if (state.data) {
        const { id_toko_vendor, id_distributor } = action.payload;
    
        const shipment = state.data.shipments.find(shipment => shipment.id_toko_vendor === id_toko_vendor);
        if (shipment) {
          shipment.id_distributor = id_distributor;
        } else {
          console.log('Error: Shipment not found.');
        }
      } else {
        console.log('Error: state.data is null. Distributor ID gagal diubah.');
      }
    },
    updateShipment(state, action: PayloadAction<{ idTokoVendor: string; id_jenis_kendaraan: string; id_jenis_layanan: string; ongkir: number }>) {
      const { idTokoVendor, id_jenis_kendaraan, id_jenis_layanan, ongkir } = action.payload;

      if (state.data) {
        const shipment = state.data.shipments.find(shipment => shipment.id_toko_vendor === idTokoVendor);

        if (shipment) {
          shipment.id_jenis_kendaraan = id_jenis_kendaraan;
          shipment.id_jenis_layanan = id_jenis_layanan;
          shipment.ongkir = ongkir;

          shipment.total_ongkir = shipment.ongkir - shipment.potongan_ongkir;
        }
      }
    },
    setMetodePembayaran(state, action: PayloadAction<string>) {
      if (state.data) {
        state.data.metode_pembayaran = action.payload;
      } else {
        console.log('Error: state.data is null. Metode pembayaran gagal dimasukan.');
      }
    },
    setSekolahId(state, action: PayloadAction<string>) {
      if (state.data) {
        state.data.sekolahId = action.payload;
      } else {
        console.log('Error: state.data is null. Sekolah ID gagal diubah.');
      }
    },
  },
});

export const {setBodyPayment, updateShipmentProductQty, updateShipment, updateDistributorId, updateItemProductQty, setMetodePembayaran, setSekolahId } = PaymentPayload.actions;

export default PaymentPayload.reducer;
