import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import {
    CreateKendaraanPerusahaan,
    DistributorDetail,
    KendaraanList,
    JenisKendaraan,
    MerkKendaraan,
    TarifKendaraan,
    TarifBerat,
    JenisLayanan,
    TypeKendaraan,
} from '../../../../../distributor/types';

interface ManajemenKendaraanState {
    jenisLayanan: JenisLayanan[];
    jenisKendaraan: JenisKendaraan[];
    merkKendaraan: MerkKendaraan[];
    tarifKendaraan: TarifKendaraan[];
    typeKendaraan: TypeKendaraan[];
    tarifBerat: TarifBerat[];
    dataDistributor: DistributorDetail;
    dataKendaraan: KendaraanList[];
    createKendaraan: CreateKendaraanPerusahaan[];
    loading: boolean;
    error: string | null;
}

const initialState: ManajemenKendaraanState = {
    typeKendaraan: [],
    merkKendaraan: [],
    jenisKendaraan: [],
    tarifKendaraan: [],
    jenisLayanan: [],
    tarifBerat: [],
    dataDistributor: {
        alamat_id: {
            pinAlamat: {
                lat: {
                    $numberDecimal: '',
                },
                long: {
                    $numberDecimal: '',
                },
            },
        },
        imageProfile: '',
        _id: '',
        jenisKelamin: '',
        nama_distributor: '',
        userId: {
            phone: {
                content: 0,
            },
            role: '',
        },
        individu: [],
        jenisPerusahaan: '',
        tanggal_lahir: '',
    },
    dataKendaraan: [],
    createKendaraan: [],
    loading: false,
    error: null,
};

//Data Kendaraan
export const fetchKendaraanPerusahaan = createAsyncThunk<KendaraanList[], void>(
    'kendaraanPerusahaan/fetchKendaraanPerusahaan',
    async () => {
        try {
            const response = await axiosInstance.get(`/kendaraan-distributtor/list`);
            console.log('response data kendaraan ', response.data.data);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching kendaraan list:', error);
            throw error;
        }
    },
);

//Create Kendaraan
export const addKendaraan = createAsyncThunk<CreateKendaraanPerusahaan, FormData>(
    'kendaraanPerusahaan/addKendaraan',
    async (formData) => {
        try {
            const response = await axiosInstance.post('/kendaraan-distributtor/create-perusahaan', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Response dari server:', response.data.message);
            return response.data.data;
        } catch (error: any) {
            console.error('Error saat mengunggah data:', error);
            throw new Error(error.response?.data?.message || 'Gagal mengunggah data');
        }
    },
);

//Update Kendaraan
export const updatekendaraan = createAsyncThunk<KendaraanList, { id: string; formData: FormData }>(
    'kendaraanPerusahaan/updateKendaraan',
    async ({ id, formData }) => {
        try {
            const response = await axiosInstance.put(`/kendaraan-distributtor/update/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data.data;
        } catch (error: any) {
            throw new Error(error.response?.data?.message || 'Gagal mengupdate data');
        }
    },
);

//Data Distributor
export const fetchDistributorDetail = createAsyncThunk<DistributorDetail, void>(
    'DistributorDetail/fetchDistributorDetail',
    async () => {
        try {
            const response = await axiosInstance.get(`/distributor/detail`);
            console.log('response data distributor ', response.data.data);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching pengemudi list:', error);
            throw error;
        }
    },
);

//Data Tarif Kendaraan
export const fetchTarifKendaraan = createAsyncThunk<TarifKendaraan[], void>(
    'tarifKendaraan/fetchTarifKendaraan',
    async () => {
        const response = await axiosInstance.get('/tarif/list');
        console.log('response tarif kendaraan', response.data.data);
        return response.data.data;
    },
);

//Jenis Layanan
export const fetchJenisLayanan = createAsyncThunk<JenisLayanan[], void>('jenisLayanan/fetchJenisLayanan', async () => {
    const response = await axiosInstance.get('/jasa-distributor/list');
    console.log('response jenis layanan', response.data.data);
    return response.data.data;
});

//Jenis Kendaraan
export const fetchJenisKendaraan = createAsyncThunk<JenisKendaraan[], void>(
    'jenisKendaraan/fetchJenisKendaraan',
    async () => {
        const response = await axiosInstance.get('/jenis-kendaraan/list/');
        console.log('response jenis kendaraan', response.data.datas);
        return response.data.datas;
    },
);

//Merk Kendaraan
export const fetchMerkKendaraan = createAsyncThunk<MerkKendaraan[], string>(
    'merkKendaraan/fetchMerkKendaraan',
    async (jenisKendaraan) => {
        const response = await axiosInstance.get(`/merk-kendaraan/list?jenis=${jenisKendaraan}`);
        return response.data.datas; // Pastikan ini sesuai dengan struktur data yang Anda terima
    },
);
export const fetchTypeKendaraan = createAsyncThunk<TypeKendaraan[], string>(
    'typeKendaraan/fetchTypeKendaraan',
    async () => {
        const response = await axiosInstance.get(`/type-kendaraan/list`);
        console.log('response.data.datas', response.data.data);
        return response.data.data;
    },
);

//Tarif Berat
export const fetchTarifBerat = createAsyncThunk<TarifBerat[], void>('tarifBerat/fetchTarifBerat', async () => {
    const response = await axiosInstance.get('/biaya_tetap/list');
    console.log('response tarif berat', response.data.data);
    return response.data.data;
});

const ManajemenKendaraanSlice = createSlice({
    name: 'manajemenKendaraan',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchKendaraanPerusahaan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchKendaraanPerusahaan.fulfilled, (state, action: PayloadAction<KendaraanList[]>) => {
                state.loading = false;
                state.dataKendaraan = action.payload;
            })
            .addCase(fetchKendaraanPerusahaan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch kendaraan';
                console.error('Error fetching kendaraan:', action.error.message);
            })
            .addCase(addKendaraan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addKendaraan.fulfilled, (state, action: PayloadAction<CreateKendaraanPerusahaan>) => {
                state.loading = false;
                console.log('State addKendaraan sebelum push:', state.createKendaraan); // Tambahkan log ini
                state.createKendaraan.push(action.payload);
            })
            .addCase(addKendaraan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to create kendaraan';
                console.error('Error creating kendaraan:', action.error.message);
            })
            .addCase(fetchDistributorDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDistributorDetail.fulfilled, (state, action: PayloadAction<DistributorDetail>) => {
                state.loading = false;
                state.dataDistributor = action.payload;
            })
            .addCase(fetchDistributorDetail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch kendaraan';
                console.error('Error fetching kendaraan:', action.error.message);
            })
            .addCase(fetchTarifBerat.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTarifBerat.fulfilled, (state, action: PayloadAction<TarifBerat[]>) => {
                state.loading = false;
                state.tarifBerat = action.payload;
            })
            .addCase(fetchTarifBerat.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch tarif berat';
                console.error('Error fetching tarif berat:', action.error.message);
            })
            .addCase(fetchMerkKendaraan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMerkKendaraan.fulfilled, (state, action: PayloadAction<MerkKendaraan[]>) => {
                state.loading = false;
                state.merkKendaraan = action.payload;
            })
            .addCase(fetchMerkKendaraan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch jenis kendaraan';
                console.error('Error fetching jenis kendaraan:', action.error.message);
            })
            .addCase(fetchTypeKendaraan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTypeKendaraan.fulfilled, (state, action: PayloadAction<TypeKendaraan[]>) => {
                state.loading = false;
                state.typeKendaraan = action.payload;
            })
            .addCase(fetchTypeKendaraan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch type kendaraan';
                console.error('Error fetching type kendaraan:', action.error.message);
            })
            .addCase(fetchJenisKendaraan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchJenisKendaraan.fulfilled, (state, action: PayloadAction<JenisKendaraan[]>) => {
                state.loading = false;
                state.jenisKendaraan = action.payload;
            })
            .addCase(fetchJenisKendaraan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch jenis kendaraan';
                console.error('Error fetching jenis kendaraan:', action.error.message);
            })
            .addCase(fetchJenisLayanan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchJenisLayanan.fulfilled, (state, action: PayloadAction<JenisLayanan[]>) => {
                state.loading = false;
                state.jenisLayanan = action.payload;
            })
            .addCase(fetchJenisLayanan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch jenis layanan';
                console.error('Error fetching jenis layanan:', action.error.message);
            })
            .addCase(fetchTarifKendaraan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTarifKendaraan.fulfilled, (state, action: PayloadAction<TarifKendaraan[]>) => {
                state.loading = false;
                state.tarifKendaraan = action.payload;
            })
            .addCase(fetchTarifKendaraan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch tarif';
                console.error('Error fetching tarif:', action.error.message);
            })
            .addCase(updatekendaraan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updatekendaraan.fulfilled, (state, action: PayloadAction<KendaraanList>) => {
                state.loading = false;
                const index = state.dataKendaraan.findIndex((pengemudi) => pengemudi._id === action.payload._id);
                if (index !== -1) {
                    state.dataKendaraan[index] = action.payload;
                }
            })
            .addCase(updatekendaraan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to update kendaraan';
            });
    },
});

export const ManajemenKendaraanService = { action: ManajemenKendaraanSlice.actions, fetchKendaraanPerusahaan };
const manajemenKendaraanReducer = ManajemenKendaraanSlice.reducer;
export default manajemenKendaraanReducer;
