import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface userData {
    id: string,
    role: string
}
const initialState: userData = {
    id: "",
    role: ""
};

const RegisterUserReducer = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // onUpdateFieldForm: (state, action: PayloadAction<{ name: keyof UserData; value: string | number }>) => {
        //     const { name, value } = action.payload;
        //     (state[name] as string | number) = value;
        // },
        setIdUser: (state, action: PayloadAction<string>) => {
            state.id = action.payload;
        },
        setRole: (state, action: PayloadAction<string>) => {
            state.role = action.payload;
        },
        resetUserData: () => initialState,
    },
});

export const {
    // onUpdateFieldForm,
    setIdUser,
    setRole,
    resetUserData,
} = RegisterUserReducer.actions;

export default RegisterUserReducer.reducer;

