export const basket = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1165_4900)">
            <path
                d="M22.713 4.077C22.4317 3.73944 22.0796 3.46795 21.6815 3.28182C21.2835 3.09568 20.8494 2.99946 20.41 3H4.242L4.2 2.649C4.11405 1.91942 3.76338 1.24673 3.21449 0.758478C2.6656 0.270223 1.95663 0.000341793 1.222 0L1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2H1.222C1.46693 2.00003 1.70334 2.08996 1.88637 2.25272C2.06941 2.41547 2.18634 2.63975 2.215 2.883L3.591 14.583C3.73385 15.7998 4.31848 16.9218 5.23391 17.736C6.14934 18.5502 7.33185 19 8.557 19H19C19.2652 19 19.5196 18.8946 19.7071 18.7071C19.8946 18.5196 20 18.2652 20 18C20 17.7348 19.8946 17.4804 19.7071 17.2929C19.5196 17.1054 19.2652 17 19 17H8.557C7.93806 16.9983 7.3348 16.8051 6.82994 16.4471C6.32507 16.089 5.94331 15.5835 5.737 15H17.657C18.8293 15.0001 19.9643 14.5882 20.8638 13.8364C21.7633 13.0846 22.37 12.0407 22.578 10.887L23.363 6.533C23.4414 6.10101 23.4237 5.65707 23.3114 5.23264C23.1991 4.80821 22.9948 4.41368 22.713 4.077ZM21.4 6.178L20.614 10.532C20.4891 11.225 20.1245 11.852 19.5839 12.3032C19.0433 12.7544 18.3612 13.0011 17.657 13H5.419L4.478 5H20.41C20.5569 4.99912 20.7022 5.03062 20.8355 5.09226C20.9689 5.15389 21.087 5.24415 21.1815 5.35661C21.276 5.46907 21.3446 5.60097 21.3824 5.74294C21.4201 5.8849 21.4262 6.03344 21.4 6.178Z"
                fill="#6D7588"
            />
            <path
                d="M7 24C8.10457 24 9 23.1046 9 22C9 20.8954 8.10457 20 7 20C5.89543 20 5 20.8954 5 22C5 23.1046 5.89543 24 7 24Z"
                fill="#6D7588"
            />
            <path
                d="M17 24C18.1046 24 19 23.1046 19 22C19 20.8954 18.1046 20 17 20C15.8954 20 15 20.8954 15 22C15 23.1046 15.8954 24 17 24Z"
                fill="#6D7588"
            />
        </g>
        <defs>
            <clipPath id="clip0_1165_4900">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
export const IconToko = () => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.1908 16H10.8574C10.6501 16 10.4548 15.9033 10.3288 15.7393C10.2028 15.5747 10.1594 15.3613 10.2134 15.1607C10.6074 13.692 11.9688 12.6667 13.5241 12.6667C15.0794 12.6667 16.4408 13.692 16.8348 15.1607C16.8888 15.3613 16.8461 15.5747 16.7194 15.7393C16.5941 15.904 16.3981 16 16.1908 16ZM13.5241 12C12.6048 12 11.8574 11.2527 11.8574 10.3333C11.8574 9.414 12.6048 8.66667 13.5241 8.66667C14.4434 8.66667 15.1908 9.414 15.1908 10.3333C15.1908 11.2527 14.4434 12 13.5241 12ZM16.8321 5.15L16.0508 2.41733C15.6441 0.994 14.3254 0 12.8454 0H12.1908V2.66667C12.1908 3.03467 11.8928 3.33333 11.5241 3.33333C11.1554 3.33333 10.8574 3.03467 10.8574 2.66667V0H6.85742V2.66667C6.85742 3.03467 6.55875 3.33333 6.19075 3.33333C5.82275 3.33333 5.52409 3.03467 5.52409 2.66667V0H4.86875C3.38876 0 2.07076 0.994 1.66342 2.41733L0.883422 5.15C0.866755 5.20933 0.857422 5.27133 0.857422 5.33333C0.857422 6.804 2.05342 8 3.52409 8H4.19075C4.99076 8 5.70142 7.63867 6.19075 7.07867C6.68009 7.63867 7.39075 8 8.19075 8H9.52409C10.3248 8 11.0348 7.63867 11.5241 7.07867C12.0134 7.63867 12.7234 8 13.5241 8H14.1908C15.6614 8 16.8574 6.804 16.8574 5.33333C16.8574 5.27133 16.8488 5.20933 16.8321 5.15ZM8.92609 14.8133C9.24675 13.6173 10.0321 12.6427 11.0541 12.0293C10.7214 11.546 10.5241 10.9627 10.5241 10.3327C10.5241 9.898 10.6214 9.48733 10.7881 9.11467C10.3834 9.25267 9.95875 9.33267 9.52409 9.33267H8.19075C7.48275 9.33267 6.79409 9.14067 6.19075 8.78667C5.58742 9.14067 4.89876 9.33267 4.19075 9.33267H3.52409C2.79542 9.33267 2.11342 9.13333 1.52409 8.79133V13.3327C1.52409 14.8033 2.72009 15.9993 4.19075 15.9993H8.98542C8.85142 15.6207 8.82009 15.2073 8.92609 14.8133Z"
            fill="#007F73"
        />
    </svg>
);
export const pinIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_376_259)">
            <path
                d="M9.99935 5C9.34008 5 8.69561 5.1955 8.14745 5.56177C7.59929 5.92804 7.17204 6.44863 6.91975 7.05772C6.66746 7.66681 6.60145 8.33703 6.73007 8.98363C6.85868 9.63024 7.17615 10.2242 7.64233 10.6904C8.1085 11.1565 8.70245 11.474 9.34905 11.6026C9.99565 11.7312 10.6659 11.6652 11.275 11.4129C11.884 11.1606 12.4046 10.7334 12.7709 10.1852C13.1372 9.63707 13.3327 8.9926 13.3327 8.33333C13.3327 7.44928 12.9815 6.60143 12.3564 5.97631C11.7313 5.35119 10.8834 5 9.99935 5ZM9.99935 10C9.66972 10 9.34748 9.90225 9.0734 9.71912C8.79932 9.53598 8.5857 9.27568 8.45955 8.97114C8.33341 8.6666 8.3004 8.33148 8.36471 8.00818C8.42902 7.68488 8.58775 7.38791 8.82084 7.15482C9.05393 6.92173 9.3509 6.763 9.6742 6.69869C9.9975 6.63438 10.3326 6.66739 10.6372 6.79353C10.9417 6.91968 11.202 7.1333 11.3851 7.40738C11.5683 7.68147 11.666 8.0037 11.666 8.33333C11.666 8.77536 11.4904 9.19928 11.1779 9.51184C10.8653 9.82441 10.4414 10 9.99935 10Z"
                fill="#222831"
            />
            <path
                d="M10.0003 20.0002C9.29855 20.0038 8.60619 19.8392 7.98115 19.5202C7.35611 19.2013 6.81659 18.7372 6.40776 18.1669C3.23193 13.786 1.62109 10.4927 1.62109 8.3777C1.62109 6.15541 2.5039 4.02414 4.07529 2.45274C5.64669 0.881338 7.77797 -0.00146484 10.0003 -0.00146484C12.2226 -0.00146484 14.3538 0.881338 15.9252 2.45274C17.4966 4.02414 18.3794 6.15541 18.3794 8.3777C18.3794 10.4927 16.7686 13.786 13.5928 18.1669C13.1839 18.7372 12.6444 19.2013 12.0194 19.5202C11.3943 19.8392 10.702 20.0038 10.0003 20.0002ZM10.0003 1.8177C8.26061 1.81969 6.59277 2.51164 5.36265 3.74176C4.13253 4.97188 3.44058 6.63972 3.43859 8.37937C3.43859 10.0544 5.01609 13.1519 7.87943 17.101C8.12251 17.4359 8.4414 17.7084 8.81002 17.8963C9.17864 18.0842 9.58651 18.1821 10.0003 18.1821C10.414 18.1821 10.8219 18.0842 11.1905 17.8963C11.5591 17.7084 11.878 17.4359 12.1211 17.101C14.9844 13.1519 16.5619 10.0544 16.5619 8.37937C16.5599 6.63972 15.868 4.97188 14.6379 3.74176C13.4077 2.51164 11.7399 1.81969 10.0003 1.8177Z"
                fill="#222831"
            />
        </g>
        <defs>
            <clipPath id="clip0_376_259">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
export const notification = () => (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.5555 13.6622L20.6555 6.82618C20.0986 4.82351 18.8882 3.06447 17.2168 1.82865C15.5454 0.592832 13.5089 -0.0488349 11.4309 0.00564524C9.35299 0.0601253 7.35288 0.807625 5.74853 2.12933C4.14419 3.45103 3.02767 5.27108 2.57647 7.30018L1.10547 13.9152C0.942986 14.6461 0.946742 15.4042 1.11646 16.1335C1.28618 16.8628 1.61753 17.5446 2.08605 18.1287C2.55457 18.7128 3.14829 19.1842 3.82338 19.5081C4.49847 19.832 5.23769 20.0002 5.98647 20.0002H7.10047C7.32999 21.1305 7.9432 22.1467 8.83621 22.8766C9.72922 23.6065 10.8471 24.0052 12.0005 24.0052C13.1538 24.0052 14.2717 23.6065 15.1647 22.8766C16.0577 22.1467 16.671 21.1305 16.9005 20.0002H17.7385C18.5093 20.0002 19.2697 19.8221 19.9602 19.4796C20.6508 19.1372 21.2528 18.6397 21.7193 18.026C22.1858 17.4124 22.504 16.6992 22.6493 15.9422C22.7945 15.1852 22.7617 14.4049 22.5555 13.6622ZM12.0005 22.0002C11.3822 21.9976 10.7798 21.8041 10.2758 21.4461C9.7717 21.0881 9.39055 20.5831 9.18447 20.0002H14.8165C14.6104 20.5831 14.2292 21.0881 13.7252 21.4461C13.2211 21.8041 12.6187 21.9976 12.0005 22.0002ZM20.1265 16.8152C19.8478 17.1848 19.4868 17.4844 19.0721 17.6901C18.6574 17.8958 18.2004 18.002 17.7375 18.0002H5.98647C5.53725 18.0001 5.09379 17.8991 4.68881 17.7048C4.28383 17.5104 3.92768 17.2275 3.64663 16.8771C3.36559 16.5266 3.16683 16.1176 3.06503 15.68C2.96323 15.2425 2.96099 14.7877 3.05847 14.3492L4.52847 7.73318C4.88282 6.13939 5.75978 4.70978 7.01993 3.67163C8.28008 2.63347 9.85109 2.04635 11.4832 2.00359C13.1154 1.96083 14.715 2.4649 16.0278 3.43565C17.3406 4.4064 18.2912 5.78813 18.7285 7.36118L20.6285 14.1972C20.754 14.6426 20.774 15.1111 20.6869 15.5655C20.5997 16.02 20.4079 16.4478 20.1265 16.8152Z"
            fill="#6D7588"
        />
    </svg>
);
export const mailIcon = () => (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19 0H5C3.67441 0.00158786 2.40356 0.528882 1.46622 1.46622C0.528882 2.40356 0.00158786 3.67441 0 5L0 17C0.00158786 18.3256 0.528882 19.5964 1.46622 20.5338C2.40356 21.4711 3.67441 21.9984 5 22H19C20.3256 21.9984 21.5964 21.4711 22.5338 20.5338C23.4711 19.5964 23.9984 18.3256 24 17V5C23.9984 3.67441 23.4711 2.40356 22.5338 1.46622C21.5964 0.528882 20.3256 0.00158786 19 0ZM5 2H19C19.5988 2.00118 20.1835 2.18151 20.679 2.5178C21.1744 2.85409 21.5579 3.33095 21.78 3.887L14.122 11.546C13.5584 12.1073 12.7954 12.4225 12 12.4225C11.2046 12.4225 10.4416 12.1073 9.878 11.546L2.22 3.887C2.44215 3.33095 2.82561 2.85409 3.32105 2.5178C3.81648 2.18151 4.40121 2.00118 5 2ZM19 20H5C4.20435 20 3.44129 19.6839 2.87868 19.1213C2.31607 18.5587 2 17.7956 2 17V6.5L8.464 12.96C9.40263 13.8963 10.6743 14.422 12 14.422C13.3257 14.422 14.5974 13.8963 15.536 12.96L22 6.5V17C22 17.7956 21.6839 18.5587 21.1213 19.1213C20.5587 19.6839 19.7956 20 19 20Z"
            fill="#6D7588"
        />
    </svg>
);
export const searchIcon = () => (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.678 16.6067L9.96296 10.8907C7.42063 12.6981 3.91739 12.256 1.90383 9.87358C-0.109735 7.49116 0.038116 3.96324 2.24396 1.75765C4.44922 -0.4489 7.97748 -0.59739 10.3603 1.41607C12.7432 3.42953 13.1856 6.9331 11.378 9.47565L17.093 15.1917L15.679 16.6057L15.678 16.6067ZM6.48596 1.99964C4.58966 1.99921 2.95365 3.33033 2.56843 5.18709C2.18321 7.04385 3.15467 8.91593 4.89463 9.66989C6.6346 10.4239 8.66486 9.85248 9.7562 8.30171C10.8475 6.75093 10.7 4.64697 9.40296 3.26365L10.008 3.86365L9.32596 3.18365L9.31396 3.17165C8.56574 2.41883 7.54735 1.99678 6.48596 1.99964Z"
            fill="#ADB5BD"
        />
    </svg>
);
export const IconWallet = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1165_4922)">
            <path
                d="M21 6H5C4.141 6 3.328 5.628 2.765 5.001C3.315 4.387 4.114 4 5 4H23C23.553 4 24 3.552 24 3C24 2.448 23.553 2 23 2H5C2.239 2 0 4.239 0 7V17C0 19.761 2.239 22 5 22H21C22.657 22 24 20.657 24 19V9C24 7.343 22.657 6 21 6ZM22 19C22 19.551 21.552 20 21 20H5C3.346 20 2 18.654 2 17V6.998C2.854 7.637 3.904 8 5 8H21C21.552 8 22 8.449 22 9V19ZM20 14C20 14.552 19.552 15 19 15C18.448 15 18 14.552 18 14C18 13.448 18.448 13 19 13C19.552 13 20 13.448 20 14Z"
                fill="#6D7588"
            />
        </g>
        <defs>
            <clipPath id="clip0_1165_4922">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
export function IconNotificationWithLenght(count: number) {
    return (
        <div className="relative inline-block cursor-pointer">
            {notification()}
            {count > 0 && (
                <span className="absolute top-0 right-0 iitems-center justify-center px-1 py-1 text-[8px] font-normal text-white bg-red-600 rounded-full">
                    {count}
                </span>
            )}
        </div>
    );
};
export function IconBasketWithLenght(count: number) {
    return (
        <div className="relative inline-block cursor-pointer">
            {basket()}
            {count > 0 && (
                <span className="absolute top-0 right-0 iitems-center justify-center px-1 py-1 text-[8px] font-normal text-white bg-red-600 rounded-full">
                    {count}
                </span>
            )}
        </div>
    );
};


export const NotificationWthNumber = (notifLength: number) => {
    <div className='cursor-pointer relative'>
    <div className='w-5 h-5 rounded-full border-2 border-white bg-[#E52427] flex items-center justify-center absolute -top-1 -right-2'>
        <p className='text-white text-[10px] font-normal text-center'>{notifLength}</p>
    </div>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_352_11419)">
            <path
                d="M8.28125 21C8.66891 21.8914 9.30848 22.6502 10.1214 23.1831C10.9343 23.716 11.8852 23.9999 12.8573 23.9999C13.8293 23.9999 14.7802 23.716 15.5931 23.1831C16.406 22.6502 17.0456 21.8914 17.4333 21H8.28125Z"
                fill="#007F73"
            />
            <path
                d="M23.2492 12.5487L21.5132 6.82569C20.9573 4.82403 19.7486 3.06546 18.0791 1.82919C16.4096 0.592914 14.3749 -0.0501967 12.2981 0.00196372C10.2214 0.0541241 8.22156 0.798566 6.6162 2.11709C5.01084 3.43562 3.89197 5.25265 3.43723 7.27969L2.08923 12.8167C1.91006 13.5524 1.90036 14.3193 2.06084 15.0593C2.22132 15.7994 2.54781 16.4933 3.01565 17.0888C3.4835 17.6842 4.08048 18.1656 4.76155 18.4966C5.44261 18.8277 6.18998 18.9997 6.94723 18.9997H18.4642C19.245 18.9997 20.0149 18.8169 20.7122 18.4659C21.4096 18.1149 22.0151 17.6055 22.4803 16.9784C22.9454 16.3514 23.2572 15.6241 23.3907 14.8549C23.5243 14.0856 23.4758 13.2958 23.2492 12.5487Z"
                fill="#007F73"
            />
        </g>
        <defs>
            <clipPath id="clip0_352_11419">
                <rect width="24" height="24" fill="white" transform="translate(0.857422)" />
            </clipPath>
        </defs>
    </svg>
</div>
}

