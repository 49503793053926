import React from 'react'
import Navbar from '../../Components/navbar'
import Slide from '../../Components/slide'
import Copyright from '../../Components/Copyright'

export default function HomeTest() {
  return (
    <main>
    <Navbar />
    <div className='pt-[160px] max-w-[1440px]'>
        <Slide/>
    </div>
    <Copyright />
</main>
  )
}
