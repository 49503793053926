import React from 'react';

interface NotificationWthNumberProps {
  notifLength: number;
}

const NotificationWthNumber: React.FC<NotificationWthNumberProps> = ({ notifLength }) => {
  return (
    <div className='cursor-pointer relative'>
      <div className='w-5 h-5 rounded-full border-2 border-white bg-[#E52427] flex items-center justify-center absolute -top-1 -right-2'>
        <p className='text-white text-[10px] font-normal text-center'>{notifLength}</p>
      </div>
      <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.5555 13.6622L20.6555 6.82618C20.0986 4.82351 18.8882 3.06447 17.2168 1.82865C15.5454 0.592832 13.5089 -0.0488349 11.4309 0.00564524C9.35299 0.0601253 7.35288 0.807625 5.74853 2.12933C4.14419 3.45103 3.02767 5.27108 2.57647 7.30018L1.10547 13.9152C0.942986 14.6461 0.946742 15.4042 1.11646 16.1335C1.28618 16.8628 1.61753 17.5446 2.08605 18.1287C2.55457 18.7128 3.14829 19.1842 3.82338 19.5081C4.49847 19.832 5.23769 20.0002 5.98647 20.0002H7.10047C7.32999 21.1305 7.9432 22.1467 8.83621 22.8766C9.72922 23.6065 10.8471 24.0052 12.0005 24.0052C13.1538 24.0052 14.2717 23.6065 15.1647 22.8766C16.0577 22.1467 16.671 21.1305 16.9005 20.0002H17.7385C18.5093 20.0002 19.2697 19.8221 19.9602 19.4796C20.6508 19.1372 21.2528 18.6397 21.7193 18.026C22.1858 17.4124 22.504 16.6992 22.6493 15.9422C22.7945 15.1852 22.7617 14.4049 22.5555 13.6622ZM12.0005 22.0002C11.3822 21.9976 10.7798 21.8041 10.2758 21.4461C9.7717 21.0881 9.39055 20.5831 9.18447 20.0002H14.8165C14.6104 20.5831 14.2292 21.0881 13.7252 21.4461C13.2211 21.8041 12.6187 21.9976 12.0005 22.0002ZM20.1265 16.8152C19.8478 17.1848 19.4868 17.4844 19.0721 17.6901C18.6574 17.8958 18.2004 18.002 17.7375 18.0002H5.98647C5.53725 18.0001 5.09379 17.8991 4.68881 17.7048C4.28383 17.5104 3.92768 17.2275 3.64663 16.8771C3.36559 16.5266 3.16683 16.1176 3.06503 15.68C2.96323 15.2425 2.96099 14.7877 3.05847 14.3492L4.52847 7.73318C4.88282 6.13939 5.75978 4.70978 7.01993 3.67163C8.28008 2.63347 9.85109 2.04635 11.4832 2.00359C13.1154 1.96083 14.715 2.4649 16.0278 3.43565C17.3406 4.4064 18.2912 5.78813 18.7285 7.36118L20.6285 14.1972C20.754 14.6426 20.774 15.1111 20.6869 15.5655C20.5997 16.02 20.4079 16.4478 20.1265 16.8152Z"
            fill="#6D7588"
        />
    </svg>
    </div>
  );
}

export default NotificationWthNumber;
