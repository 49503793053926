import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { PerusahaanVendorState } from '../../interfaces';

const initialState: PerusahaanVendorState = {
    isRoleIndividu: false,
    jenisPerusahaan: '',
    addressContainer: '',
    isChecked: false,
    isOpenModalFirst: false,
    namaBadanUsahaPT: '',
    noAktaPerusahaanPT: '',
    noPhoneKantorPT: '',
    nomorNpwpPT: '',
    namaProvinsiPT: '',
    namaKotaPT: '',
    namaKecamatanPT: '',
    namaKelurahanPT: '',
    selectedIdProvincePT: '',
    selectedIdCityPT: '',
    selectedIdSubdistrictPT: '',
    selectedIdUrVillagePT: '',
    longtitudePT: 0,
    langtitudePT: 0,
    detailAlamatSatuPT: 'JL. BIKINI BOTTOM',
    detailAlamatDuaPT: '',
    kodePosPT: '',
    uploadFileNPWP: [],
    uploadPreviewNPWP: [],
    datasFileSIUP: [],
    datasPreviewSIUP: [],
    showingMaps: false,
    //---> Data Gudang
    isOpenModalSecond: false,
    namaTokoGudang: '',
    namaProvinsiTokoGudang: '',
    namaKotaTokoGudang: '',
    namaKecamatanTokoGudang: '',
    namaKelurahanTokoGudang: '',
    selectedIdProvinceGudang: '',
    selectedIdCityGudang: '',
    selectedIdSubdistrictGudang: '',
    selectedIdUrVillageGudang: '',
    longtitudeTokoGudang: 0,
    langtitudeTokoGudang: 0,
    detailAlamatTokoSatuGudang: 'JL. RAWA BEBEK',
    detailAlamatTokoDuaGudang: '',
    kodePosTokoGudang: '',
    isShowCordinateWarehouse: false,
    //---> Data PJ
    isOpenModalThird: false,
    namaLengkapPJ_PT: '',
    noNikPJ_PT: '',
    namaProvinsiPJ_PT: '',
    namaKotaPJ_PT: '',
    namaKecamatanPJ_PT: '',
    namaKelurahanPJ_PT: '',
    selectedIdProvincePJ: '',
    selectedIdCityPJ: '',
    selectedIdSubdistrictPJ: '',
    selectedIdUrVillagePJ: '',
    longtitudePJ_PT: 0,
    langtitudePJ_PT: 0,
    detailAlamatPJSatu_PT: '',
    detailAlamatPJDua_PT: '',
    kodePosPJ_PT: '',
    datasFileKTP_PJ: [],
    datasPreviewKTP_PJ: [],
    uploadFileNpwpPJ: [],
    uploadPreviewNpwpPJ: [],
    jabatanPJ: '',
    detailID: '',
    noNpwpPJ: '',
    isShowCordinatePJ: false,
};

const PerusahaanVendorSlice = createSlice({
    name: 'PerusahaanlVendor',
    initialState,
    reducers: {
        onChangeInputFields: (
            state,
            action: PayloadAction<{ name: keyof PerusahaanVendorState; value: string | number }>,
        ) => {
            const { name, value } = action.payload;
            (state[name] as string | number) = value;
        },
        getNamaBadanUsaha: (state, action: PayloadAction<string>) => {
            state.namaBadanUsahaPT = action.payload;
        },
        getJenisPerusahaan: (state, action: PayloadAction<string>) => {
            state.jenisPerusahaan = action.payload;
        },
        checkIsIndividu: (state, action: PayloadAction<boolean>) => {
            state.isRoleIndividu = action.payload;
        },
        setLatitudePT: (state, action: PayloadAction<number>) => {
            state.langtitudePT = action.payload;
        },
        setLongtitudePT: (state, action: PayloadAction<number>) => {
            state.longtitudePT = action.payload;
        },
        onResetLatLangDataCompany: (state) => {
            state.langtitudePT = initialState.langtitudePT;
            state.longtitudePT = initialState.longtitudePT;
        },
        setAddressContainer: (state, action: PayloadAction<string>) => {
            state.addressContainer = action.payload;
        },
        setToggleCheckboxes: (state) => {
            state.isChecked = !state.isChecked;
        },
        setCheckboxFalse: (state) => {
            state.isChecked = false;
        },
        setDetailId: (state, action: PayloadAction<string>) => {
            state.detailID = action.payload;
        },
        setDisplayCordinatePT: (state, action: PayloadAction<boolean>) => {
            state.showingMaps = action.payload;
        },
        setDisplayCordinateWarehouse: (state, action: PayloadAction<boolean>) => {
            state.isShowCordinateWarehouse = action.payload;
        },
        setDisplayCordinatePJ: (state, action: PayloadAction<boolean>) => {
            state.isShowCordinatePJ = action.payload;
        },
        setDataNPWP: (state, action: PayloadAction<File[]>) => {
            state.uploadFileNPWP = action.payload;
        },
        setDataPreviewNPWP: (state, action: PayloadAction<string[]>) => {
            state.uploadPreviewNPWP = action.payload;
        },
        setDataSIUP: (state, action: PayloadAction<File[]>) => {
            state.datasFileSIUP = action.payload;
        },
        setDataPreviewSIUP: (state, action: PayloadAction<string[]>) => {
            state.datasPreviewSIUP = action.payload;
        },
        setDisplayModal: (state, action: PayloadAction<boolean>) => {
            state.isOpenModalFirst = action.payload;
        },
        getNameProvinceDataPT: (state, action: PayloadAction<string>) => {
            state.namaProvinsiPT = action.payload;
        },
        getNameCityDataPT: (state, action: PayloadAction<string>) => {
            state.namaKotaPT = action.payload;
        },
        getNameSubdistrictDataPT: (state, action: PayloadAction<string>) => {
            state.namaKecamatanPT = action.payload;
        },
        getNameUrVillageDataPT: (state, action: PayloadAction<string>) => {
            state.namaKelurahanPT = action.payload;
        },
        onResetDataPT: (state) => {
            state.namaProvinsiPT = initialState.namaProvinsiPT;
            state.namaKotaPT = initialState.namaKotaPT;
            state.namaKecamatanPT = initialState.namaKecamatanPT;
            state.namaKelurahanPT = initialState.namaKelurahanPT;
        },
        getIdProvinceDataPT: (state, action: PayloadAction<string>) => {
            state.selectedIdProvincePT = action.payload;
        },
        getIdCitiesDataPT: (state, action: PayloadAction<string>) => {
            state.selectedIdCityPT = action.payload;
        },
        getIdSubdistrictDataPT: (state, action: PayloadAction<string>) => {
            state.selectedIdSubdistrictPT = action.payload;
        },
        getIdUrVillageDataPT: (state, action: PayloadAction<string>) => {
            state.selectedIdUrVillagePT = action.payload;
        },
        // -------------------> DATA GUDANG <---------------------------//
        setDisplayModalSecond: (state, action: PayloadAction<boolean>) => {
            state.isOpenModalSecond = action.payload;
        },
        setLatitudeWarehouse: (state, action: PayloadAction<number>) => {
            state.langtitudeTokoGudang = action.payload;
        },
        setLongtitudeWarehouse: (state, action: PayloadAction<number>) => {
            state.longtitudeTokoGudang = action.payload;
        },
        onResetLatLangDataWarehouse: (state) => {
            state.langtitudeTokoGudang = initialState.langtitudeTokoGudang;
            state.longtitudeTokoGudang = initialState.longtitudeTokoGudang;
        },
        getNameProvinceDataGudang: (state, action: PayloadAction<string>) => {
            state.namaProvinsiTokoGudang = action.payload;
        },
        getNameCityDataGudang: (state, action: PayloadAction<string>) => {
            state.namaKotaTokoGudang = action.payload;
        },
        getNameSubdistrictDataGudang: (state, action: PayloadAction<string>) => {
            state.namaKecamatanTokoGudang = action.payload;
        },
        getNameUrVillageDataGudang: (state, action: PayloadAction<string>) => {
            state.namaKelurahanTokoGudang = action.payload;
        },
        onResetDataGudang: (state) => {
            state.namaProvinsiTokoGudang = initialState.namaProvinsiTokoGudang;
            state.namaKotaTokoGudang = initialState.namaKotaTokoGudang;
            state.namaKecamatanTokoGudang = initialState.namaKecamatanTokoGudang;
            state.namaKelurahanTokoGudang = initialState.namaKelurahanTokoGudang;
        },
        getIdProvinceDataGudang: (state, action: PayloadAction<string>) => {
            state.selectedIdProvinceGudang = action.payload;
        },
        getIdCitiesDataGudang: (state, action: PayloadAction<string>) => {
            state.selectedIdCityGudang = action.payload;
        },
        getIdSubdistrictDataGudang: (state, action: PayloadAction<string>) => {
            state.selectedIdSubdistrictGudang = action.payload;
        },
        getIdUrVillageDataGudang: (state, action: PayloadAction<string>) => {
            state.selectedIdUrVillageGudang = action.payload;
        },
        // -------------------> DATA PJ <---------------------------//
        setLatitudePJ: (state, action: PayloadAction<number>) => {
            state.langtitudePJ_PT = action.payload;
        },
        setLongtitudePJ: (state, action: PayloadAction<number>) => {
            state.longtitudePJ_PT = action.payload;
        },
        onResetLatLangDataPJ: (state) => {
            state.langtitudePJ_PT = initialState.langtitudePJ_PT;
            state.longtitudePJ_PT = initialState.longtitudePJ_PT;
        },
        setDataKTP: (state, action: PayloadAction<File[]>) => {
            state.datasFileKTP_PJ = action.payload;
        },
        setDataPreviewKTP: (state, action: PayloadAction<string[]>) => {
            state.datasPreviewKTP_PJ = action.payload;
        },
        setDataNpwpPJ: (state, action: PayloadAction<File[]>) => {
            state.uploadFileNpwpPJ = action.payload;
        },
        setDataPreviewNpwpPJ: (state, action: PayloadAction<string[]>) => {
            state.uploadPreviewNpwpPJ = action.payload;
        },
        setDisplayModalThird: (state, action: PayloadAction<boolean>) => {
            state.isOpenModalThird = action.payload;
        },
        getNameProvinceDataPJ: (state, action: PayloadAction<string>) => {
            state.namaProvinsiPJ_PT = action.payload;
        },
        getNameCityDataPJ: (state, action: PayloadAction<string>) => {
            state.namaKotaPJ_PT = action.payload;
        },
        getNameSubdistrictDataPJ: (state, action: PayloadAction<string>) => {
            state.namaKecamatanPJ_PT = action.payload;
        },
        getNameUrVillageDataPJ: (state, action: PayloadAction<string>) => {
            state.namaKelurahanPJ_PT = action.payload;
        },
        onResetDataPJ: (state) => {
            state.namaProvinsiPJ_PT = initialState.namaProvinsiPJ_PT;
            state.namaKotaPJ_PT = initialState.namaKotaPJ_PT;
            state.namaKecamatanPJ_PT = initialState.namaKecamatanPJ_PT;
            state.namaKelurahanPJ_PT = initialState.namaKelurahanPJ_PT;
        },
        getIdProvinceDataPJ: (state, action: PayloadAction<string>) => {
            state.selectedIdProvincePJ = action.payload;
        },
        getIdCitiesDataPJ: (state, action: PayloadAction<string>) => {
            state.selectedIdCityPJ = action.payload;
        },
        getIdSubdistrictDataPJ: (state, action: PayloadAction<string>) => {
            state.selectedIdSubdistrictPJ = action.payload;
        },
        getIdUrVillageDataPJ: (state, action: PayloadAction<string>) => {
            state.selectedIdUrVillagePJ = action.payload;
        },
    },
});

export const PerusahaanVendorService = { action: PerusahaanVendorSlice.actions };
const PerusahaanVendorReducer = PerusahaanVendorSlice.reducer;
export default PerusahaanVendorReducer;

export const isKeyofFormValuePerusahaanVendor = (key: any): key is keyof PerusahaanVendorState => {
    return [
        'namaBadanUsahaPT',
        'noAktaPerusahaanPT',
        'noPhoneKantorPT',
        'namaProvinsiPT',
        'namaKotaPT',
        'namaKecamatanPT',
        'namaKelurahanPT',
        'detailAlamatDuaPT',
        'kodePosPT',
        'namaTokoGudang',
        'detailAlamatTokoDuaGudang',
        'kodePosTokoGudang',
        'namaLengkapPJ_PT',
        'noNikPJ_PT',
        'detailAlamatPJDua_PT',
        'kodePosPJ_PT',
        'nomorNpwpPT',
        'jabatanPJ',
        'detailID',
        'noNpwpPJ',
    ].includes(key);
};
