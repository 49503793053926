import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface userInfo {
    role: string;
    token: string
    deviceId: string
}
const initialState: userInfo = {
    role: "",
    token: "",
    deviceId: ''
  };

  const UserInfo = createSlice({
    name: 'UserInfo',
    initialState,
    reducers:{
        setRole(state, action: PayloadAction<string>) {
            state.role = action.payload;
        },
        
        setToken(state, action: PayloadAction<string>) {
            state.token = action.payload;
        },
        setDeviceId(state, action: PayloadAction<string>) {
            console.log(action);
            
            state.deviceId = action.payload;
        },
        removeDeviceId(state) {
            state.deviceId = '';
        },
  }
  });

  export const { setToken, setRole, setDeviceId, removeDeviceId } = UserInfo.actions;
export default UserInfo.reducer;