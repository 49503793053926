import { useEffect, useState } from 'react'
import { ArrowLeftIcon } from '../../assets/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import DellAccountReasonInput from './section/reason'
import AnyReason from './section/anyReason'
import ConfirmDellAccountModals from './section/confirmDellAccount'

interface data {
    reason: string
    anyReason: string
}

export default function DeleteAccountReason() {
    const location = useLocation();
    const navigate = useNavigate();
    const [reason, setReason] = useState('')
    const [anyReason, setAnyReason] = useState('')
    const [confirmDell, setConfirmDell] = useState(false)
    const data: data = {
        reason, anyReason
    }
    useEffect(() => {
        if (!location.state || !location.state.data) {
            navigate('/delete-account');
        } else if (!location.state.data.dataOrder.passed ||
            !location.state.data.saldo.passed ||
            !location.state.data.user.passed
        ) {
            navigate('/delete-account');
        } else {
            console.log(location.state);
        }
    }, [location, navigate]);
    return (
        <div className='pt-[203px]'>
            <ConfirmDellAccountModals showModal={confirmDell} CloseModals={setConfirmDell} data={data} />
            <section className='w-[976px] mx-auto py-[24px] px-[16px] shadow-custom-shadow mb-[180px]'>
                <div
                    className='flex gap-[8px] items-center cursor-pointer'>
                    <div onClick={() => navigate(-1)}>
                        <ArrowLeftIcon />
                    </div>
                    <p className='text-[24px] text-[#25282B] font-bold'>Hapus Akun</p>
                </div>
                <div className='w-[360px] mt-[40px]'>
                    <p className='text-[16px] text-[#0F3F8A] font-medium'>Akun akan terhapus secara permanen</p>
                    <p className='text-[14px] text-[#4A4D4F] font-normal mt-[16px]'>Berikan alasan Anda mengapa ingin hapus akun, alasan kamu akan membantu MBG menjadi lebih baik.</p>
                </div>
                <DellAccountReasonInput
                    inputReason={setReason} />
                <AnyReason
                    inputAnyReason={setAnyReason} />
                <div className='mt-[80px] flex justify-center'>
                    <button
                        disabled={reason === ''}
                        onClick={() => setConfirmDell(true)}
                        className={reason === '' ? 'w-[320px] rounded-full text-[#BCBCBC] bg-[#E1E1E1] py-[8px]' : 'w-[320px] rounded-full text-[#fff] bg-[#1454B8] py-[8px]'}>
                        Konfirmasi
                    </button>
                </div>
            </section>
        </div>
    )
}
