import axios from 'axios';
import React from 'react'
import { useAppDispatch, useTypedSelector } from '../../../../../redux/store';
import { setId, setReason } from '../../../../../redux/store/reducers/Konsumen/auth/dellAccountReason';
import { useNavigate } from 'react-router-dom';
interface data {
  reason: string
  anyReason: string
}
interface confirmDellProps {
  showModal: boolean
  CloseModals: (value: boolean) => void;
  data: data
}

export default function ConfirmDellAccountModals({ showModal, CloseModals, data }: confirmDellProps) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const email = useTypedSelector((state) => state.DetailKonsumen.data?.userId.email.content)
  console.log(email);

  const handleDellAcoount = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/resend-otp`, {email})
    .then((res) => {
      dispatch(setId(res.data.id));
      navigate('/auth/otp?source=delete-account')
      dispatch(setReason(`${data.reason} - ${data.anyReason}`))
    }).catch((err) => {
      console.log(err);
    })
    console.log('resend otp jalan');
    console.log(`${data.reason} - ${data.anyReason}`);
    console.log(`${process.env.REACT_APP_API_URL}/resend-otp`);

  }
  return (
    <div className={
      showModal ? 'fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50' : 'hidden'
    }>
      <div className='flex flex-col justify-center items-center bg-white  w-[568px] p-[32px] rounded-[16px] shadow-lg'>
        <h6 className='text-[18px] text-[#25282B] font-bold'>Yakin ingin menghapus akun?</h6>
        <p className='text-[14px] text-[#818284] font-normal max-w-[318px] mt-[24px] text-center'>Setelah dihapus, akun ini tidak dapat dipulihkan. Apakah Anda yakin ingin melanjutkan?</p>
        <div className='flex gap-[24px] mt-[32px]'>
          <button
            onClick={() => CloseModals(false)}
            className='text-[18px] text-[#1454B8] border-[#1454B8] font-medium w-[160px] py-[8px] rounded-full border-[1px]'>Batalkan</button>
          <button
            onClick={() => handleDellAcoount()}
            className='text-[18px] text-[#fff] font-medium py-[8px] rounded-full border-[1px] w-[320px] bg-[#1454B8]'>Ya, Hapus</button>
        </div>
      </div>
    </div>
  )
}
