import React from 'react'
interface inputAnyReason{
  inputAnyReason: (value: string) => void;
}

export default function AnyReason({inputAnyReason}:inputAnyReason) {
  return (
    <div className='mt-[48px]'>
      <p className='text-[16px] text-[#25282B] font-medium'>Penjelasan lebih detail</p>
      <p className='text-[14px] text-[#4A4D4F] font-normal'>Beritahu alasan Anda lebih detail kenapa menghapus akun ?</p>
      <textarea
      onChange={(e)=>inputAnyReason(e.target.value)}
       className='w-[472px] h-[99px] bg-[#F7F7F7] py-[10px] px-[16px] mt-[16px] rounded-[12px]' placeholder='Mohon berikan penjelasan Anda lebih detail'></textarea>
    </div>
  )
}
