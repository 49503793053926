import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
    KendaraanList,
    PengemudiList,
    TarifKendaraan,
    JenisLayanan,
    TarifBerat,
    DistributorDetail,
    KendaraanIndividuRequest,
    JenisKendaraan,
    MerkKendaraan,
} from '../../../../../distributor/types';
import axiosInstance from '../../../../../api/axiosInstance';

interface KendaraanIndividuState {
    jenisKendaraan: JenisKendaraan[];
    merkKendaraan: MerkKendaraan[];
    dataKendaraan: KendaraanList[];
    addKendaraan: KendaraanIndividuRequest[];
    dataDistributor: DistributorDetail;
    dataPengemudi: PengemudiList[];
    dataTarifKendaraan: TarifKendaraan[];
    dataJenisLayanan: JenisLayanan[];
    dataTarifBerat: TarifBerat[];
    loading: boolean;
    error: string | null;
}

const initialState: KendaraanIndividuState = {
    merkKendaraan: [],
    jenisKendaraan: [],
    dataDistributor: {
        alamat_id: {
            pinAlamat: {
                lat: {
                    $numberDecimal: '',
                },
                long: {
                    $numberDecimal: '',
                },
            },
        },
        imageProfile: '',
        _id: '',
        jenisKelamin: '',
        nama_distributor: '',
        userId: {
            phone: {
                content: 0,
            },
            role: '',
        },
        individu: [],
        jenisPerusahaan: '',
        tanggal_lahir: '',
    },
    dataKendaraan: [],
    addKendaraan: [],
    dataPengemudi: [],
    dataTarifKendaraan: [],
    dataJenisLayanan: [],
    dataTarifBerat: [],
    loading: false,
    error: null,
};

// Data pengemudi
export const fetchPengemudiList = createAsyncThunk<PengemudiList[], void>(
    'PengemudiList/fetchPengemudiList',
    async () => {
        try {
            const response = await axiosInstance.get(`/pengemudi/list`);
            console.log('response data pengemudi', response.data.data);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching pengemudi list:', error);
            throw error;
        }
    },
);

// Data Create

export const fetchDistributorDetail = createAsyncThunk<DistributorDetail, void>(
    'DistributorDetail/fetchDistributorDetail',
    async () => {
        try {
            const response = await axiosInstance.get(`/distributor/detail`);
            console.log('response data distributor ', response.data.data);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching pengemudi list:', error);
            throw error;
        }
    },
);

// Data kendaraan
export const fetchKendaraanIndividu = createAsyncThunk<KendaraanList[], void>(
    'kendaraanIndividu/fetchKendaraanIndividu',
    async () => {
        const response = await axiosInstance.get(`/kendaraan-distributtor/list/`);
        console.log('response kendaraan', response.data.data);
        return response.data.data;
    },
);

//create data
export const addKendaraanDriver = createAsyncThunk<KendaraanIndividuRequest, FormData>(
    'kendaraanIndividu/addKendaraanDriver',
    async (formData) => {
        try {
            const response = await axiosInstance.post('/kendaraan-distributtor/create', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Response dari server:', response.data.data);
            return response.data.data;
        } catch (error: any) {
            console.error('Error saat mengunggah data:', error);
            throw new Error(error.response?.data?.message || 'Gagal mengunggah data');
        }
    },
);

// Data tarif kendaraan
export const fetchTarifKendaraan = createAsyncThunk<TarifKendaraan[], void>(
    'tarifKendaraan/fetchTarifKendaraan',
    async () => {
        const response = await axiosInstance.get('/tarif/list');
        console.log('response tarif kendaraan', response.data.data);
        return response.data.data;
    },
);

export const updateKendaraan = createAsyncThunk<
    KendaraanList, // Tipe data yang dikembalikan
    { id_distributor: string; data: KendaraanIndividuRequest } // Tipe argumen
>('kendaraanIndividu/updateKendaraan', async ({ id_distributor, data }) => {
    const response = await axiosInstance.put(`/kendaraan-distributtor/update-individu/${id_distributor}`, data);
    return response.data.data;
});

//Data jenis layanan
export const fetchJenisLayanan = createAsyncThunk<JenisLayanan[], void>('jenisLayanan/fetchJenisLayanan', async () => {
    const response = await axiosInstance.get('/jasa-distributor/list');
    console.log('response jenis layanan', response.data.data);
    return response.data.data;
});

export const fetchJenisKendaraan = createAsyncThunk<JenisKendaraan[], void>(
    'jenisKendaraan/fetchJenisKendaraan',
    async () => {
        const response = await axiosInstance.get('/jenis-kendaraan/list/');
        console.log('response jenis kendaraan', response.data.datas);
        return response.data.datas;
    },
);

export const fetchMerkKendaraan = createAsyncThunk<MerkKendaraan[], string>(
    'merkKendaraan/fetchMerkKendaraan',
    async (jenisKendaraan) => {
        const response = await axiosInstance.get(`/merk-kendaraan/list?jenis=${jenisKendaraan}`);
        return response.data.datas; // Pastikan ini sesuai dengan struktur data yang Anda terima
    },
);

//Data Tarif Berat
export const fetchTarifBerat = createAsyncThunk<TarifBerat[], void>('tarifBerat/fetchTarifBerat', async () => {
    const response = await axiosInstance.get('/biaya_tetap/list');
    console.log('response tarif berat', response.data.data);
    return response.data.data;
});

const KendaraanIndividuSlice = createSlice({
    name: 'kendaraanIndividu',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addKendaraanDriver.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addKendaraanDriver.fulfilled, (state, action: PayloadAction<KendaraanIndividuRequest>) => {
                state.loading = false;
                console.log('State addKendaraan sebelum push:', state.addKendaraan);
                state.addKendaraan.push(action.payload);
            })
            .addCase(addKendaraanDriver.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to create kendaraan';
            })
            .addCase(fetchKendaraanIndividu.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchKendaraanIndividu.fulfilled, (state, action: PayloadAction<KendaraanList[]>) => {
                state.loading = false;
                state.dataKendaraan = action.payload;
            })
            .addCase(fetchKendaraanIndividu.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch kendaraan';
            })
            .addCase(fetchDistributorDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDistributorDetail.fulfilled, (state, action: PayloadAction<DistributorDetail>) => {
                state.loading = false;
                state.dataDistributor = action.payload;
            })
            .addCase(fetchDistributorDetail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch kendaraan';
            })

            .addCase(fetchPengemudiList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPengemudiList.fulfilled, (state, action: PayloadAction<PengemudiList[]>) => {
                state.loading = false;
                state.dataPengemudi = action.payload;
            })
            .addCase(fetchPengemudiList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch pengemudi list';
            })

            .addCase(fetchTarifKendaraan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTarifKendaraan.fulfilled, (state, action: PayloadAction<TarifKendaraan[]>) => {
                state.loading = false;
                state.dataTarifKendaraan = action.payload;
            })
            .addCase(fetchTarifKendaraan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch tarif';
            })
            .addCase(fetchJenisLayanan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchJenisLayanan.fulfilled, (state, action: PayloadAction<JenisLayanan[]>) => {
                state.loading = false;
                state.dataJenisLayanan = action.payload;
            })
            .addCase(fetchJenisLayanan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch jenis layanan';
            })
            .addCase(fetchJenisKendaraan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchJenisKendaraan.fulfilled, (state, action: PayloadAction<JenisKendaraan[]>) => {
                state.loading = false;
                state.jenisKendaraan = action.payload; // Simpan data ke state
            })
            .addCase(fetchJenisKendaraan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch jenis kendaraan';
            })
            .addCase(fetchMerkKendaraan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMerkKendaraan.fulfilled, (state, action: PayloadAction<MerkKendaraan[]>) => {
                state.loading = false;
                state.merkKendaraan = action.payload; // Simpan data ke state
            })
            .addCase(fetchMerkKendaraan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch jenis kendaraan';
            })
            .addCase(fetchTarifBerat.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTarifBerat.fulfilled, (state, action: PayloadAction<TarifBerat[]>) => {
                state.loading = false;
                state.dataTarifBerat = action.payload;
            })
            .addCase(fetchTarifBerat.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch tarif berat';
            })
            .addCase(updateKendaraan.pending, (state) => {
                state.loading = true; // Set loading saat permintaan pembaruan sedang diproses
                state.error = null;
            })
            .addCase(updateKendaraan.fulfilled, (state, action: PayloadAction<KendaraanList>) => {
                state.loading = false;
                const updatedKendaraan = action.payload;
                const index = state.dataKendaraan.findIndex((kendaraan) => kendaraan._id === updatedKendaraan._id);
                if (index !== -1) {
                    state.dataKendaraan[index] = updatedKendaraan;
                }
            })
            .addCase(updateKendaraan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to update kendaraan';
            });
    },
});

export const KendaraanIndividuService = { action: KendaraanIndividuSlice.actions, fetchKendaraanIndividu };
const KendaraanIndividuReducer = KendaraanIndividuSlice.reducer;
export default KendaraanIndividuReducer;
