import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { IndividualVendorState } from '../../interfaces';

const initialState: IndividualVendorState = {
    fullname: '',
    address: '',
    detailAddress: '',
    postalCode: '',
    nik: '',
    imageKTP: null,
    npwp: '',
    imageNPWP: null,
    nameStore: '',
    firstDetailStoreAddress: '',
    secondDetailStoreAddress: '',
    postalCodeStore: '',
    errorPostalCodeStore: false,
    latPinData: 0,
    longPinData: 0,
    firstDetailAddress: '',
    secondDetailAddress: '',
    isOpenModalIndividu: false,
    namaProvinsiDataIndividu: '',
    namaKotaDataIndividu: '',
    namaKecamatanDataIndividu: '',
    namaKelurahanDataIndividu: '',
    selectedIdProvinceDataIndividu: '',
    selectedIdCityDataIndividu: '',
    selectedIdSubdistrictDataIndividu: '',
    selectedIdUrVillageDataIndividu: '',
    //----------------------->
    activeTab: 'Individual',
    hidden: true,
    isShow: false,
    moveFormCompany: 'Data Perusahaan',
    isOpenModal: false,
    isReset: false,
    selectedIdProvinsi: '',
    selectedIdKota: '',
    selectedIdKecamatan: '',
    selectedIdKelurahan: '',
    provinsiName: '',
    kotaName: '',
    kecamatanName: '',
    kelurahanName: '',
    openModalKota: false,
    openModalKecamatan: false,
    openModalKelurahan: false,
    datasFiles: [],
    datasPreviews: [],
    datasFileNPWP: [],
    datasPreviewsNPWP: [],
    //------------------->
    isOpenModalGlobal: false,
    provinsiNameStore: '',
    kotaNameStore: '',
    kecamatanNameStore: '',
    kelurahanNameStore: '',
    selectedIdProvinceStore: '',
    selectedIdCityStore: '',
    selectedIdSubdistrictStore: '',
    selectedIdUrVillageStore: '',
    latPinDataStore: 0,
    longPinDataStore: 0,
    openCordinateStore: false,
};

const postalCodeRegex = /^\d{5}$/;

const IndividualVendorSlice = createSlice({
    name: 'individualVendor',
    initialState,
    reducers: {
        onChangeUpdateFields: (
            state,
            action: PayloadAction<{ name: keyof IndividualVendorState; value: string | number }>,
        ) => {
            const { name, value } = action.payload;
            (state[name] as string | number) = value;
        },
        submitForm: (state, action: PayloadAction<IndividualVendorState>) => {
            console.log('submitted submit form', action.payload);
        },
        setNameStore: (state, action: PayloadAction<string>) => {
            state.nameStore = action.payload;
        },
        setDetailAddressStore: (state, action: PayloadAction<string>) => {
            state.secondDetailStoreAddress = action.payload;
        },
        setPostalCodeStore: (state, action: PayloadAction<string>) => {
            if (postalCodeRegex.test(action.payload)) {
                state.postalCodeStore = action.payload;
            } else {
                console.error('Invalid postal code');
            }
        },
        setLatitudeMap: (state, action: PayloadAction<number>) => {
            state.latPinData = action.payload;
        },
        setLongtitudeMap: (state, action: PayloadAction<number>) => {
            state.longPinData = action.payload;
        },
        onResetLatLangIndividual: (state) => {
            state.latPinData = initialState.latPinData;
            state.longPinData = initialState.longPinData;
        },
        // --------->
        setActiveTab: (state, action: PayloadAction<string>) => {
            state.activeTab = action.payload;
            state.hidden = action.payload !== 'Perusahaan';
        },
        setMoveFormCompany: (state, action: PayloadAction<string>) => {
            state.moveFormCompany = action.payload;
        },
        setDatasFiles: (state, action: PayloadAction<File[]>) => {
            state.datasFiles = action.payload;
        },
        setDatasPreviews: (state, action: PayloadAction<string[]>) => {
            state.datasPreviews = action.payload;
        },
        setDatasFilesNPWP: (state, action: PayloadAction<File[]>) => {
            state.datasFileNPWP = action.payload;
        },
        setDatasPreviewsNPWP: (state, action: PayloadAction<string[]>) => {
            state.datasPreviewsNPWP = action.payload;
        },
        // ------DATA TOKO------------>
        setLattiudeMapStore: (state, action: PayloadAction<number>) => {
            state.latPinDataStore = action.payload;
        },
        setLongtitudeMapStore: (state, action: PayloadAction<number>) => {
            state.longPinDataStore = action.payload;
        },
        onResetLatLangDataStore: (state) => {
            state.latPinDataStore = initialState.latPinDataStore;
            state.longPinDataStore = initialState.longPinDataStore;
        },
        setShowingModal: (state, action: PayloadAction<boolean>) => {
            state.isOpenModalGlobal = action.payload;
        },
        setOpenCordinateStore: (state, action: PayloadAction<boolean>) => {
            state.openCordinateStore = action.payload;
        },
        getNameProvinceStore: (state, action: PayloadAction<string>) => {
            state.provinsiNameStore = action.payload;
        },
        getNameCityStore: (state, action: PayloadAction<string>) => {
            state.kotaNameStore = action.payload;
        },
        getNameSubdistrictStore: (state, action: PayloadAction<string>) => {
            state.kecamatanNameStore = action.payload;
        },
        getNameUrVillageStore: (state, action: PayloadAction<string>) => {
            state.kelurahanNameStore = action.payload;
        },
        onResetStore: (state) => {
            state.provinsiNameStore = initialState.provinsiNameStore;
            state.kotaNameStore = initialState.kotaNameStore;
            state.kecamatanNameStore = initialState.kecamatanNameStore;
            state.kelurahanNameStore = initialState.kelurahanNameStore;
        },
        getIdProvinceStore: (state, action: PayloadAction<string>) => {
            state.selectedIdProvinceStore = action.payload;
        },
        getIdCitiesStore: (state, action: PayloadAction<string>) => {
            state.selectedIdCityStore = action.payload;
        },
        getIdSubdistrictStore: (state, action: PayloadAction<string>) => {
            state.selectedIdSubdistrictStore = action.payload;
        },
        getIdUrVillageStore: (state, action: PayloadAction<string>) => {
            state.selectedIdUrVillageStore = action.payload;
        },
        onClearFields: (state, action: PayloadAction<{ name: keyof IndividualVendorState }>) => {
            const { name } = action.payload;
            if (isKeyofFormValueIndividualVendor(name)) {
                if (typeof state[name] === 'string') {
                    (state[name] as string) = '';
                } else if (Array.isArray(state[name])) {
                    (state[name] as unknown as any[]) = [];
                }
            }
        },
        // ----------------------------------------->
        setDisplayModalIndividu: (state, action: PayloadAction<boolean>) => {
            state.isOpenModalIndividu = action.payload;
        },
        getNameProvinceDataIndividu: (state, action: PayloadAction<string>) => {
            state.namaProvinsiDataIndividu = action.payload;
        },
        getNameCityDataIndividu: (state, action: PayloadAction<string>) => {
            state.namaKotaDataIndividu = action.payload;
        },
        getNameSubdistrictDataIndividu: (state, action: PayloadAction<string>) => {
            state.namaKecamatanDataIndividu = action.payload;
        },
        getNameUrVillageDataIndividu: (state, action: PayloadAction<string>) => {
            state.namaKelurahanDataIndividu = action.payload;
        },
        onResetDataIndividu: (state) => {
            state.namaProvinsiDataIndividu = initialState.namaProvinsiDataIndividu;
            state.namaKotaDataIndividu = initialState.namaKotaDataIndividu;
            state.namaKecamatanDataIndividu = initialState.namaKecamatanDataIndividu;
            state.namaKelurahanDataIndividu = initialState.namaKelurahanDataIndividu;
        },
        getIdProvinceDataIndividu: (state, action: PayloadAction<string>) => {
            state.selectedIdProvinceDataIndividu = action.payload;
        },
        getIdCitiesDataIndividu: (state, action: PayloadAction<string>) => {
            state.selectedIdCityDataIndividu = action.payload;
        },
        getIdSubdistrictDataIndividu: (state, action: PayloadAction<string>) => {
            state.selectedIdSubdistrictDataIndividu = action.payload;
        },
        getIdUrVillageDataIndividu: (state, action: PayloadAction<string>) => {
            state.selectedIdUrVillageDataIndividu = action.payload;
        },
        onClearFieldsIndividuals: (state) => {
            state.fullname = '';
            state.address = '';
            state.detailAddress = '';
            state.postalCode = '';
            state.nik = '';
            state.imageKTP = null;
            state.npwp = '';
            state.imageNPWP = null;
            state.nameStore = '';
            state.firstDetailStoreAddress = '';
            state.secondDetailStoreAddress = '';
            state.postalCodeStore = '';
            state.errorPostalCodeStore = false;
            state.latPinData = 0;
            state.longPinData = 0;
            state.firstDetailAddress = '';
            state.secondDetailAddress = '';
            state.isOpenModalIndividu = false;
            state.namaProvinsiDataIndividu = '';
            state.namaKotaDataIndividu = '';
            state.namaKecamatanDataIndividu = '';
            state.namaKelurahanDataIndividu = '';
            state.selectedIdProvinceDataIndividu = '';
            state.selectedIdCityDataIndividu = '';
            state.selectedIdSubdistrictDataIndividu = '';
            state.selectedIdUrVillageDataIndividu = '';
            state.activeTab = 'Individual';
            state.hidden = true;
            state.isShow = false;
            state.moveFormCompany = 'Data Perusahaan';
            state.isOpenModal = false;
            state.isReset = false;
            state.selectedIdProvinsi = '';
            state.selectedIdKota = '';
            state.selectedIdKecamatan = '';
            state.selectedIdKelurahan = '';
            state.provinsiName = '';
            state.kotaName = '';
            state.kecamatanName = '';
            state.kelurahanName = '';
            state.openModalKota = false;
            state.openModalKecamatan = false;
            state.openModalKelurahan = false;
            state.datasFiles = [];
            state.datasPreviews = [];
            state.datasFileNPWP = [];
            state.datasPreviewsNPWP = [];
            state.isOpenModalGlobal = false;
            state.provinsiNameStore = '';
            state.kotaNameStore = '';
            state.kecamatanNameStore = '';
            state.kelurahanNameStore = '';
            state.selectedIdProvinceStore = '';
            state.selectedIdCityStore = '';
            state.selectedIdSubdistrictStore = '';
            state.selectedIdUrVillageStore = '';
            state.latPinDataStore = 0;
            state.longPinDataStore = 0;
            state.openCordinateStore = false;
        },        
    },
});

export const IndividualVendorService = { action: IndividualVendorSlice.actions };
const IndividualVendorReducer = IndividualVendorSlice.reducer;
export default IndividualVendorReducer;

export const isKeyofFormValueIndividualVendor = (key: any): key is keyof IndividualVendorState => {
    return [
        'fullname',
        'address',
        'detailAddress',
        'postalCode',
        'nik',
        'npwp',
        'imageKTP',
        'imageNPWP',
        'nameStore',
        'secondDetailStoreAddress',
        'postalCodeStore',
        'nomorNpwpPT',
    ].includes(key);
};
