import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { ProductInRadius } from '../../../../../konsumnen/Components/product/ProductInRadius/interface';

export const GetProductInRadius = createAsyncThunk(
  'product/getProductInRadius',
  async () => { 
    const response = await axiosInstance.get(`/product/product_with_radius_konsumen`); 
    return response.data;
  }
);

// Redux slice
const productInRadius = createSlice({
    name: 'productInRadius',
    initialState: {
      data: null as ProductInRadius | null,
      loading: false,
      error: null as string | null | undefined, 
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(GetProductInRadius.pending, (state) => {
          state.loading = true;
          state.error = null;  
        })
        .addCase(GetProductInRadius.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(GetProductInRadius.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;  
        });
    },
  });
  

export default productInRadius.reducer;
