import { MBGIconOrange } from '../../assets/icons';
import { instagramIcon, facebookIcon, twitterIcon, youtubeIcon, wordpressIcon } from './icon';

export default function Footer() {
    return (
        <footer className="bg-[#fff] mb-[38px] mt-[160px]">
            <hr className="border-grey mb-[90px]"></hr>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
                    <MBGIconOrange />
                    <div className="text-start">
                        <h3 className="text-lg font-semibold text-black mb-4">Learn More</h3>
                        <ul className="text-gray-400">
                            <li>
                                <section className="hover:text-grey text-[black] hover:ml-[10px] duration-300">
                                    About Lift
                                </section>
                            </li>
                            <li>
                                <section className="hover:text-grey text-[black] hover:ml-[10px] duration-300">
                                    Press Relasses
                                </section>
                            </li>
                            <li>
                                <section className="hover:text-grey text-[black] hover:ml-[10px] duration-300">
                                    Environment
                                </section>
                            </li>
                            <li>
                                <section className="hover:text-grey text-[black] hover:ml-[10px] duration-300">
                                    Jobs
                                </section>
                            </li>
                            <li>
                                <section className="hover:text-grey text-[black] hover:ml-[10px] duration-300">
                                    Provacy Policy
                                </section>
                            </li>
                            <li>
                                <section className="hover:text-grey text-[black] hover:ml-[10px] duration-300">
                                    Contact Us
                                </section>
                            </li>
                        </ul>
                    </div>
                    <div className="text-start">
                        <h3 className="text-lg font-semibold text-black mb-4">Tickets & Booking</h3>
                        <ul className="text-gray-400">
                            <li>
                                <section className="hover:text-grey text-[black] hover:ml-[10px] duration-300">
                                    Lift Tickets
                                </section>
                            </li>
                            <li>
                                <section className="hover:text-grey text-[black] hover:ml-[10px] duration-300">
                                    Season Passes
                                </section>
                            </li>
                            <li>
                                <section className="hover:text-grey text-[black] hover:ml-[10px] duration-300">
                                    Vacation Packages
                                </section>
                            </li>
                        </ul>
                    </div>
                    <div className="text-start">
                        <h3 className="text-lg font-semibold text-black mb-4">Contact Us</h3>
                        <ul className="text-gray-400">
                            <li>
                                <section className="hover:text-grey text-[black] hover:ml-[10px] duration-300">
                                    Hotel Reservation: <span className="font-bold">123</span>
                                </section>
                            </li>
                            <li>
                                <section className="hover:text-grey text-[black] hover:ml-[10px] duration-300">
                                    Tickets Office: <span className="font-bold">123-456-7890</span>
                                </section>
                            </li>
                        </ul>
                    </div>
                    <div className="text-start">
                        <h3 className="text-lg font-semibold text-black mb-4">Social</h3>
                        <div className="flex gap-[20px]">
                            {facebookIcon()}
                            {instagramIcon()}
                            {twitterIcon()}
                            {youtubeIcon()}
                            {wordpressIcon()}
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    );
}
